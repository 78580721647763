import React from "react"
import { graphql, StaticQuery } from "gatsby"
import "./AWSGoogleBadges.scss"
import google from "../img/Google_partner.svg"
import aws from "../img/aws_partner.svg"

export default props => (
  /**
   * Gets all the data for the badge from AgilityCMS
   */

  <StaticQuery
    query={graphql`
      {
        agilityawsgooglebadge {
          id
          customFields {
            image {
              url
              filesize
              pixelHeight
              pixelWidth
              height
              width
            }
          }
        }
      }
    `}
    render={queryData => {
      const viewModel = {
        customFields: queryData.agilityawsgooglebadge.customFields,
      }
      return <AWSGoogleBadges {...viewModel} />
    }}
  />
)

/**
 * Component to render the badge
 * @function Badge
 */
const AWSGoogleBadges = props => {
  // const customFields = props.customFields

  return (
    <section className="badge">
      <div className="container">
        {/* <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img
              className="badge-image"
              src={customFields.image ? customFields.image.url : ""}
              alt="badge"
            />
          </div>
        </div> */}
        <div className="row pt-4 pb-4  justify-content-center">
          <div className="col-4 col-md-3 m-auto">
            <img className="badge-image" src={google} alt="" />
          </div>
          <div className="col-4 col-md-3 m-auto">
            <img className="badge-image" src={aws} alt="" />
          </div>
          {/* temporal while the page is redisigned */}
          <div className="col-4 col-md-3 justify-content-center d-flex align-items-center">
            <a
              href="https://www.semrush.com/agencies/pixel506-by-10pearls/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img
                src="https://static.semrush.com/agency-directory/shared/badge.svg"
                width="100"
                height="100"
                alt="Semrush certified agency partner badge"
              />{" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
