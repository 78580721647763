import React from "react"
import { Link } from "gatsby"
import "./ButtonCTA.scss"

const ButtonCTA = ({ data }) => {
  const { styles, text, scrollToForm, href } = data

  if (scrollToForm) {
    const handleScrollToForm = () => {
      if (typeof window === `undefined`) return
      if (document.querySelector(".form-section")) {
        window.scrollTo({
          top: document.querySelector(".form-section").offsetTop,
          behavior: "smooth",
        })
      }
    }

    return (
      <button className={`btn custom-btn ${styles}`} onClick={handleScrollToForm}>
        {text}
      </button>
    )
  }

  if (!scrollToForm && href) {
    return (
      <Link className={`btn custom-btn ${styles}`} to={href}>
        {text}
      </Link>
    )
  }

  return null
}

export default ButtonCTA
