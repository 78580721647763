import React from "react"
import { stripHTML } from "./../agility/helpers"
import "./LogoQuoteBanner.scss"

const logoQuoteBanner = ({ item }) => {
  const customFields = item.customFields
  const alt = customFields.background.label
    ? customFields.background.label
    : stripHTML(
        customFields.quoteOrText
          .split("&ldquo;")
          .join("")
          .split("&rdquo;")
          .join("")
      )
  const backgrounds = `
    @media (min-width: 320px) {
      .quote-banner.bg-${item.contentID} {
        background-image: url(${
          customFields.mobileBackgroundImage
            ? customFields.mobileBackgroundImage.url
            : customFields.background.url
        }); 
      }
    }
    @media (min-width: 768px) {
      .quote-banner.bg-${item.contentID} {
        background-image: url(${customFields.background.url}); 
      }
    }
  `

  return (
    <section
      className={`quote-banner bg-${item.contentID} background-cover`}
      title={alt}
    >
      <style>{backgrounds}</style>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center flex-column align-items-center">
            <img
              className="logo"
              src={customFields.logo.url}
              alt={customFields.logo.label}
            ></img>
            <div
              className="quote"
              dangerouslySetInnerHTML={{ __html: customFields.quoteOrText }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default logoQuoteBanner
