import React from "react"
import LazyImage from "../components/LazyImage"
import "./SingleTestimonial.scss"

const SingleTestimonial = ({ item }) => {
  const { title, text, author, roleName, logo } = item.customFields

  return (
    <section className={`single-testimonial st-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title-wraper d-flex flex-column align-items-center">
              <span className="headline-line" />
              <h2 className="title">{title}</h2>
            </div>
          </div>
        </div>
        <div className="row wrapped">
          <div className="col-md-4">
            <div className="logo-wraper d-flex">
              <LazyImage src={logo.url} alt={logo.label} />
            </div>
          </div>
          <div className="col-md-8">
            <div className="testimonial-wraper">
              <div className="quotation-mark d-flex align-items-center">
                <p>&#8222;</p>
              </div>
              <p className="text">{text}</p>
              <p className="author mb-0">{author}</p>
              <p className="role-name">{roleName}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SingleTestimonial
