import React from "react"
import { renderHTML } from "../agility/utils"
import "./SimpleImageBackgroundAndText.scss"
import ButtonCTA from "../components/ButtonCTA"

const SimpleImageBackgroundAndText = ({ item }) => {
  const { imageBaackground, text, cTA, mobileImageBackground } = item.customFields

  const backgroundStyles = `
  .simple-image-background.item-${item.contentID} {
    background-image: url(${mobileImageBackground?.url});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 489px;
  }

    @media (min-width: 768px) {
      .simple-image-background.item-${item.contentID} {
        background-image: url(${imageBaackground?.url});
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 606px;
      }
    }
  `

  return (
    <section className={`simple-image-background item-${item.contentID}`}>
      <style>{backgroundStyles}</style>
      {text && (
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-md-center aling-items-start">
              <div
                className="text"
                dangerouslySetInnerHTML={renderHTML(text)}
              />
              <ButtonCTA
                  data={{
                    text: cTA.text +"  →",
                    styles: "btn btn-solid-white custom",
                    href: cTA.href,
                    scrollToForm: true
                  }}
                />
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default SimpleImageBackgroundAndText
