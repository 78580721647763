import React, { useRef, useEffect } from "react"
import "./CarouselGallery.scss"
import SVG from "./../components/SVG"
import Glide, {
  Controls,
  Keyboard,
  Breakpoints,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm"

const CarouselGallery = ({ item }) => {
  const customFields = item.customFields
  const currentCarousel = useRef(null)

  useEffect(() => {
    const elementToGlide = currentCarousel.current.querySelector(".glide")
    const glide = new Glide(elementToGlide, {
      type: "carousel",
      perView: 1,
      gap: 0,
    })

    glide.mount({ Controls, Keyboard, Breakpoints, Swipe })

    return () => {
      glide.destroy()
    }
  })

  const CarouselSlide = ({ props }) => {
    const alt = props.customFields.image.label
      ? props.customFields.image.label
      : props.customFields.title

    return (
      <li className="glide__slide d-flex justify-content-center align-items-center">
        <img
          className="carousel-gallery__img"
          src={props.customFields.image.url}
          alt={alt}
        ></img>
      </li>
    )
  }

  return (
    <section className="carousel-gallery" ref={currentCarousel}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="carousel-gallery__title">
              {customFields.carouselGalleryTtile}
            </h2>
            <div className="carousel glide">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                  {customFields.carouselImages.map(image => (
                    <CarouselSlide key={image.contentID} props={image} />
                  ))}
                </ul>
                <div
                  className="carousel-gallery-controls"
                  data-glide-el="controls"
                >
                  <button
                    className="carousel-gallery-controls-prev"
                    data-glide-dir={`<`}
                  >
                    <SVG
                      name="caret-left"
                      fill="#fff"
                      width="19"
                      height="29"
                    ></SVG>
                  </button>
                  <button
                    className="carousel-gallery-controls-next"
                    data-glide-dir={`>`}
                  >
                    <SVG
                      name="caret-right"
                      fill="#fff"
                      width="19"
                      height="29"
                    ></SVG>
                  </button>
                </div>
              </div>
              <div
                className="glide__bullets bullets"
                data-glide-el="controls[nav]"
              >
                {customFields.carouselImages.map((image, key) => (
                  <button
                    className="glide__bullet bullet"
                    key={key}
                    aria-label={`Bullet for ${image.customFields.title}`}
                    data-glide-dir={`=${key}`}
                  ></button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CarouselGallery
