import React, { Fragment } from "react"
import "./ThreeCardsColumn.scss"

const ThreeCardsColumn = ({ item }) => {
  const {
    titleSection,
    text,
    cards,
    background,
    mobileBackground,
  } = item.customFields

  const backgroundStyles = `.tcc-${item.contentID} {
    background-image: url(${background.url});
    }
    @media only screen and (max-width: 767px) {
      .tcc-${item.contentID} {
        background-image: url(${mobileBackground.url});
      }
    }
  }`
  // min-height: 550px;

  //agregar las de mobile bg

  const Card = ({ card }) => {
    const { backgroundColor, description, title } = card.customFields
    const cardBackgroundStyle = `${
      backgroundColor === "black" ? "black" : "transparent"
    }`

    return (
      <div className="col-md-4 col">
        <div className={`card-wrapper ${cardBackgroundStyle}`}>
          <h5 className="card-title">{title}</h5>
          <p className="card-description m-0 mt-3">{description}</p>
        </div>
      </div>
    )
  }

  return (
    <section
      className={`three-cards-columns background-cover tcc-${item.contentID}`}
    >
      <style>{backgroundStyles}</style>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <h3 className="title-section">{titleSection}</h3>
          </div>
        </div>
        <div className="row no-gutters pt-5 pb-5">
          {cards.map(card => {
            return (
              <Fragment key={card.contentID}>
                <Card card={card} />
              </Fragment>
            )
          })}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <h3 className="text">{text}</h3>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ThreeCardsColumn
