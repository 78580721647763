import React from "react"
import "./TwoColumnsContentAndBackground.scss"
import { Link } from "gatsby"
import { stripHTML } from "./../agility/helpers"
import { renderHTML } from "../agility/utils"

const TwoColumnsContentAndBackground = ({ item }) => {
  const customFields = item.customFields
  const textColor =
    customFields.backgroundColor === "black" ? "#FFF" : "#1C1C1C"
  const mainColor = customFields.mainColor.customFields.hexadecimalValue
  const background = customFields.background
    ? encodeURI(customFields.background.url)
    : ""
  const fullDottedBackground = customFields.fullDottedBackground

  const centeredBackgroundContent = customFields.centeredContent
    ? "centered"
    : ""

  const mobileBackground = customFields.mobileBackground
    ? encodeURI(customFields.mobileBackground.url)
    : ""
  const placeholder = customFields.placeholder
    ? encodeURI(customFields.placeholder.url)
    : ""
  const alt = customFields.background.label
    ? customFields.background.label
    : stripHTML(customFields.mainTitle)

  const inlineStyles = `
    .tc-bg.tc-bg-${item.contentID} .main-title, 
    .tc-bg.tc-bg-${item.contentID} .headline,
    .tc-bg.tc-bg-${item.contentID} .text-block {
      color: ${textColor};
    }
    .tc-bg.tc-bg-${item.contentID} .main-title p span  {
      color: ${mainColor};
    }
    .tc-bg.tc-bg-${item.contentID} .headline-line {
      background-color: ${mainColor};
    }
    .tc-bg.tc-bg-${item.contentID} .background {
      background-image: url(${mobileBackground}), url(${placeholder});
    }

    @media (min-width: 768px) {
      .tc-bg.tc-bg-${item.contentID} .background {
        background-image: url(${background}), url(${placeholder});
      }
    }
  `

  const CTA = () => {
    const buttonStyleClass = customFields.buttonStyle.customFields.cSSClass
      ? customFields.buttonStyle.customFields.cSSClass
      : "btn-primary-white"
    return (
      <Link
        className={`btn ${
          customFields.backgroundColor === "black" ? "" : "black"
        } ${buttonStyleClass}`}
        to={customFields.ctaLink.href}
      >
        {customFields.ctaLink.text}
      </Link>
    )
  }

  return (
    <section
      className={`tc-bg tc-bg-${item.contentID} ${
        fullDottedBackground
          ? "full-dotted-background"
          : customFields.backgroundColor
      }`}
    >
      <style>{inlineStyles}</style>
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div
            className={`col-12 col-md-6 col-sm-12 ${
              fullDottedBackground ? "full-dotted-background" : ""
            } ${
              customFields.backgroudColumnAlignment === "right" ? "order-1" : ""
            }`}
          >
            <div className="text-wrapper">
              <div className="d-flex justify-content-start align-items-center mb-2 mb-md-4 px-sm-2">
                <p className="headline mb-0">{customFields.headline}</p>
              </div>
              <h2
                className="main-title mb-5 mb-sm-5 px-sm-2"
                dangerouslySetInnerHTML={{ __html: customFields.mainTitle }}
              ></h2>

              {!customFields.hTMLContent && (
                <p className="text-block mb-5 pb-1 px-sm-2">
                  {customFields.textBlock}
                </p>
              )}

              {!customFields.textBlock && customFields.hTMLContent && (
                <div
                  className="text-block mb-5 pb-1 px-sm-2"
                  dangerouslySetInnerHTML={renderHTML(customFields.hTMLContent)}
                />
              )}

              {customFields.ctaLink && customFields.ctaLink.href && <CTA />}
            </div>
          </div>
          <div
            className={`col-12 col-md-6 col-sm-12 ${
              fullDottedBackground ? "full-dotted-background" : ""
            } ${
              customFields.backgroudColumnAlignment ? "" : "order-2"
            }`}
          >
            {customFields.mobileBackground && customFields.background && (
              <figure className="mb-0 background" role="img" title={alt}>
                <div
                  className={`text-over-bg d-flex flex-column justify-content-center ${centeredBackgroundContent}`}
                >
                  {/* <span className="headline-line" /> */}
                  {customFields.title ? (
                    <h2 className="background-title">{customFields.title}</h2>
                  ) : (
                    ""
                  )}
                  {customFields.text ? (
                    <div
                      className={`background-text ${centeredBackgroundContent}`}
                      dangerouslySetInnerHTML={renderHTML(customFields.text)}
                    />
                  ) : (
                    ""
                  )}
                  {centeredBackgroundContent ? <span className="headline-line headline-line-bottom" /> : ""}
                </div>
              </figure>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnsContentAndBackground
