import React from "react"
import "./BenefitsCards.scss"
import { renderHTML } from "./../agility/utils"

const BenefitsCards = ({ item }) => {
  const { customFields } = item

  const styles = `.cards-wrapper {
    background-image: url(${customFields.backgroundImage.url})
  }`

  const Card = ({ card, index }) => {
    const { icon, description } = card.customFields
    return (
      <div key={index} className="card">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img className="card-icon" src={icon.url} alt={icon.label} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <p className="card-description text-center">{description}</p> */}
            <div
              className="card-description text-center"
              dangerouslySetInnerHTML={renderHTML(description)}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className={`benefit-cards-${item.contentID} benefit-cards`}>
      <style>{styles}</style>
      <div className="container">
        <div className="wrapper">
          <span className="headline-line" />
          <div className="row">
            <div className="col-12">
              <h2 className="title">{customFields.title}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <p className="description">{customFields.description}</p>
            </div>
          </div>
          <div className="row cards-wrapper">
            {customFields.benefits.map((card, index) => {
              return (
                <div className="col-md-4 col-6" key={index}>
                  <Card card={card} index={index} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BenefitsCards
