import React from "react"
import "./NumbersSection.scss"

const NumbersSection = ({ item }) => {
  const { customFields } = item
  const row_1 = customFields.numbers.slice(0, 3)
  const row_2 = customFields.numbers.slice(3, 7)

  const Number = ({ number, col, index }) => {
    return (
      <div
        className={`col-md-${col} col-12 d-flex justify-content-center tex-center`}
        key={index}
      >
        <h2 className="number">{number}</h2>
      </div>
    )
  }

  const Description = ({ description, col, index }) => {
    return (
      <div
        className={`col-md-${col} col-12 d-flex justify-content-center align-items-center`}
        key={index}
      >
        <p className="description mb-0">{description}</p>
      </div>
    )
  }

  const NumberDescription = ({ number, index }) => {
    return (
      <div className="col-12 d-flex flex-column align-items-center" key={index}>
        <h2 className="number">{number.customFields.number}</h2>
        <p className="description mobile mb-0">{number.customFields.description}</p>
      </div>
    )
  }

  return (
    <>
      <section className={`numbers-section numbeers-section-${item.contentID}`}>
        <div className="container squares-wrapper">
          <div className="row">
            <div className="col d-flex justify-content-end">
              <div className="squares" />
            </div>
          </div>
        </div>
        <div className="container">
           {/* Testing */}
           <div className="row no-gutters d-md-none">
            {customFields.numbers.map((num, index) => {
              return (
                <NumberDescription number={num} key={index} index={index} />
              )
            })}
          </div>

          <div className="row no-gutters d-none d-md-flex">
            {row_1.map((number, index) => {
              return (
                <Number
                  key={index}
                  number={number.customFields.number}
                  col={"4"}
                  index={index}
                />
              )
            })}
          </div>

          <div className="row no-gutters description d-none d-md-flex">
            {row_1.map((number, index) => {
              return (
                <Description
                  key={index}
                  description={number.customFields.description}
                  col={"4"}
                  index={index}
                />
              )
            })}
          </div>
          <div className="row no-gutters pt-4 d-none d-md-flex">
            {row_2.map((number, index) => {
              return (
                <Number
                  key={index}
                  number={number.customFields.number}
                  col={"3"}
                  index={index}
                />
              )
            })}
          </div>
          <div className="row no-gutters description d-none d-md-flex">
            {row_2.map((number, index) => {
              return (
                <Description
                  key={index}
                  description={number.customFields.description}
                  col={"3"}
                  index={index}
                />
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default NumbersSection
