import React from "react"
import { Link } from "gatsby"
import { stripHTML } from "./../agility/helpers"
import "./TextCtaBanner.scss"

const TextCtaBanner = ({ item }) => {
  const customFields = item.customFields
  const alt = customFields.background.label
    ? customFields.background.label
    : stripHTML(customFields.bannerTitle)
  const backgrounds = `
    @media (min-width: 320px) {
      .text-cta-banner.bg-${item.contentID} {
        background-image: url(${
          customFields.mobileBackgroundImage
            ? customFields.mobileBackgroundImage.url
            : customFields.background.url
        }); 
      }
    }
    @media (min-width: 768px) {
      .text-cta-banner.bg-${item.contentID} {
        background-image: url(${customFields.background.url}); 
      }
    }
  `

  const CTA = () => {
    const buttonStyleClass = item.customFields.textBannerCtaStyle.customFields
      .cSSClass
      ? item.customFields.textBannerCtaStyle.customFields.cSSClass
      : "btn-primary-pink"
    return (
      <Link
        className={`btn ${buttonStyleClass}`}
        to={item.customFields.ctaLink.href}
      >
        {item.customFields.ctaText}
      </Link>
    )
  }

  return (
    <section
      className={`section text-cta-banner bg-${item.contentID} background-cover`}
      title={alt}
    >
      <style>{backgrounds}</style>
      <div className="container-fluid">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-12 col-xl-6 mb-5">
            <h2
              className="title mb-0"
              dangerouslySetInnerHTML={{ __html: customFields.bannerTitle }}
            ></h2>
          </div>
          <div className="col-12 col-xl-6 mb-5">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: customFields.description }}
            ></div>
          </div>
          <div className="col-12 text-center">
            {item.customFields.ctaLink && item.customFields.ctaLink.href && (
              <CTA />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextCtaBanner
