import React from "react"

/**
 * Custom hook to know if an element has mounted.
 * @function useHasMounted
 */

export default function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}
