import React from "react"
import "./LogoGridGallery.scss"

const LogoGridGallery = ({ item }) => {
  const { greyBG } = item.customFields
  return (
    <section className={`grid-gallery ${greyBG ? "grey-bg" : ""}`}>
      <div className="container">
        <h2>{item.customFields.title}</h2>
        <p className="description">{item.customFields.description}</p>
        <div className="row justify-content-center">
          {item.customFields.logos.map((logo, index) => (
            <div
              className="col-6 col-lg-3 d-flex flex-column justify-content-center align-items-center"
              key={index}
            >
              <div className="grid-gallery-img" aria-hidden="true">
                <img
                  src={logo.customFields.logoImage.url}
                  alt={logo.customFields.title}
                />
              </div>
            </div>
          ))}
        </div>

      </div>
    </section>
  )
}

export default LogoGridGallery
