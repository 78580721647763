import React, { Fragment } from "react"
import LazyImage from "../components/LazyImage"
import "./MultipleRowsAndColumns.scss"

const MultipleRowsAndColumns = ({ item }) => {
  const { title, description, titleAlignment, contentItems } = item.customFields

  const Card = ({ item }) => {
    const {
      serviceTitle,
      serviceDescription,
      linearGradient,
      icon,
    } = item.customFields

    const flex_col = icon ? " flex-column" : ""

    return (
      <div className={`col-md-3 mb-md-4 d-flex ${flex_col}`}>
        {linearGradient === "true" && <span className="linear-gradient" />}
        {icon && (
          <LazyImage src={icon.url} alt={icon.label} extraClasses="icon mb-3" />
        )}
        <div className="item-wrapper">
          <h3 className="item-title mb-3">{serviceTitle}</h3>
          <p className="item-description">{serviceDescription}</p>
        </div>
      </div>
    )
  }

  return (
    <section className={`multiple-rows-columns mrc-${item.contentID}`}>
      <div className="container">
        <div className="row no-gutters">
          <div className={`${titleAlignment === "left" ? "col-md-7" : "col"}`}>
            <div
              className={`description-wrapper d-flex flex-column ${titleAlignment}`}
            >
              <span className="headline-line" />
              <h2 className="title">{title}</h2>
              <p
                className={`description text-md-${titleAlignment} mt-md-2 ${titleAlignment}`}
              >
                {description}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {contentItems &&
            contentItems.map(item => {
              return (
                <Fragment key={item.contentID}>
                  <Card item={item} />
                </Fragment>
              )
            })}
        </div>
      </div>
    </section>
  )
}

export default MultipleRowsAndColumns
