import React from "react"
import ContentZone from "../agility/components/ContentZone"
import DarkLandingHeader from "../components/DarkLandingHeader"
import LandingFooter from "./../components/LandingFooter"

const DarkEnglishLandingPageTemplate = props => {
  return (
    <div className="pixel506">
      <DarkLandingHeader />
      <main>
        <ContentZone name="MainContentZone" {...props} />
      </main>
      <LandingFooter />
    </div>
  )
}

export default DarkEnglishLandingPageTemplate
