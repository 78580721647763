import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CareerPostListing from "../components/CareerPostListing"

export default props => (
  <StaticQuery
    query={graphql`
      query AllCareers {
        allAgilityCareer {
          nodes {
            customFields {
              type
              title
              location
              description
              category
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <CareerPostListing
          allCareers={queryData.allAgilityCareer.nodes}
          {...props}
        />
      )
    }}
  />
)
