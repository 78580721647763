import React from "react"
import "./OneColumnAndContent.scss"
import { renderHTML } from "../agility/utils"

const OneColumnAndContent = ({ item }) => {
  const { tagName, content } = item.customFields
  return (
    <section className={`one-column-content item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-12">
            <span className="tag-name">{tagName}</span>
            <div
              className="description"
              dangerouslySetInnerHTML={renderHTML(content)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default OneColumnAndContent
