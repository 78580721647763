import React, { Fragment } from 'react'
import "./TwoRowsAndGridCards.scss"

const TwoRowsAndGridCards = ({ item }) => {
  const { title, description, subtitle, items } = item.customFields
  return (
    <section className={`two-rows-grid-cards item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col col-lg-10">
            <h2 className='title'>{title}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col col-lg-6">
            <p className='description'>{description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col col-lg-8">
            <h2 className='subtitle'>{subtitle}</h2>
          </div>
        </div>
        <div className="row no-gutters row-gap-3">
          {items && items.map((item, index) => (
            <Fragment key={item.contentID}>
              <CarItem title={item.customFields.title} image={item.customFields.image} index={index} />
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}

const CarItem = ({ title, image, index }) => {

  return (
    <div className="col-md-4 card-item mb-4">
      <img className='card-item-image w-100' src={image.url} alt={image.label} />
      <div className="card-item-content">
        <p className='card-item-content-index'>{index + 1}.</p>
        <p className='card-item-content-title'>{title}</p>
      </div>
    </div>
  )

}

export default TwoRowsAndGridCards