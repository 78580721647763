import React from "react"
import "./DarkContentAndImage.scss"
import ButtonCTA from "../components/ButtonCTA"

const DarkContentAndImage = ({ item }) => {
  const { image, text, cta } = item.customFields
  return (
    <section className={`dark-content-image item-${item.contentID}`}>
      <img className="left-image d-md-flex d-none" src={image.url} alt={image.label} />
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-12 col-md-9 align-items-space-between">
            <div className="text">{text}</div>
            <ButtonCTA
              data={{
                styles: "btn-transparent no-radius",
                text: cta.text + " →",
                href: cta.href,
                scrollToForm: true
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default DarkContentAndImage
