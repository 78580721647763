import "./Hero.scss"
import Form from "./../components/Form"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import { renderHTML } from "../agility/utils"
import HeroSubscribeForm from "../components/HeroSubscribeForm"
import arrow from "../img/arrow.svg"

const Hero = ({ item }) => {
  const customFields = item.customFields
  const { videoUrl, hidePlayerControls } = customFields
  const showControls =
    hidePlayerControls === "true" ? "?controls=0&rel=0" : "?rel=0"

  const thankyoupage = item.customFields.thankyoupage

  const lowQuality = `${
    customFields.lowQualityImage
      ? encodeURI(customFields.lowQualityImage.url)
      : ""
  }`
  const highQualityDesktop = `${encodeURI(
    customFields?.highQualityDesktopImage?.url
  )}`
  const highQuality = `${
    customFields.highQualityMobileImage
      ? encodeURI(customFields.highQualityMobileImage.url)
      : highQualityDesktop
  }`

  const headlineDecorationColor = `${
    customFields.headlineDecorationColor
      ? customFields.headlineDecorationColor.customFields.hexadecimalValue
      : "#E12A5C"
  }`
  const headlineTextColor = `${
    customFields.headlineTextColor
      ? customFields.headlineTextColor.customFields.hexadecimalValue
      : "#FFF"
  }`
  const titleColor = `${
    customFields.titleColor
      ? customFields.titleColor.customFields.hexadecimalValue
      : "#FFF"
  }`
  const subHeadlineColor = `${
    customFields.subheadlineColor
      ? customFields.subheadlineColor.customFields.hexadecimalValue
      : "#FFF"
  }`
  const buttonStyleClass = customFields.cTAStyle
    ? customFields.cTAStyle.customFields.cSSClass
    : "btn-primary-pink"

  const alt = customFields.highQualityDesktopImage?.label
    ? customFields.highQualityDesktopImage.label
    : customFields.title

  const backgroundStyles = `
    .hero.item-${item.contentID} .title {
      color: ${titleColor}
    }

    .hero.item-${item.contentID} .headline-decoration {
      color: ${headlineDecorationColor}
    }

    .hero.item-${item.contentID} .headline-content {
      color: ${headlineTextColor}
    }

    .hero.item-${item.contentID} .subheadline {
      color: ${subHeadlineColor}
    }

    .hero.item-${item.contentID} {
      background-image: url(${highQuality}),url(${lowQuality});
      min-height: 450px;
      background-position: ${customFields.backgroundPosition}
    }

    @media (min-width: 768px) {
      .hero.item-${item.contentID} {
        background-image: url(${highQualityDesktop}),url(${lowQuality});
        min-height: 550px;
      }
    }
  `

  const hasShadow = customFields.form?.customFields?.shadow

  const CTA = () => {
    if (
      customFields.scrollToFormOnClick &&
      customFields.cTASettings &&
      customFields.cTASettings.href &&
      customFields.hasForm
    ) {
      return (
        <Link
          className={`btn ${buttonStyleClass}`}
          to={customFields.cTASettings.href}
        >
          {customFields.cTASettings.text}
        </Link>
      )
    }

    if (
      customFields.scrollToFormOnClick &&
      customFields.cTASettings &&
      !customFields.cTASettings.href
      // !customFields.hasForm enables scrolling to the footer form
    ) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${buttonStyleClass}`}
          onClick={handleScrollToForm}
        >
          {customFields.cTASettings.text}
        </button>
      )
    }

    return null
  }

  return (
    <section
      className={`hero background-cover item-${item.contentID}`}
      title={alt}
      role="banner"
    >
      <Helmet>
        <link rel="preload" as="image" href={lowQuality} />
        <link rel="preload" as="image" href={highQuality} />
        <link rel="preload" as="image" href={highQualityDesktop} />
      </Helmet>
      <style>{backgroundStyles}</style>
      <div className="container pb-5">
        <div
          className={
            customFields.hasForm ? "row justify-content-between mt-5" : "row"
          }
        >
          <div
            className={
              customFields.hasForm === "true" ||
              customFields.addSubscribeForm === "true"
                ? "col-12 col-lg-4 mt-4 mb-sm-0 d-flex flex-column justify-content-start"
                : "col-12 col-md-12 mb-4 mb-md-0" //col-sm-10
            }
          >
            {customFields.headline && (
              <div className="headline-wrapper mt-md-5">
                <span
                  className="headline-decoration"
                  style={{
                    backgroundColor:
                      item.customFields.headlineDecorationColor.customFields
                        .hexadecimalValue,
                  }}
                ></span>
                <p className="headline-content mb-0">{customFields.headline}</p>
              </div>
            )}

            {/* <h1 className="title">{customFields.title}</h1> */}
            <div
              className={`title ${
                customFields.smallerTitleText ? "smaller" : ""
              }`}
              dangerouslySetInnerHTML={renderHTML(customFields.titleText)}
            />
            {customFields.subheadline ? (
              <div
                className={`subheadline mb-md-3 mb-2 ${
                  customFields.wrapSubheadline === "true" ? "wrapped" : ""
                }`}
                dangerouslySetInnerHTML={renderHTML(customFields.subheadline)}
              />
            ) : null}

            <CTA />
          </div>
          {videoUrl && (
            <div className="col-lg-8 pt-5 d-flex align-items-center hidden flex-column">
              <iframe
                className="embed-video"
                width="560"
                height="315"
                src={`https://www.youtube-nocookie.com/embed/${videoUrl
                  .split("/")
                  .pop()}${showControls}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <img className="arrow-to-form d-none d-md-block" src={arrow} alt="arrow" />
              {customFields.addSubscribeForm === "true" && (
                <HeroSubscribeForm
                  {...{
                    label: "Email Address",
                    btnTxt: "Sign Me Up!",
                    hero: true,
                  }}
                />
              )}
            </div>
          )}
          {customFields.hasForm === "true" && (
            <div className={`col-12 col-sm-5 col-md-6`}>
              <div
                className={`form-wrapper form-section ${
                  hasShadow ? "shadow" : ""
                }`}
              >
                {hasShadow && <span className="headline-line" />}
                <h2
                  className={`text-center mb-4 ${
                    customFields.changeFontType ? "change-font" : ""
                  }`}
                >
                  {customFields.formHeadline}
                </h2>
                <Form form={customFields.form} thankYouPage={thankyoupage} />
              </div>
            </div>
          )}
        </div>
        {/* {customFields.addSubscribeForm === "true" && (
          <HeroSubscribeForm
            {...{
              label: "Email Address",
              btnTxt: "Sign Me Up!",
              hero: true,
            }}
          />
        )} */}
      </div>
    </section>
  )
}

export default Hero
