import React from "react"
import { Helmet } from "react-helmet"
import "./PostDetails.scss"
import { renderHTML } from "./../agility/utils"
import moment from "moment"
import MostPopularPosts from "./../components/MostPopularPosts"
import MostPopularPodcasts from "./../components/MostPopularPodcasts"
import { getReadTime } from "./../agility/helpers"
import RelatedPosts from "./../components/RelatedPosts"
import RelatedPodcasts from "./../components/RelatedPodcasts"
import ShareOnMedia from "../components/ShareOnMedia"
import LazyImage from "../components/LazyImage"
import useHasMounted from "../hooks/useHasMounted"
import ReactAudioPlayer from "react-audio-player"
import EbookLead from "../components/EbookLead"
import { Link } from "gatsby"
import InsightForm from "../components/InsightForm"

const PostDetails = ({ dynamicPageItem, route }) => {
  const post = dynamicPageItem

  const hide_Ebook_lead_Form = true // delete this when need to show ebook form

  const categories = post.customFields.categories.map(
    category => category.customFields.name
  )
  const author = post.customFields.author
  const readTime = post.customFields.content
    ? getReadTime(post.customFields.content)
    : null

  const Popular = () => {
    if (post.customFields.podcast) {
      return <MostPopularPodcasts />
    } else {
      return <MostPopularPosts />
    }
  }

  const Related = () => {
    if (post.customFields.podcast) {
      return <RelatedPodcasts post={post} />
    } else {
      return <RelatedPosts post={post} />
    }
  }

  const PodcastSchema = () => {
    const hasMounted = useHasMounted()
    const url = hasMounted
      ? window.location.href
      : `https://pixel506.com/podcasts/${post.customFields.title
          .toLowerCase()
          .split(" ")
          .join("-")}`
    const timeRequired = post.customFields.timeRequired
      ? post.customFields.timeRequired
      : "PT50M"
    const schema = {
      "@context": "http://schema.org",
      "@type": "PodcastEpisode",
      url: url,
      name: post.customFields.title,
      datePublished: post.customFields.publishDate,
      timeRequired: timeRequired,
      description: post.customFields.excerpt,
      associatedMedia: {
        "@type": "MediaObject",
        contentUrl: post.customFields.podcast,
      },
      partOfSeries: {
        "@type": "PodcastSeries",
        name: post.customFields.title,
        url: post.customFields.podcast,
      },
    }

    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    )
  }

  const MetaDescriptions = () => {
    const { seo } = post
    const imageUrl = post.customFields.image?.url

    return (
      <Helmet
        meta={[
          {
            name: `description`,
            content: seo?.metaDescription,
          },
          {
            name: `og:description`,
            content: seo?.metaDescription,
          },
          {
            prefix: `og: http://ogp.me/ns#`,
            property: `og:image`,
            content: imageUrl,
          },
          {
            name: `twitter:description`,
            content: seo?.metaDescription,
          },
          {
            name: "twitter:image",
            content: imageUrl
          },
        ]}
      />
    )
  }

  const AuthorDetails = () => {
    return (
      <div className="author">
        <picture className="author-picture">
          <img
            src={author.customFields.photo.url}
            alt={`${author.customFields.name} Avatar`}
          />
        </picture>
        <div className="author-metadata">
          {author.customFields.linkToAuthorPage && (
            <Link
              id="schema_author"
              className="name"
              to={author.customFields.linkToAuthorPage.href}
            >
              {author.customFields.name}
            </Link>
          )}

          {!author.customFields.linkToAuthorPage && (
            <span className="name">{author.customFields.name}</span>
          )}

          <div className="date-read">
            <span id="schema_date" className="date">
              {" "}
              {moment(post.customFields.publishDate)
                .utcOffset("-06:00")
                .format("MMM D YYYY")}
            </span>
            {!post.customFields.podcast && (
              <span className="readtime">
                &nbsp;•&nbsp;{readTime} min
                {readTime > 1 && <span>s</span>} Read
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <article
      className="post-details"
      role="region"
      aria-labelledby="post-details-title"
    >
      {/* {post.seo && post.seo.metaDescription && <MetaDescriptions />} */}
      <MetaDescriptions />
      {post.customFields.podcast && <PodcastSchema />}
      <div className="post-details-metadata">
        <div className="container">
          <span id="schema_categories" className="categories">
            {categories.join(", ")}
          </span>
          <h1
            id="post-details-title"
            className="title"
            dangerouslySetInnerHTML={{ __html: post.customFields.title }}
          ></h1>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="excerpt" dangerouslySetInnerHTML={renderHTML(post.customFields.excerpt)}></div>
            </div>
            <div className="col-12 col-md-6">{author && <AuthorDetails />}</div>
          </div>
        </div>
      </div>

      {!post.customFields.podcast &&
        post.customFields.image &&
        post.customFields.imagePlaceholder && (
          <LazyImage
            src={post.customFields.image.url}
            placeholder={post.customFields.imagePlaceholder.url}
            alt={post.customFields.image ? post.customFields.image.label : ""}
            extraClasses="post-details-hero"
          />
        )}

      {!post.customFields.podcast &&
        post.customFields.image &&
        !post.customFields.imagePlaceholder && (
          <img
            className="post-details-hero"
            id="schema_image"
            src={post.customFields.image ? post.customFields.image.url : ""}
            alt={post.customFields.image ? post.customFields.image.label : ""}
          />
        )}

      <div className="container post-details-content">
        <div className="row">
          <div className="col-12 col-lg-7 col-xl-8" id="schema_bodyarticle">
            {post.customFields.content && (
              <article
                dangerouslySetInnerHTML={renderHTML(post.customFields.content)}
              />
            )}
            {post.customFields.podcast && (
              <div className="podcast-player">
                <ReactAudioPlayer
                  src={`https://anchor.fm/s/80c6d88/podcast/play/23525933/${post.customFields.podcast}`}
                  title={post.customFields.title}
                  preload
                  controls
                />
              </div>
            )}
            <InsightForm /> 
            {post.customFields.hasHorizontalAd && !hide_Ebook_lead_Form && (
              <div
                className={
                  post.customFields.hasVerticalAd
                    ? "d-none d-sm-block"
                    : "wrapper"
                }
              >
                <EbookLead
                  alignment="horizontal"
                  title={post.customFields.leadManagmentAdTitle}
                  subtitle={post.customFields.leadManagmentAdSubtitle}
                  styling={post.customFields.leadManagmentAdStyling}
                  thumbnail={post.customFields.leadManagmentThumbnail}
                />
              </div>
            )}
          </div>
          <div className="col-12 col-lg-5 col-xl-4">
            <div className="sticky">
              {post.customFields.hasVerticalAd === "true" && !hide_Ebook_lead_Form && (
                <EbookLead
                  alignment="vertical"
                  title={post.customFields.leadManagmentAdTitle}
                  subtitle={post.customFields.leadManagmentAdSubtitle}
                  styling={post.customFields.leadManagmentAdStyling}
                  thumbnail={post.customFields.leadManagmentThumbnail}
                  nearshoreInsight={post.customFields.nearshoreInsight}
                />
              )}
              <Popular />
              <ShareOnMedia post={post} route={route} />
            </div>
          </div>
        </div>
      </div>

      <Related />
    </article>
  )
}

export default PostDetails
