import React, { useState, useEffect, useRef, Fragment } from "react"
import "./FactsModule.scss"

const FactsModule = ({ item }) => {
  const { customFields } = item

  const FactNumber = ({ fact, key }) => {
    const { number, description } = fact.customFields
    const numberToStop = number.replace(/[^0-9]/g, "")

    const _intervalRef = useRef(null)
    const [startCounter, setStartCounter] = useState(false)
    const [counter, setCounter] = useState(0)

    useEffect(() => {
      if (startCounter && counter === 0) {
        _intervalRef.current = setInterval(() => {
          setCounter(counter => counter + 1)
        }, 10)
      } else {
        clearInterval(_intervalRef.current)
      }
      return () => clearInterval(_intervalRef.current)
    }, [startCounter])

    useEffect(() => {
      if (counter >= numberToStop) {
        setStartCounter(false)
      }
    }, [counter])

    return (
      <div
        className="col-md col-12"
        key={key}
        onMouseOver={() => setStartCounter(true)}
      >
        <p className="text-center numbers">
          <span className="number">{counter}%</span> <br />
          {description}
        </p>
      </div>
    )
  }

  return (
    <section className={`fact-module-${item.contentID} fact-module`}>
      <div className="container">
        <div className="wrapper">
          <span className="headline-line" />
          <div className="row">
            <div className="col-12">
              <h2 className="title">{customFields.title}</h2>
            </div>
          </div>
          <div className="row">
            {customFields.funFacts.map((fact, index) => {
              return (
                <Fragment key={index}>
                  <FactNumber fact={fact} />
                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FactsModule
