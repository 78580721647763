import React from "react"
import { graphql, StaticQuery } from "gatsby"
import MostPopularInsightCard from "./MostPopularInsightCard"

/**
 * Anonymous element to query the podcasts posts and render them using the MostPopular component.
 */

export default () => (
  <StaticQuery
    query={graphql`
      {
        allAgilityInsight {
            nodes {
              sitemapNode {
                pageView
                pagePath
              }
              customFields {
                title
                excerpt
                image {
                  url
                }
                publishDate(formatString: "MMMM DD, yyyy")
              }
              categories {
                customFields {
                  name
                }
              }
              author {
                customFields {
                  name
                  photo {
                    url
                  }
                }
              }
            }
          }
        }
    `}
    render={queryData => {
      const allPageViews = queryData.allAgilityInsight.nodes || null
      const mostPopular = allPageViews
        .filter(a => a.sitemapNode !== null)
        .sort((a, b) => a.sitemapNode.pageView - b.sitemapNode.pageView)
        .reverse()
        .slice(0, 3)

      return <MostPopularInsightCard popular={mostPopular} />
    }}
  />
)
