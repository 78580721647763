import React from "react"
import "./GridListingAndImage.scss"
import SVG from "../components/SVG"
import { hexToRgba, trimText, isSingleDigit } from "./../agility/helpers"
import { renderHTML } from "../agility/utils"

const GridLisitingAndImage = ({ item }) => {
  const customFields = item.customFields
  const moduleColor = customFields.mainColor.customFields.hexadecimalValue
  const textColor =
    customFields.backgroundColor === "black" ? "#FFF" : "#1C1C1C"
  const maximunCharacters = 70

  const dynamicStyles = `
    .grid-listing-img.gli-${item.contentID} .title {
      color: ${textColor}
    }
    .grid-listing-img.gli-${item.contentID} .grid-item__index {
      color: ${hexToRgba(moduleColor, 0.5)};
      border-color: ${moduleColor};
    }
    .grid-listing-img.gli-${item.contentID} .grid-item__description {
      color: ${textColor}
    }
  `

  const list = customFields.informationListing.map((item, key) => {
    const humanIndex = key + 1
    return {
      ...item.customFields,
      color: moduleColor,
      number: isSingleDigit(humanIndex) ? `0${key + 1}` : `${key + 1}`,
    }
  })

  const GridItem = ({ props }) => {
    return (
      <div className={"col-6 grid-item"}>
        <p className="grid-item__index">{props.number}</p>

        <div className="grid-item__description">
          {/* {trimText(props.description, maximunCharacters)} */}
          <div dangerouslySetInnerHTML={renderHTML(props.description)} />
        </div>
      </div>
    )
  }

  const GridItems = ({ list }) => {
    return list.map((listItem, index) => {
      return <GridItem props={listItem} key={index} />
    })
  }

  return (
    <section
      className={`grid-listing-img gli-${item.contentID} ${customFields.backgroundColor}`}
    >
      <style>{dynamicStyles}</style>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="headline-decoration"
              style={{ borderColor: moduleColor }}
            ></div>
            <h2 className="title">{customFields.title}</h2>
          </div>
          <div className="col-12 col-sm-6">
            <div className="row">
              <GridItems list={list} />
            </div>
          </div>
          <div className="col-12 col-sm-6 img-wrapper">
            <img
              className="image desktop d-none d-sm-block"
              src={customFields.image.url}
              alt={customFields.title}
            />
            <img
              className="image mobile d-block d-sm-none"
              src={customFields.mobileImage.url}
              alt={customFields.title}
            />
          </div>
        </div>
      </div>
      <div className="squares-wrapper d-none d-md-block">
        <SVG
          name={"dotted-squares"}
          width={495}
          height={226}
          fill={moduleColor}
        ></SVG>
      </div>
    </section>
  )
}

export default GridLisitingAndImage
