import React, { Fragment } from 'react'
import "./TitleAndRowCards.scss"

const TitleAndRowCards = ({ item }) => {
  const { title, description, contentItems
  } = item.customFields

  return (
    <section className={`title-row-cards ${item.contentID}`}>
      <div className='container'>
        <div className='row no-gutters'>
          <div className="col-12 col-md-8">
            <h2 className='title'>{title}</h2>
          </div>
        </div>
        <div className='row'>
          <div className="col-12 col-md-6">
            <p className='description mt-4'>{description}</p>
          </div>
        </div>
        <div className="row">
          {contentItems && contentItems.map((item, index) => (
            <Fragment key={item.contentID}>
              <div className="item col-12 col-md-3 d-flex flex-column justify-content-center align-items-center">
                <div className="item-icon">
                  <img src={item.customFields.icon.url} alt={item.customFields.icon.label} />
                </div>
                <div className="item-body">
                  <h4 className="item-body-title">{item.customFields.title}</h4>
                  <p className="item-body-text">{item.customFields.description}</p>
                </div>
                <div className="arrow-container d-md-none">
                  {index !== contentItems.length - 1 && <div className="arrow-line" />}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}

export default TitleAndRowCards