import React from 'react'
import { renderHTML } from "./../agility/utils"
import './ContentBlock.scss'

const ContentBlock = ({item}) => {

  return (
    <section className="content-block container">
      <h1>{item.customFields.title}</h1>
      <div className="content-block-content" dangerouslySetInnerHTML={renderHTML(item.customFields.content)}></div>
    </section>
  )
}

export default ContentBlock