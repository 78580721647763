import React from "react"
import "./IconsAndText.scss"

export const IconsAndText = ({ item }) => {
  const customFields = item.customFields

  return (
    <section className={`icon-text icon-text-${item.contentID}`} icon-text>
      <div className="container-fluid px-0">
        <div className="row no-gutters mt-0">
          <div className="col-12 col-md-12 lines-image d-flex justify-content-start">
            <img src={customFields?.topLinesImage.url} alt="" />
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-6 col-md-3 col-sm-6 d-flex justify-content-center flex-column align-items-center">
            <img src={customFields?.iconLocations.url} alt="icon" />
            <p>{customFields.locationsText}</p>
          </div>
          <div className="col-6 col-md-3 col-sm-6 d-flex justify-content-center flex-column align-items-center">
            <img src={customFields?.iconExperience.url} alt="icon" />
            <p>{customFields.experienceText}</p>
          </div>
          <div className="col-6 col-md-3 col-sm-6 d-flex justify-content-center flex-column align-items-center">
            <img src={customFields?.iconMembers.url} alt="icon" />
            <p>{customFields.membersText}</p>
          </div>
          <div className="col-6 col-md-3 col-sm-6 d-flex justify-content-center flex-column align-items-center">
            <img src={customFields?.iconClients?.url} alt="icon" />
            <p>{customFields.clientsText}</p>
          </div>
        </div>
        <div className="row no-gutters ">
          <div className="col-6 col-md-4 col-sm-6 d-flex justify-content-center flex-column align-items-center">
            <img src={customFields?.iconYears.url} alt="icon" />
            <p>{customFields.yearsText}</p>
          </div>
          <div className="col-6 col-md-4 col-sm-6 d-flex justify-content-center flex-column align-items-center">
            <img src={customFields?.iconCountries.url} alt="icon" />
            <p>{customFields.countriestext}</p>
          </div>
          <div className="col-12 col-md-4 col-sm-12 d-flex justify-content-center flex-column align-items-center">
            <img src={customFields?.iconIndustries.url} alt="icon" />
            <p>{customFields.industriesText}</p>
          </div>
        </div>
        <div className="row no-gutters mt-0">
          <div className="col-12 col-md-12 lines-image d-flex justify-content-end">
            <img src={customFields?.bottomLinesImage.url} alt="img" />
          </div>
        </div>
      </div>
    </section>
  )
}
export default IconsAndText
