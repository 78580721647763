import React from "react"
import "./TwoColumnsAndGradientForm.scss"
import Form from "./../components/Form"
import { renderHTML } from "../agility/utils"

const TwoColumnsAndGradientForm = ({ item }) => {
  const {
    title,
    descriptionContent,
    imageBackground,
    mobileBackground,
    formHeadline,
    thankYouPage,
    form,
  } = item.customFields

  const backgroundStyles = `.two-columns-gradient-form.item-${item.contentID} {
    background-image: url(${mobileBackground?.url});
    background-size: cover;
  }
  @media (min-width: 768px) {
    .two-columns-gradient-form.item-${item.contentID} {
      background-image: url(${imageBackground?.url});
      background-size: cover;
    }
  }`

  return (
    <section className={`two-columns-gradient-form item-${item.contentID}`}>
      <style>{backgroundStyles}</style>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col">
            <h2 className="title">{title}</h2>
            <div
              className="description"
              dangerouslySetInnerHTML={renderHTML(descriptionContent)}
            />
          </div>
          <div className="col-md-6">
            <div className="form-wrapper form-section">
              <h2 className="text-center mb-4">{formHeadline}</h2>
              <Form form={form} thankYouPage={thankYouPage} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnsAndGradientForm
