import React from "react"
import "./FormSection.scss"
import Form from "./../components/Form"

const FormSection = ({ item }) => {
  const detailColor = item.customFields.detailColor
    ? item.customFields.detailColor.customFields.hexadecimalValue
    : "#E12A5C"
  const thankyoupage = item.customFields.thankyoupage

  return (
    <section className="form-section section">
      <div className="container">
        <h2 className="form-section-title">
          <span className="wrapper">
            <span
              style={{
                backgroundColor: detailColor,
              }}
            />
            {item.customFields.title}
          </span>
        </h2>
        <Form form={item.customFields.form} thankYouPage={thankyoupage} />
      </div>
    </section>
  )
}

export default FormSection
