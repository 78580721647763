import React from "react"
import LazyImage from "../components/LazyImage"
import { renderHTML } from "../agility/utils"
import ButtonCTA from "../components/ButtonCTA"
import "./TwoColumnsContentAndImage.scss"

const TwoColumnsContentAndImage = ({ item }) => {
  const {
    title,
    text,
    cTASettings,
    buttonStyle,
    image,
    imageTitle,
    imageFirstOnMobile,
  } = item.customFields

  const ctaClass = buttonStyle?.customFields?.cSSClass

  const imageOrder = imageFirstOnMobile ? "order-first" : "order-last"

  return (
    <section className={`two-columns-ci ${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className={`col-md-6 d-flex flex-column align-items-start ${imageOrder} order-md-first`}>
            {imageTitle && <h3 className="image-title">{imageTitle}</h3>}
            <LazyImage
              src={image.url}
              alt={image.label}
              extraClasses="image m-md-0 mt-5"
            />
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            {title && <h2 className="title">{title}</h2>}
            <div className="text mt-md-0 mt-5" dangerouslySetInnerHTML={renderHTML(text)} />
            {cTASettings && (
              <ButtonCTA
                data={{
                  text: cTASettings.text,
                  styles: ctaClass,
                  href: cTASettings.href,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnsContentAndImage
