import React, { useEffect, useRef, useState } from "react"
import "./AnimatedProcess.scss"
import { renderHTML } from "../agility/utils"

import Arcs from "/static/graphic_vector.svg"

const AnimatedProcess = ({ item }) => {
  const {
    mainTitle,
    description,
    processContent,
    subtitle,
    text,
    backgroundImage,
    horizontalTimeline,
  } = item.customFields

  const backgroundStyles = `
    @media (min-width: 768px) {
      .animated-process.item-${item.contentID} {
        background-image: url(${backgroundImage?.url});
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  `

  const ContentCard = ({ content, extraClass, showCircle = "" }) => {
    const { title, description, icon } = content.customFields
    return (
      <div
        className={`col-md-${
          horizontalTimeline ? "2" : "3"
        } col-12 p-lg-0 timeline-item text-center d-flex flex-column align-items-center ${extraClass}`}
      >
        {icon && <img className="card-icon" src={icon.url} alt={icon.label} />}
        <div className={`card-circle ${showCircle}`}></div>
        <h4
          className={`card-title ${
            horizontalTimeline === "true" ? "align-self-start" : ""
          }`}
        >
          {title}
        </h4>
        <p
          className={`card-description m-0 ${
            horizontalTimeline === "true" ? "horizontal" : ""
          }`}
        >
          {description}
        </p>
      </div>
    )
  }

  const Timeline = () => {
    const [show, setShow] = useState(false)
    const [showItem, setShowItem] = useState(false)
    const animatedRef = useRef(null)

    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setShow(true)
          setShowItem(true)
          observer.unobserve(animatedRef.current)
        }
      })

      observer.observe(animatedRef.current)

      return () => {
        observer.unobserve(animatedRef.current)
      }
    }, [])

    return (
      <div className="row">
        <div className="col-12">
          <div className="timeline d-flex justify-content-between">
            <div
              className={`line ${show ? "show" : ""} ${
                horizontalTimeline === "true" ? "horizontal" : ""
              }`}
              ref={animatedRef}
            />
            {horizontalTimeline && (
              <img className="arcs d-none d-md-block" src={Arcs} alt="arcs" />
            )}
            <ContentCard
              content={processContent[0]}
              extraClass={`item-1 ${showItem ? "show-item" : ""} ${
                horizontalTimeline === "true" ? "horizontal" : ""
              }`}
              showCircle={`${horizontalTimeline ? "d-md-none" : ""}`}
            />
            <ContentCard
              content={processContent[1]}
              extraClass={`item-2 ${showItem ? "show-item" : ""} ${
                horizontalTimeline === "true" ? "horizontal" : ""
              }`}
              showCircle={`${horizontalTimeline ? "d-md-none" : ""}`}
            />
            <ContentCard
              content={processContent[2]}
              extraClass={`item-3 ${showItem ? "show-item" : ""} ${
                horizontalTimeline === "true" ? "horizontal" : ""
              }`}
              showCircle={`${horizontalTimeline ? "d-md-none" : ""}`}
            />
            <ContentCard
              content={processContent[3]}
              extraClass={`item-4 ${showItem ? "show-item" : ""} ${
                horizontalTimeline === "true" ? "horizontal" : ""
              }`}
              showCircle={`${horizontalTimeline ? "d-md-none":""}`}
            />
            {horizontalTimeline === "true" && (
              <ContentCard
                content={processContent[4]}
                extraClass={`item-4 ${showItem ? "show-item" : ""} ${
                  horizontalTimeline === "true" ? "horizontal" : ""
                }`}
                showCircle={`${horizontalTimeline ? "d-md-none" : ""}`}
              />
            )}
          </div>
        </div>
        <div className="col-md-8">
          <div className="timeline-line"></div>
        </div>
      </div>
    )
  }

  return (
    <section className={`animated-process item-${item.contentID}`}>
      <style>{backgroundStyles}</style>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2 className="main-title">{mainTitle}</h2>
            <p className="description">{description}</p>
          </div>
        </div>
        <Timeline />
        <div className="row">
          <div className="col-md-8">
            <h2 className="subtitle">{subtitle}</h2>
          </div>
          <div className="col-md-6">
            {text && (
              <div
                className="text mt-md-5"
                dangerouslySetInnerHTML={renderHTML(text)}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AnimatedProcess
