import React from "react"
import { renderHTML } from "../agility/utils"
import { Link } from "gatsby"
import "./FullImageBackgroundAndText.scss"

export const FullImageBackgroundAndText = ({ item }) => {
  const customFields = item.customFields

  const alt = customFields?.imageBackground?.label
    ? customFields.imageBackground.label
    : ""
  const placeholder = customFields.placeholder
    ? customFields.placeholder.url
    : customFields?.imageBackground?.url

  const styles = `.full-img-background-text.full-img-background-text-${item.contentID} .bg-img {
    background-image: url(${customFields?.imageBackground?.url}), url(${placeholder});

    @media (min-width: 768px) {
        .full-img-background-text.full-img-background-text-${item.contentID} .bg-img {
          background-image: url(${customFields.imageBackground.url}), url(${placeholder});
        }
      }
  }`
  const CTA = () => {
    const buttonStyleClass = customFields.buttonStyle.customFields.cSSClass
      ? customFields.buttonStyle.customFields.cSSClass
      : "btn-primary-white"

    if (customFields.cTALink && customFields.cTALink.href !== "") {
      return (
        <Link
          className={`btn ${buttonStyleClass} mt-4 cta`}
          to={customFields.cTALink.href}
        >
          {customFields.cTALink.text}
        </Link>
      )
    }

    //If href is not setted, so it goes to the form in the same pahge
    if (customFields.cTALink && customFields.cTAScrollToForm) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${buttonStyleClass} mt-4 cta`}
          onClick={handleScrollToForm}
        >
          {customFields.cTALink.text}
        </button>
      )
    }
    return null
  }

  return (
    <section
      className={`full-img-background-text full-img-background-text-${item.contentID}`}
    >
      <style>{styles}</style>
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <div className="col-12 col-md-12">
            <figure className="bg-img mb-0" role="img" title={alt}>
              <div className="row">
                <div className="col-12 col-md-6 col-sm-12">
                  <div className="block-text-wrapper d-flex flex-column justify-content-center">
                    <h1 className="block-title">{customFields.title}</h1>
                    <div
                      className="text-block pb-1 px-sm-2 pt-3"
                      dangerouslySetInnerHTML={renderHTML(
                        customFields.textblock
                      )}
                    />
                    <CTA />
                  </div>
                </div>
              </div>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}
export default FullImageBackgroundAndText
