import React from "react"
import loadable from "@loadable/component"
import LoadableContentZone from "../agility/components/LoadableContentZone"
const GlobalHeader = loadable(() => import("../components/GlobalHeader"))
const EFYBadge = loadable(() => import("../components/EFYBadge"))
const MainFooter = loadable(() => import("../components/MainFooter"))

const DarkHeaderTemplateEFY = props => {
  return (
    <div className="pixel506">
      <GlobalHeader
        languageCode={props.languageCode}
        isMultiLanguage={props.isMultiLanguage}
        variation="dark"
        route={props.route}
      ></GlobalHeader>
      <main>
        <LoadableContentZone name="MainContentZone" {...props} />
      </main>
      <EFYBadge />
      <MainFooter
        languageCode={props.languageCode}
        isMultiLanguage={props.isMultiLanguage}
        route={props.route}
      />
    </div>
  )
}
export default DarkHeaderTemplateEFY
