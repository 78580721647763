import React, { useEffect, useState, useRef, useCallback } from "react"
import { scrollTo, swipe } from "./../agility/helpers"
import { debounce } from "throttle-debounce"
import "./FullHeight.scss"

/**
 * Detects if the element is not undefined and extends the prototype of the element with the swipe module.
 */

if (typeof Element !== `undefined`) Element.prototype.swipe = swipe

/**
 * Creates a component using the high level API React.memo() to set a container with a full height.
 * @function FullHeight
 */

const FullHeight = React.memo(({ children }) => {
  const updating = useRef(false)
  const quietPeriod = 200
  const animationTime = 300
  const container = useRef()
  let lastAnimation = new Date().getTime()
  const [activePanel, setActivePanel] = useState(0)
  const body =
    typeof document !== `undefined`
      ? document.documentElement.querySelector("body")
      : null

  /**
   * Sets a memoized callback for getting the sections with the full-height-section class.
   * @function getSections
   */

  const getSections = useCallback(() => {
    return [...container.current.querySelectorAll(".full-height-section")]
  }, [container])

  /**
   * Returns an object with boolean values if the page is on the top or in in the bottom.
   * @function scrolling
   */

  const scrolling = () => {
    const sections = getSections()
    const top = sections[activePanel].scrollTop > 0
    const bottom =
      sections[activePanel].scrollTop + sections[activePanel].clientHeight <
      sections[activePanel].scrollHeight

    return {
      top: top,
      bottom: bottom,
    }
  }

  /**
   * Detects the next element from the active element.
   * @function next
   */

  const next = () => {
    const isScrolling = scrolling()
    const sections = getSections()
    if (isScrolling.bottom || updating.current) return

    const activeElement =
      typeof document !== `undefined` ? document.activeElement : null
    if (activeElement) activeElement.blur()

    const prevSlide = activePanel
    const nextSlide =
      activePanel + 1 >= sections.length ? activePanel : activePanel + 1

    if (prevSlide === nextSlide) return
    goTo(nextSlide)
  }

  /**
   * Detects the previous element from the active element.
   * @function prev
   */

  const prev = () => {
    const isScrolling = scrolling()
    if (isScrolling.top || updating.current) return

    const activeElement =
      typeof document !== `undefined` ? document.activeElement : null
    if (activeElement) activeElement.blur()

    const prevSlide = activePanel
    const nextSlide = activePanel - 1 < 0 ? 0 : activePanel - 1

    if (prevSlide === nextSlide) return
    goTo(nextSlide)
  }

  /**
   * Callback that handles the mouse wheel movement.
   * @function mounseWheelHandler.
   */

  const mouseWheelHandler = (event) => {
    const isScrolling = scrolling()
    const currenTime = new Date().getTime()
    const animating = currenTime - lastAnimation < quietPeriod + animationTime

    if (
      updating.current ||
      body.classList.contains("menu-open") ||
      (isScrolling.top && isScrolling.bottom)
    ) {
      return
    }

    if (animating) {
      if (updating.current) event.preventDefault()
      return
    }

    const delta = event.wheelDelta || -event.detail

    if (delta < 0) next()
    else prev()

    lastAnimation = currenTime
  }

  /**
   * Callback to analyze the sections, remove and add active class depending on what
   * is needed.
   * @function scrollToActive
   */
  const scrollToActive = useCallback(() => {
    if (typeof window === `undefined`) return

    const sections = getSections()
    const end = window.innerHeight * activePanel
    sections.forEach((section) => {
      section.classList.remove("active")
    })
    updating.current = true
    scrollTo(
      end,
      () => {
        updating.current = false
        sections[activePanel].classList.add("active")
      },
      animationTime
    )
  }, [activePanel, updating, getSections])

  /**
   * Sets an element visibile.
   * @function goTo
   */

  const goTo = (panel) => {
    setActivePanel(panel)
  }

  /**
   * If the window is resized debounces the page.
   * @function handleResize
   */

  const handleResize = debounce(500, () => {
    scrollToActive()
  })

  /**
   * swipe up event handler.
   * @function swipeUp
   */

  const swipeUp = (e) => {
    e.preventDefault()
    const isScrolling = scrolling()
    if (
      updating.current ||
      body.classList.contains("menu-open") ||
      (isScrolling.top && isScrolling.bottom)
    ) {
      return
    }
    next()
  }

  /**
   * swipe up event handler.
   * @function swipeDown
   */

  const swipeDown = (e) => {
    e.preventDefault()
    const isScrolling = scrolling()
    if (
      updating.current ||
      body.classList.contains("menu-open") ||
      (isScrolling.top && isScrolling.bottom)
    ) {
      return
    }
    prev()
  }

  useEffect(() => {
    scrollToActive()
  }, [activePanel, scrollToActive])

  /**
   * When the component is mounted, the useEffect hook binds the different handlers.
   */

  useEffect(() => {
    const scrollerSection = container.current
    if (typeof window !== `undefined`) {
      document.addEventListener("mousewheel", mouseWheelHandler, {
        passive: false,
      })
      document.addEventListener("DOMMouseScroll", mouseWheelHandler)
      window.addEventListener("resize", handleResize)
      scrollerSection.swipe("down", swipeDown)
      scrollerSection.swipe("up", swipeUp)
      body.classList.add("scroll-blocked")
    }

    return () => {
      if (typeof window !== `undefined`) {
        document.removeEventListener("mousewheel", mouseWheelHandler, {
          passive: false,
        })
        document.removeEventListener("DOMMouseScroll", mouseWheelHandler)
        window.removeEventListener("resize", handleResize)
        scrollerSection.swipe("down", prev, false, true)
        scrollerSection.swipe("up", next, false, true)
        body.classList.remove("scroll-blocked")
      }
    }
  })

  const childrenWithEvent = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      goToSlide: goTo,
      currentslide: activePanel,
    })
  )

  /**
   * Returns the full height element.
   */

  return (
    <section className="full-height" ref={container}>
      {childrenWithEvent}
    </section>
  )
})

export default FullHeight
