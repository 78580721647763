import React from "react"
import ContentZone from "../agility/components/ContentZone"
import LandingHeader from "./../components/LandingHeader"
import LandingFooter from "./../components/LandingFooter"
// import Badge from "./../components/Badge"

const LandingPageTemplate = props => {
  return (
    <div className="pixel506">
      <LandingHeader />
      <main>
        <ContentZone name="MainContentZone" {...props} />
      </main>
      {/* <Badge/> */}
      <LandingFooter />
    </div>
  )
}
export default LandingPageTemplate
