import React from "react"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./TrustedBySection.scss"

export const TrustedBySection = ({ item }) => {
  const customFields = item.customFields

  const renderSlides = () => {
    const { logoList } = customFields

    const logos = logoList.map((logo, i) => {
      return (
        <img
          key={i}
          className="logo-img"
          src={logo.customFields.logoImage.url}
          alt="logo"
        />
      )
    })
    return logos
  }

  const Arrow = props => {
    let className = props.type === "next" ? "nextArrow" : "prevArrow"
    className += " arrow"
    const char = props.type === "next" ? ">" : "<"

    const handleKeyDown = e => {
      if (e.key === 'Enter' || e.key === ' ') {
        props.onClick();
      }
    }

    return (
      <span
        className={className}
        onClick={props.onClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        {char}
      </span>
    )
  }

  return (
    <section className={`trusted-by trusted-by-${item.contentID}`}>
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <div className="col-12 col-md-12 d-flex justify-content-center">
            <h2 className="title pt-5">{customFields.title}</h2>
          </div>
        </div>
        <div className="row no-gutters slider-hidden-mb">
          <div className="col-12 col-md-12">
            <Slider
              className=""
              dots={false}
              slidesToShow={6}
              slidesToScroll={2}
              autoplay={true}
              autoplaySpeed={3000}
              nextArrow={<Arrow type="next" />}
              prevArrow={<Arrow type="prev" />}
            >
              {renderSlides()}
            </Slider>
          </div>
        </div>
        <div className="row slider-shown-mb">
          {renderSlides().map((slice, indx) => {
            return (
              <div key={indx} className="col-4">
                {slice}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
export default TrustedBySection
