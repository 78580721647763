import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { shuffleArray } from "./../agility/helpers"
import Related from "./Related"

/**
 * Anonymous component to fetch the related podcasts from Agility to render them to the Related component.
 */

export default (props) => (
  <StaticQuery
    query={graphql`
      query AllPodcastsQuery {
        allAgilityPodcast {
          nodes {
            contentID
            categories {
              customFields {
                name
              }
            }
            customFields {
              title
              excerpt
              publishDate
              content
              image {
                url
              }
            }
            sitemapNode {
              pagePath
              pageView
            }
            author {
              customFields {
                name
                photo {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const postCategory = props.post.customFields.categories.map(
        (category) => category.slug
      )
      const queryPosts = data.allAgilityPodcast.nodes.filter((post) => {
        return Number(post.contentID) !== Number(props.post.contentID)
      })
      const relatedPosts = queryPosts.filter((post) => {
        const categories = post.categories.map((category) => category.slug)
        return categories.some((category) => postCategory.includes(category))
      })
      const randomizedRelatedPosts = shuffleArray(relatedPosts)
      const posts = randomizedRelatedPosts.slice(0, 3)

      return <Related posts={posts} />
    }}
  />
)
