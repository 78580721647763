import React from "react"
import { Link } from "gatsby"

const CallToAction = ({
  scrollToFormOnClick,
  text,
  href,
  buttonStyleClass,
}) => {
  if (!scrollToFormOnClick && href && buttonStyleClass) {
    return (
      <Link className={`btn ${buttonStyleClass}`} to={href}>
        {text}
      </Link>
    )
  }

  if (scrollToFormOnClick && !href) {
    const handleScrollToForm = () => {
      if (typeof window === `undefined`) return
      if (document.querySelector(".form-section")) {
        window.scrollTo({
          top: document.querySelector(".form-section").offsetTop,
          behavior: "smooth",
        })
      }
    }

    return (
      <button
        className={`btn ${buttonStyleClass}`}
        onClick={handleScrollToForm}
      >
        {text}
      </button>
    )
  }

  return null
}

export default CallToAction
