import React, { Fragment } from "react"
import { renderHTML } from "../agility/utils"
import "./RowAndContent.scss"

const RowAndContent = ({ item }) => {
  const { headlineTitle, description, content } = item.customFields

  const ContentCol = ({ content }) => {
    const { title, description } = content.customFields
    return (
      <div className="row row-service">
        <div className="col-md-5 d-flex p-0 col-12">
          <h6 className="content-title">{title}</h6>
        </div>
        <div className="col-md-7 p-0 col-12">
          <p className="content-description">{description}</p>
        </div>
      </div>
    )
  }

  return (
    <section className={`row-content item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-12">
            <div
              className="title"
              dangerouslySetInnerHTML={renderHTML(headlineTitle)}
            />
          </div>
          <div className="col-md-8">
            <p className="description mt-md-4">{description}</p>
          </div>
        </div>
        {content &&
          content.map((content, index) => {
            return (
              <Fragment key={index}>
                <ContentCol content={content} />
              </Fragment>
            )
          })}
      </div>
    </section>
  )
}

export default RowAndContent
