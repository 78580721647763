import React from "react"
import { InlineWidget } from "react-calendly"
import "./EmbedCalendly.scss"

const EmbedCalendly = ({ item }) => {
  const { cTA, calendlyScheduleUrl } = item.customFields

  return (
    <section className={`embed-calendly ec-${item.contentID}`}>
      <div className="container">
        <h2>{cTA}</h2>
        <div className="row">
          <div className="col-12">
            <InlineWidget url={calendlyScheduleUrl.href} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default EmbedCalendly
