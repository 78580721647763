import React from "react"
import "./LatestInsights.scss"
import Img from "gatsby-image"
import Subscribe from "./../components/Subscribe"
import { Link, graphql, StaticQuery } from "gatsby"
import { generateSlug } from "./../agility/helpers"
import { renderHTML } from "./../agility/utils"

export default props => (
  <StaticQuery
    query={graphql`
      query LatestInsights {
        allAgilityInsight(
          sort: { order: DESC, fields: customFields___publishDate }
        ) {
          nodes {
            categories {
              customFields {
                name
                slug
              }
            }
            customFields {
              title
              content
              excerpt
              publishDate
              imageLocalImg {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 480) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            sitemapNode {
              pagePath
            }
          }
        }
      }
    `}
    render={queryData => {
      let posts = queryData.allAgilityInsight.nodes
      const filters = props.item.customFields.filters
      if (filters) {
        const categories = filters.map(filter => filter.customFields.slug)
        posts = queryData.allAgilityInsight.nodes.filter(post => {
          const postCategories = post.categories.map(
            category => category.customFields.slug
          )
          return postCategories.some(category => categories.includes(category))
        })
      }
      posts = posts.splice(0, 3)
      return <LatestInsights insights={posts} {...props} />
    }}
  />
)

const Insight = ({ data, subscribeLabels }) => {
  // const {content, excerpt, imageLocalImg, publishDate, title} = data.customFields
  const {excerpt} = data.customFields

  const categoriesRender = data.categories.map(
    category => category.customFields.name
  )
  const link = data.sitemapNode
    ? data.sitemapNode.pagePath
    : `/insights/${generateSlug(data.customFields.title)}`

  return (
    <article className="latest-insights-item">
      <Link className={`picture ${subscribeLabels ? "": "bigger"}`} to={link}>
      {/* <Link className="picture" to={link}> */}
        <Img
          fluid={data.customFields.imageLocalImg?.childImageSharp?.fluid}
          alt={data.customFields.title}
        />
      </Link>
      <div className="wrapper">
        <span className="categories">{categoriesRender.join(" & ")}</span>
        <Link to={link}>
          <h3
            className="title"
            dangerouslySetInnerHTML={renderHTML(data.customFields.title)}
          ></h3>
        </Link>
        <div className="excerpt" dangerouslySetInnerHTML={renderHTML(excerpt)}/>
        {/* <p className="excerpt">{data.customFields.excerpt}</p> */}
      </div>
    </article>
  )
}

const LatestInsights = ({ insights, item }) => {
  const inputLabel = item.customFields.inputLabel
    ? item.customFields.inputLabel
    : "Email Address"
  const submitButtonLabel = item.customFields.submitButtonLabel
    ? item.customFields.submitButtonLabel
    : "Subscribe"
    
  const subscribeLabels =
    item.customFields?.subscribeLabel || item.customFields?.emailLabel

  return (
    <section className="latest-insights section">
      <div className="container">
        <h2>{item.customFields.title}</h2>
        <div className="row">
          {/* <div className="col-12 col-md-6 col-xl-7 latest-insights-posts"> */}
          <div
            className={`col-12 ${
              subscribeLabels
                ? "col-md-6 col-xl-7"
                : "col-md-12 d-flex flex-column align-items-center"
            } latest-insights-posts`}
          >
            {insights.map((insight, index) => (
              <Insight data={insight} key={index} subscribeLabels={subscribeLabels}/>
            ))}
            {item.customFields.cTALink && item.customFields.cTALink.href && (
              <Link
                className="btn black btn-primary-pink"
                to={item.customFields.cTALink.href}
                target={item.customFields.cTALink.target}
              >
                {item.customFields.cTALink.text}
              </Link>
            )}
          </div>
          {subscribeLabels && (
            <div className="col-12 col-md-6 col-xl-5">
              <div className="subscribe">
                <h4>{item.customFields.subscribeLabel}</h4>
                <p>{item.customFields.emailLabel}</p>
                <Subscribe label={inputLabel} btnTxt={submitButtonLabel} />
              </div>
            </div>
          )}
          {/* <div className="col-12 col-md-6 col-xl-5">
            <div className="subscribe">
              <h4>{item.customFields.subscribeLabel}</h4>
              <p>{item.customFields.emailLabel}</p>
              <Subscribe label={inputLabel} btnTxt={submitButtonLabel} />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
