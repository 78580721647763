import React, { useRef } from "react"
import "./SliderAndTitle.scss"
import Glide from "react-glidejs"
import "react-glidejs/dist/index.css"
import ButtonCTA from "../components/ButtonCTA"

const SliderAndTitle = ({ item }) => {
  const { title, cards, description, titleClassColumns, cTA } = item.customFields
  const gliderRef = useRef(null)

  const SlideCard = ({ card, index }) => {
    const { title, description, image } = card.customFields

    const backgroundStyles = `
    @media (min-width: 768px) {
      .card-slider-slide:hover {
        background-image: url(${image?.url});
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  `

    return (
      <div className={`card-slider-slide`}>
        <style>{backgroundStyles}</style>
        <span className="card-index">{index}.</span>
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
      </div>
    )
  }

  return (
    <section className={`slider slider-title item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className={`col-md-6-- ${titleClassColumns}`}>
            <h2 className="slider__title">{title}</h2>
          </div>
        </div>
        {description && <div className="row">
          <div className="col col-md-9">
            <div className="slider__description mb-5"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>}

        {cTA && <ButtonCTA data={{ styles: "slider__pink_cta mb-5", text: cTA.text, scrollToForm: true }} />}
        <Glide
          ref={gliderRef}
          throttle={0}
          gap={20}
          type="carousel"
          startAt={0}
          perView={3}
          className="glide"
          breakpoints={{
            1024: {
              perView: 2,
              peek: {
                before: 0,
                after: 0,
              },
            },
            600: {
              perView: 1,
              peek: {
                before: 0,
                after: 30,
              },
            },
          }}
        >
          {cards.map((card, index) => {
            return <SlideCard card={card} key={index} index={index + 1} />
          })}
        </Glide>
      </div>
    </section>
  )
}

export default SliderAndTitle
