import React from 'react'
import "./FeaturedHero.scss"
import ButtonCTA from '../components/ButtonCTA'
import { Helmet } from "react-helmet"
import { renderHTML } from "../agility/utils"

const FeaturedHero = ({ item }) => {
  const { lineTag, title, description, callToAction, backgroundImage, partnerLogos, mobileImage } = item.customFields

  const backgroundStyles = `
  .fetured-hero.item-${item.contentID} {
    background-image: url(${mobileImage?.url});
    background-size: cover;
}
  @media (min-width: 768px) {
    .fetured-hero.item-${item.contentID} {
      background-image: url(${backgroundImage?.url});
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
    `

  return (
    <section className={`fetured-hero item-${item.contentID}`}>
      {partnerLogos && (
        <Helmet>
          <link rel="preload" as="image" href={partnerLogos.url} />
        </Helmet>
      )}
      <style>{backgroundStyles}</style>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 d-flex flex-column align-items-center p-0">
            <span className='text-uppercase line-tag'>{lineTag}</span>
            <div
              className="fetured-hero- title"
              dangerouslySetInnerHTML={renderHTML(title)}
            />
          </div>
        </div>
        <div className="row d-flex flex-column align-items-center">
          <div className="col-12 col-md-8 d-flex flex-column align-items-center">
            <p className='description'>{description}</p>
            <ButtonCTA data={{ text: callToAction.text, styles: "cta mt-5", href: callToAction.href, scrollToForm: true }} />
          </div>
        </div>
        <div className="row row-logos">
          {partnerLogos && <div className="col d-flex align-items-center flex-column">
            <span className="partner-logos-title">Our Partner Logos</span>
            <img className="partners-logos" src={partnerLogos.url} alt={partnerLogos.label}/>
          </div>}
        </div>
      </div>
    </section>
  )
}

export default FeaturedHero