import React, { useEffect, useRef, useState, useCallback } from "react"
import "./PostsListing.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import SVG from "./SVG"
import moment from "moment"
import { getReadTime } from "./../agility/helpers"
import { renderHTML } from "./../agility/utils"

/**
 * Gets the parameters of the URL.
 * @function getParameters
 */

const getParameters = () => {
  if (typeof window === `undefined`) return []
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get("category") ? urlParams.get("category").split(",") : []
}

/**
 * Lists al the posts with the categoreis and the posts.
 * @function PostsListing
 */

const PostsListing = ({ allPosts, categories }) => {
  const [filterCategories, setFilterCategories] = useState(getParameters())
  const [posts, setPosts] = useState(allPosts)
  const [view, setView] = useState("grid")
  const section = useRef()

  /**
   * Callback to filter the posts using the categories.
   * @function updatePosts
   */

  const updatePosts = useCallback(() => {
    if (filterCategories.length > 0) {
      setPosts(
        allPosts.filter((post) => {
          const postCategories = post.categories.map(
            (category) => category.customFields.slug
          )
          return postCategories.some((category) =>
            filterCategories.includes(category)
          )
        })
      )
    } else {
      setPosts(allPosts)
    }
  }, [filterCategories, allPosts])

  /**
   * Callback to update the parameters of the URL.
   * @function updateParameters
   */

  const updateParameters = useCallback(() => {
    if (typeof window === `undefined`) return
    const urlParams = new URLSearchParams(window.location.search)
    if (filterCategories.length > 0) {
      urlParams.set("category", filterCategories.join(","))
    } else {
      urlParams.delete("category")
    }
    const urlString = urlParams.toString().replace(/%2C/g, ",")
    const urlToNavigate = urlString
      ? `${window.location.origin}${window.location.pathname}?${urlString}`
      : `${window.location.origin}${window.location.pathname}`

    if (urlToNavigate !== window.location.href) {
      window.history.pushState(null, null, urlToNavigate)
    }
    updatePosts()
  }, [filterCategories, updatePosts])

  useEffect(() => {
    updateParameters()
  }, [filterCategories, updateParameters])

  /**
   * Sets the filter using the categories.
   * @function Filter
   */
  const Filter = ({ category }) => {
    const handleClick = () => {
      const index = filterCategories.indexOf(category.slug)
      if (index > -1) {
        const filters = [...filterCategories]
        filters.splice(index, 1)
        setFilterCategories([...filters])
      } else {
        setFilterCategories([...filterCategories, category.slug])
      }
      section.current.scrollIntoView({
        behavior: "smooth",
      })
    }

    /**
     * Renders the provieded filter button UI.
     */

    return (
      <li>
        <button
          className={`posts-filters-btn ${
            filterCategories.includes(category.slug) ? "active" : ""
          }`}
          onClick={handleClick}
        >
          {category.name}
        </button>
      </li>
    )
  }

  /**
   * Sets the Filter dropdown on the UI
   *@function FilterDropdown
   */

  const FilterDropdown = () => {
    const clearFilters = () => {
      setFilterCategories([])
      section.current.scrollIntoView({
        behavior: "smooth",
      })
    }
    const [active, setActive] = useState(false)
    const toggleActive = () => {
      setActive(active ? false : true)
    }

    /**
     * Renders the UI of all the filters on the dropdown.
     */

    return (
      <div className="filter-dropdown">
        <button className="label" onClick={toggleActive}>
          Categories
        </button>
        <ul className={`menu ${active ? "active" : ""}`}>
          <li>
            <button
              className={`posts-filters-btn ${
                filterCategories.length === 0 ? "active" : ""
              }`}
              onClick={clearFilters}
            >
              All
            </button>
          </li>
          {categories.map((category, index) => (
            <Filter category={category} key={index} />
          ))}
        </ul>
      </div>
    )
  }

  /**
   * Renders the Filters UI using the dropdown and the buttons.
   * @function Filters
   */
  const Filters = () => {
    return (
      <nav className="posts-filters sticky">
        <div className="posts-filters-views">
          <button
            className={`posts-view-btn ${view === "grid" ? "active" : ""}`}
            onClick={() => {
              setView("grid")
            }}
          >
            <SVG name="view-grid"></SVG>
          </button>
          <button
            className={`posts-view-btn ${view === "list" ? "active" : ""}`}
            onClick={() => {
              setView("list")
            }}
          >
            <SVG name="view-list"></SVG>
          </button>
        </div>
        <div className="posts-filters-categories">
          <FilterDropdown />
        </div>
      </nav>
    )
  }

  /**
   * Renders a the card of as post with the provided post.
   * @function PostCard
   */
  const PostCard = ({ post }) => {

    const categories = post.categories.map(
      (category) => category.customFields.name
    )
    const readTime = post.customFields.content
      ? getReadTime(post.customFields.content)
      : null
    const postLink = post.sitemapNode ? post.sitemapNode.pagePath : "#"

    return (
      <article
        className={`posts-card ${
          postLink.includes("/podcasts/") ? "podcast" : ""
        }`}
      >
        <div className="read-more">
          <Link className="btn btn-secondary-black" to={postLink}>
            Read this
          </Link>
        </div>
        <Link to={postLink} className="featured-image background-cover">
          <Img
            fluid={post.customFields.imageLocalImg?.childImageSharp?.fluid}
            alt={post.customFields.title}
          />
          <span className="categories xs d-lg-none">
            {" "}
            {categories.join(", ")}{" "}
          </span>
        </Link>
        <div className="post-metadata">
          <span className="categories d-none d-lg-flex">
            {" "}
            {categories.join(", ")}{" "}
          </span>
          <h2 className="title">
            <Link
              to={postLink}
              dangerouslySetInnerHTML={{ __html: post.customFields.title }}
            ></Link>
          </h2>
          <div className="excerpt" dangerouslySetInnerHTML={renderHTML(post.customFields.excerpt)}/>
          <p className="date-read">
            <span className="date">
              {moment(post.customFields.publishDate)
                .utcOffset("-06:00")
                .format("MMM D YYYY")}
            </span>
            {!post.customFields.podcast && (
              <span className="readtime">
                &nbsp;•&nbsp;{readTime} min{readTime > 1 && <span>s</span>} Read
              </span>
            )}
          </p>
        </div>
      </article>
    )
  }

  /**
   * Full render of the PostListing UI.
   */

  return (
    <section className="posts" ref={section}>
      <Filters />
      <div className="container">
        <div className={`posts-wrapper ${view}`}>
          {posts.map((post, index) => (
            <PostCard post={post} key={index} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default PostsListing
