import React from "react"
import ContentZone from "../agility/components/ContentZone"
import DarkLandingHeader from "../components/DarkLandingHeader"
import SpanishLandingFooter from "../components/SpanishLandingFooter"

const DarkSpanishLandingPageTemplate = props => {
  return (
    <div className="pixel506">
      <DarkLandingHeader />
      <main>
        <ContentZone name="MainContentZone" {...props} />
      </main>
      <SpanishLandingFooter />
    </div>
  )
}

export default DarkSpanishLandingPageTemplate
