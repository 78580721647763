import React, { Fragment } from 'react';
import "./RowAndServicesCard.scss"
import useFetchData from '../hooks/useFetchData';

const RowAndServicesCard = ({ item }) => {
  const { title, description, servicesList } = item.customFields
  const { data } = useFetchData(`referencename=${servicesList.referencename}`);


  return (
    <section className={`row-services-card item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2 className='title'>{title}</h2>
            <p className='description mt-3'>{description}</p>
          </div>
        </div>
        <div className="row mx-lg-n5">
          {data && data.items.map(item => {
            const { title, description, icon } = item.fields
            return (
              <Fragment key={title}>
                <div className='col-12 col-md-3'>
                  <div className="card-wrapper">
                    <img className='card-wrapper-icon' src={icon.url} alt={icon.label} />
                    <h5 className='card-wrapper-title'>{title}</h5>
                    <p className='card-wrapper-description'>{description}</p>
                  </div>
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default RowAndServicesCard