import React, { useRef } from "react"
import "./CardSlider.scss"
import { Link } from "gatsby"
import Glide from "react-glidejs"
import "react-glidejs/dist/index.css"

const CardSlider = ({ item }) => {
  const gliderRef = useRef(null)
  const sliderCards = item.customFields.cards

  const SlideCard = ({ card }) => {
    const styles = {
      backgroundImage: `url(${encodeURI(
        card.customFields.backgroundImage.url
      )})`,
    }
    const categories = card.customFields.categories.map(category => {
      return category.customFields.name
    })
    const buttonText = card.customFields.cTALink.title
      ? card.customFields.cTALink.title
      : "Visit"

    const alt = card.customFields.backgroundImage.label
      ? card.customFields.backgroundImage.label
      : card.customFields.title

    return (
      <div className={`card-slider-slide`}>
        <figure
          className="card-slider-slide-background"
          style={styles}
          title={alt}
          aria-hidden="true"
        ></figure>
        <div className="card-slider-slide-content">
          <span className="categories">{categories.join(", ")}</span>
          <h3 className="title">{card.customFields.title}</h3>
          {card.customFields.cTALink.href && (
            <Link
              className="btn btn-secondary-pink"
              to={card.customFields.cTALink.href}
            >
              {buttonText}
            </Link>
          )}
        </div>
      </div>
    )
  }

  return (
    <section className="card-slider">
      <div className="container pt-2 pt-md-0">
        <h2 className="card-slider-title">
          <span>{item.customFields.title}</span>
        </h2>
        <Glide
          ref={gliderRef}
          throttle={0}
          type="carousel"
          startAt={1}
          perView={3}
          leftArrowComponent={null}
          rightArrowComponent={null}
          hideArrows
          breakpoints={{
            1024: {
              perView: 2,
              peek: {
                before: 0,
                after: 0,
              },
            },
            600: {
              perView: 1,
              peek: {
                before: 0,
                after: 30,
              },
            },
          }}
        >
          {sliderCards.map((card, index) => {
            return <SlideCard card={card} key={index}></SlideCard>
          })}
        </Glide>
      </div>
    </section>
  )
}

export default CardSlider
