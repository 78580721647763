import React, { useEffect, useState } from "react"
import "./HeroSlider.scss"
import Glide, {
  Controls,
  Keyboard,
  Breakpoints,
  Swipe,
  Autoplay,
} from "@glidejs/glide/dist/glide.modular.esm"
import { Link } from "gatsby"
import useHasMounted from "./../hooks/useHasMounted"
import { stripHTML } from "./../agility/helpers"
import { Helmet } from "react-helmet"

const HeroSlider = ({ item }) => {
  const hasMounted = useHasMounted()
  const [glideMounted, setGlideMounted] = useState(false)
  const customFields = item.customFields
  const cards = customFields.cards
  let glideElement = null

  if (hasMounted) {
    glideElement = document.querySelector(".hero-slider-glide")
  }

  useEffect(() => {
    const glide = new Glide(glideElement, {
      type: "carousel",
      perView: 1,
      gap: 0,
      autoplay: 7000,
    })
    if (hasMounted) {
      glide.on("mount.after", () => {
        setGlideMounted(true)
      })

      glide.mount({ Controls, Keyboard, Breakpoints, Swipe, Autoplay })
    }
    return () => {
      glide.destroy()
    }
  })

  const HeroSlide = ({ props }) => {
    const alt = props.heroCardImage.label
      ? props.heroCardImage.label
      : stripHTML(props.heroCardHeadline)

    const Title = () => {
      if (props.isH1 === "true") {
        return (
          <h1
            className="slide-title"
            dangerouslySetInnerHTML={{ __html: props.heroCardHeadline }}
          ></h1>
        )
      }
      if (props.isH1 === "false" || props.isH1 === undefined) {
        return (
          <h2
            className="slide-title"
            dangerouslySetInnerHTML={{ __html: props.heroCardHeadline }}
          ></h2>
        )
      }
    }

    const CTA = () => {
      const buttonStyleClass = props.heroCardCTAStyle.customFields.cSSClass
        ? props.heroCardCTAStyle.customFields.cSSClass
        : "btn-primary-pink"
      return (
        <Link
          className={`btn black ${buttonStyleClass}`}
          to={props.heroCardCtaLink.href}
        >
          {props.heroCardCtaLink.text}
        </Link>
      )
    }

    return (
      <li className="hero-slider__slide" style={{ width: "100%" }}>
        <div
          className="slide-wrapper"
          title={alt}
          style={{
            backgroundImage: `url(${encodeURI(props.heroCardImage.url)}), url(${
              props.imagePlaceholder
                ? encodeURI(props.imagePlaceholder.url)
                : ""
            })`,
          }}
        >
          <Title />
          <p className="slide-content d-none d-sm-flex mb-5">
            {props.heroCardSubtitle}
          </p>
          <div className="d-none d-sm-flex">
            {props.heroCardCtaLink && props.heroCardCtaLink.href && <CTA />}
          </div>
          <div
            className="slide-borders"
            style={{
              borderColor:
                props.heroCardMainColor.customFields.hexadecimalValue,
            }}
          ></div>
        </div>
        <div className="d-flex d-sm-none flex-column slide-xs-wrapper">
          <p className="slide-content mb-5 mt-3">{props.heroCardSubtitle}</p>
          <div className="text-center">
            {props.heroCardCtaLink && props.heroCardCtaLink.href && <CTA />}
          </div>
        </div>
      </li>
    )
  }

  return (
    <section className="hero-slider">
      <Helmet>
        {cards.map((card, key) => (
          <link
            rel="preload"
            as="image"
            key={key}
            href={card.customFields.heroCardImage.url}
          ></link>
        ))}
      </Helmet>
      <div className={`hero-slider-glide glide`}>
        <div className="glide__track" data-glide-el="track">
          <ul
            className={`${
              glideMounted ? "" : "flex-column unmounted-slides"
            } glide__slides mb-0`}
          >
            {cards.map(card => (
              <HeroSlide key={card.contentID} props={card.customFields} />
            ))}
          </ul>
        </div>

        <div
          className="glide__bullets bullets text-center mt-sm-3"
          data-glide-el="controls[nav]"
        >
          {cards.map((card, key) => (
            <button
              className={`glide__bullet 
              ${glideMounted && key === 0 ? "" : "glide__bullet--active"}
              ${card.customFields.heroCardMainColor.customFields.name
                .split(" ")
                .join("-")
                .toLowerCase()}`}
              key={key}
              aria-label={`Bullet for ${card.customFields.heroCardTitle}`}
              data-glide-dir={`=${key}`}
            >
              <span className="bullet"></span>
              <p className="mb-0 ml-3 d-none d-sm-flex bullet-title">
                {card.customFields.heroCardTitle}
              </p>
            </button>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HeroSlider
