import React from "react"
import { debounce } from "throttle-debounce"
import useHasMounted from "./../hooks/useHasMounted"

/**
 * Component to handle a responsive background on the webpage.
 * @function ResponsiveBackground
 */

const ResponsiveBackground = ({
  desktop = "",
  mobile = "",
  alt = "",
  className = "responsive-background",
  hasParallax = false,
  renderAsSection = false,
  children,
}) => {
  const hasMounted = useHasMounted()
  const cssClass = hasParallax ? `${className} parallax` : className
  const [windowWidth, setWindowWidth] = React.useState(
    typeof window !== `undefined` ? window.innerWidth : 0
  )
  const [backgroundPosition, setbackgroundPosition] = React.useState(50)
  const backgroundElement = React.useRef(null)

  const handleResize = debounce(500, true, () => {
    setWindowWidth(typeof window !== `undefined` ? window.innerWidth : 0)
  })

  /**
   * Handles the scroll of the page to set the new background position.
   * @function handleScroll
   */

  const handleScroll = () => {
    const elementRect = backgroundElement.current.getBoundingClientRect()
    const end = elementRect.height * 2
    const height = elementRect.height
    const top = -elementRect.top + height < 0 ? 0 : -elementRect.top + height
    const progress = top > end ? end : top

    setbackgroundPosition((progress * 100) / end)
  }

  /**
   * useEffect hook to add and remove the scroll handler.
   */

  React.useEffect(() => {
    if (hasMounted) {
      window.addEventListener("resize", handleResize)
      hasParallax && window.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (hasMounted) {
        window.removeEventListener("resize", handleResize)
        hasParallax && window.removeEventListener("scroll", handleScroll)
      }
    }
  })

  const backgroundSize = hasParallax ? "auto 125vh" : "cover"
  const backgroundAttachment = hasParallax ? "fixed" : "initial"

  let imageStyle =
    windowWidth > 768
      ? {
          backgroundImage: `url('${desktop}')`,
          backgroundPosition: `center ${backgroundPosition}%`,
          backgroundSize: backgroundSize,
          backgroundAttachment: backgroundAttachment,
          backgroundRepeat: "no-repeat",
        }
      : {
          backgroundImage: `url('${mobile}')`,
          backgroundPosition: `center`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }

  /**
   * Renders the component depending on the renderAsSection property.
   */

  if (renderAsSection) {
    return (
      <div className={cssClass} style={imageStyle} ref={backgroundElement}>
        {children}
      </div>
    )
  } else {
    return (
      <figure
        className={cssClass}
        role="img"
        aria-label={alt}
        style={imageStyle}
        ref={backgroundElement}
      >
        {children}
      </figure>
    )
  }
}

export default ResponsiveBackground
