import React, { Fragment, useEffect, useRef, useState } from "react"
import "./StepsLine.scss"
import { renderHTML } from "../agility/utils"

const StepsLine = ({ item }) => {
  const [show, setShow] = useState(false)
  const [showLines, setShowLines] = useState(false)
  const lineRef = useRef(null)
  const animatedRefLines = useRef(null)
  const { content, contentItems, svg, withoutAnimation, grayBG } = item.customFields

  useEffect(() => {
    const lineCurrent = lineRef.current;
    const animatedRefLinesCurrent = animatedRefLines.current;

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setShow(true);
        setShowLines(true);
        observer.unobserve(lineCurrent);
        observer.unobserve(animatedRefLinesCurrent);
      }
    });

    observer.observe(lineCurrent);
    observer.observe(animatedRefLinesCurrent);

    return () => {
      observer.unobserve(lineCurrent);
      observer.unobserve(animatedRefLinesCurrent);
    };
  }, []);

  const backgroundStyles = `
    @media (min-width: 768px) {
      .arrow-line-layer {
        background-image: url(${svg?.url});
        background-size: contain;
        background-repeat: no-repeat;
      }
    } `

  const ContentCard = ({ card, index }) => {
    const { title, description, icon } = card.customFields

    const order = index === 1 ? "order-lg-last" : ""
    return (
      <div className={`col-md-4 col-12 pt-md-5 pb-md-5 d-flex align-items-space-around justify-content-around ${order}`}>
        <div className="card">
          <div className="card-circle d-md-none"></div>
          <img className="card-icon" src={icon.url} alt={icon.label} />
          <h4 className="card-title">
            <span className="index d-none d-md-inline">0{index + 1} </span>
            {title}
          </h4>
          {/* <div className="line"></div> */}
          <p className="card-description">{description}</p>
        </div>
      </div>
    )
  }

  return (
    <section className={`steps-line item-${item.contentID} ${grayBG ? "gray" : ""}`}>
      <style>{backgroundStyles}</style>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <div
              className="content-title"
              dangerouslySetInnerHTML={renderHTML(content)}
            />
          </div>
        </div>
        <div className="row row-cards">
          <div
            className={`vertical-line d-md-none ${show ? "show" : ""}`}
            ref={lineRef}
          ></div>
          <div
            ref={animatedRefLines}
            className={`arrow-line-layer d-none d-lg-block ${withoutAnimation ? "scale" : ""}  ${showLines ? "showLines" : ""
              }`}
          ></div>
          {contentItems.map((card, index) => {
            return (
              <Fragment key={card.contentID}>
                <ContentCard card={card} index={index} />
              </Fragment>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default StepsLine
