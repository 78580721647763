import React from "react"
import { graphql, StaticQuery } from "gatsby"
import MostPopular from "./MostPopular"

/**
 * Anonymous element to query the podcasts posts and render them using the MostPopular component.
 */

export default () => (
  <StaticQuery
    query={graphql`
      {
        allAgilityInsight {
          nodes {
            sitemapNode {
              pageView
              pagePath
            }
            customFields {
              title
            }
          }
        }
      }
    `}
    render={(queryData) => {
      const allPageViews = queryData.allAgilityInsight.nodes || null
      const  mostPopular = allPageViews
      .filter((a) => a.sitemapNode !==  null)
      .sort((a, b) => a.sitemapNode.pageView - b.sitemapNode.pageView)
      .reverse()
      .slice(0, 3)

      return <MostPopular popular={mostPopular} type="Articles" />
    }}
  />
)
