import React from "react"
import "./FullHeightSectionWithText.scss"

const FullHeightSectionWithText = ({ item }) => {
  const customFields = item.customFields
  const textColor =
    customFields.backgroundColor === "black" ? "#FFF" : "#1C1C1C"
  const styles = `
    .full-height-text.fht-${item.contentID} h2 {
      color: ${textColor}
    }
  `
  return (
    <section
      className={`full-height-text fht-${item.contentID} ${customFields.backgroundColor}`}
    >
      <style>{styles}</style>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className={`text-${customFields.textAlignment}`}>
              {customFields.text}
            </h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FullHeightSectionWithText
