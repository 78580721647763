import React from "react"
import { renderHTML } from "../agility/utils"
import "./TextAndTitle.scss"

const TextAndTitle = ({ item }) => {
  const { customFields } = item

  return (
    <section className={`text-and-title text-and-title-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5">
            <span className="headline-line" />
            <h2 className="title">{customFields.title}</h2>
          </div>
          <div className="col-md-10 col-12 ml-md-auto mr-md-auto mb-5 text-col">
            <div
              className="text"
              dangerouslySetInnerHTML={renderHTML(customFields.text)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextAndTitle
