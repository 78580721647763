import React, { Fragment } from "react"
import "./TwoColumnsAndRows.scss"
import { renderHTML } from "../agility/utils"

const TwoColumnsAndRows = ({ item }) => {
  const { headlineTag, title, description, items } = item.customFields

  const ContentItem = ({ content }) => {
    const {
      serviceTitle,
      serviceDescription,
    } = content.customFields
    return (
      <div className="col-md-5 col-12 pb-5">
        <h4 className="service-title">{serviceTitle}</h4>
        <div
          className="service-description"
          dangerouslySetInnerHTML={renderHTML(serviceDescription)}
        />
      </div>
    )
  }

  return (
    <section className={`two-columns-rows item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7">
            <span className="headline-tag ml-1">{headlineTag}</span>
            <h2 className="title">{title}</h2>
            <p className="description">{description}</p>
          </div>
        </div>
        <div className="row justify-content-between">
          {items &&
            items.map(content => {
              return (
                <Fragment key={content.contentID}>
                  <ContentItem content={content} />
                </Fragment>
              )
            })}
        </div>
      </div>
    </section>
  )
}

export default TwoColumnsAndRows
