import React from "react"
import LazyImage from "../components/LazyImage"
import "./CardsAndImage.scss"

const CardsAndImage = ({ item }) => {
  const { headline, results } = item.customFields

  const Row = ({ result }) => {
    const { number, text, backgroundColorCard, image, cardAlignment } = result.customFields

    return (
      <div className="row mb-md-5" key={result.contentID}>
        <div className={`col-md-5 ${cardAlignment === 'right'? 'order-md-1' : ""}`}>
          <div
            className="card"
            style={{
              background: `${backgroundColorCard.customFields.hexadecimalValue}`,
            }}
          >
            <h2 className="card-title mb-0">{number}</h2>
            <h2 className="card-text mb-0">{text}</h2>
          </div>
        </div>
        <div className="col-md-7">
          <div className="image-wrapper">
            <LazyImage src={image.url} alt={image.label} extraClasses="image"/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className={`cards-img ci-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col">
            <span className="headline-line" />
            <h2 className="headline-text">{headline}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {results.map(result => {
              return <Row result={result} key={result.contentID} />
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardsAndImage
