import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PostsListing from './../components/PostsListing'

export default (props) => (
  <StaticQuery
    query={graphql`
      query AllPodcasts {
        allAgilityPodcast(sort: {order: DESC, fields: customFields___publishDate}) {
          nodes {
            categories {
              customFields {
                name
                slug
              }
            }
            customFields {
              title
              excerpt
              podcast
              publishDate
              content
              imageLocalImg {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 480) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            sitemapNode {
              pagePath
            }
          }
          totalCount
        }
        allAgilitySlugDefinition(filter: {properties: {referenceName: {eq: "categories"}}}) {
          nodes {
            customFields {
              name
              slug
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <PostsListing
          allPosts={queryData.allAgilityPodcast.nodes}
          totalInsights={queryData.allAgilityPodcast.totalCount}
          categories={queryData.allAgilitySlugDefinition.nodes.map(category => category.customFields)}
          {...props} />
      );
    }}
  />
)
