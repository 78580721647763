import React, { Fragment } from "react"
import "./TwoColumnsAndContent.scss"

const TwoColumnsAndContent = ({ item }) => {
  const {
    title,
    tagName,
    description,
    content,
    image,
    mobileImage,
  } = item.customFields

  const ContentCard = ({ content }) => {
    const { serviceTitle, serviceDescription } = content.customFields

    return (
      <div className="col-12 col-md-6 pr-md-0">
        <div className="card-wrapper">
          <h5 className="card-title mb-md-3">{serviceTitle}</h5>
          <p className="card-description">{serviceDescription}</p>
        </div>
      </div>
    )
  }

  return (
    <section className={`two-columns-content item-${item.contentID}`}>
      <img
        className="image-desktop-v2 d-none d-md-block"
        src={image.url}
        alt={image.label}
      />
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-md-8 col-12">
            <span className="tag-name md-mb-5">{tagName}</span>
            <h2 className="title ">{title}</h2>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-12 col-md-8">
            <p className="description mt-5">{description}</p>
            <div className="row flex-wrap">
              {content &&
                content.map(content => {
                  return (
                    <Fragment key={content.contentID}>
                      <ContentCard content={content} />
                    </Fragment>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-end p-0 d-md-none mobile-row">
        <img
          className="image-mobile"
          src={mobileImage.url}
          alt={mobileImage.label}
        />
      </div>
    </section>
  )
}

export default TwoColumnsAndContent
