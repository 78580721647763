import React, { Fragment } from "react"
import "./MostPopularInsightCard.scss"
import { Link } from "gatsby"
import LazyImage from "./LazyImage"

const MostPopularInsightCard = ({ popular }) => {
  const ArticleCard = ({ article }) => {
    const { image, title, excerpt, publishDate } = article.customFields
    const { author, categories } = article

    return (
      <>
        <div className="wrapper">
          <div className="pink-suare"></div>
          <div className="line"></div>
          <div className="row">
            <div className="col-md-5 col-12 d-flex justify-content-center">
              <LazyImage src={image.url} extraClasses="image" />
            </div>
            <div className="col-md-7 col-12 col-content">
              <span className="category">
                #{categories[0].customFields.name}
              </span>
              <h4 className="title">{title}</h4>
              <div
                className="excerpt"
                dangerouslySetInnerHTML={{
                  __html: excerpt.slice(0, 200).concat("…"),
                }}
              />
              <div className="author d-flex mt-md-5 mt-4">
                <img className="author-photo" src={author.customFields.photo.url} alt="author-photo" />
                <div className="author-data d-flex flex-column pl-3">
                    <span className="author-name">{author.customFields.name}</span>
                    <span className="insight-date">{publishDate}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="most-popular-insight-card">
      <div className="container">
        {popular.map((post, index) => {
          return (
            <Fragment key={index}>
              <ArticleCard article={post} />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default MostPopularInsightCard
