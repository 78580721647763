import React from "react"
import Form from "../components/Form"
import LazyImage from "../components/LazyImage"
import "./ImageAndTitle.scss"

const ImageAndTitle = ({ item }) => {
  const { customFields } = item

  const CTA = () => {
    const handleScrollToForm = () => {
      if (typeof window === `undefined`) return
      if (document.querySelector(".form-section")) {
        window.scrollTo({
          top: document.querySelector(".form-section").offsetTop,
          behavior: "smooth",
        })
      }
    }
    if (customFields.cTA && !customFields.cTA.href) {
      return (
        <button className="cta-btn btn-solid-pink" onClick={handleScrollToForm}>
          {customFields.cTA.text}
        </button>
      )
    }
    return (
      <button className={`cta-btn btn-solid-pink`}>
        {customFields.cTA.text}
      </button>
    )
  }

  const ColumnWithForm = () => {
    const thankyoupage = customFields.thankYouPage

    return (
      <>
        {/* <div className="col-12 col-md-6 col-sm-5 order-1 order-md-0 form-wrapper form-section"> */}
        <div className="col-12 col-md-6 col-sm-5 order-1 order-md-0 form-section">
          <div className="form-wrapper">
            <span className="headline-line" />
            <h2 className="mb-4">{customFields.formHeadline}</h2>
            <Form form={customFields.form} thankYouPage={thankyoupage} />
          </div>
        </div>
        <div className="col-12 col-md-6 order-0 order-md-1 d-flex align-items-center">
          <h2
            className="title mb-md-5 ml-md-5"
            dangerouslySetInnerHTML={{ __html: customFields._title }}
          />
        </div>
      </>
    )
  }

  return (
    <section className={`image-and-title image-and-title-${item.contentID}`}>
      <div className="container squares-wrapper">
        <div className="row">
          <div className="col d-flex justify-content-end">
            <div className="squares" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {customFields.hasForm ? (
            <ColumnWithForm />
          ) : (
            <>
              <div className="col-md-4 col-12">
                <LazyImage
                  src={customFields.image.url}
                  alt={customFields.image.label}
                />
              </div>
              <div className="col-md-7 col-12 flex-column d-flex text-cta">
                <div
                  className={`wrapper ml-5 d-flex flex-column ${customFields.cTAAlignment}`}
                >
                  <h2
                    className="title"
                    dangerouslySetInnerHTML={{ __html: customFields._title }}
                  />
                  <CTA />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default ImageAndTitle
