import React from "react"
import "./FullBackgroundImageAndCenteredText.scss"
import ButtonCTA from "../components/ButtonCTA"

const FullBackgroundImageAndCenteredText = ({ item }) => {
  const { customFields } = item

  const bgImage = customFields.mobileBackground
  ? customFields.mobileBackground.url
  : customFields.imageBackground.url

  const inlineStyles = `
  .full-background-image-centered-text.fb-${item.contentID} {
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (min-width: 768px) {
    .full-background-image-centered-text.fb-${item.contentID} {
      background-image: url(${customFields.imageBackground?.url});
      background-size: cover;
    }
  }
  `
  const showTextinmobile = customFields.showTextinmobile

  //   const styles = `.bg-content.bg-content-${item.contentID} .bg-img {
  //     background-image: url(${customFields?.imageBackground?.url}), url(${placeholder});

  //     @media (min-width: 768px) {
  //         .bg-content.bg-content-${item.contentID} .bg-img {
  //           background-image: url(${customFields.imageBackground.url}), url(${placeholder});
  //         }
  //       }
  //   }`

  const CTA = () => {
    const buttonStyleClass = customFields.buttonStyle.customFields.cSSClass
      ? customFields.buttonStyle.customFields.cSSClass
      : "btn-primary-white"

    if (customFields.cTAScrollToForm && !customFields?.cTA?.href) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${
            customFields.backgroundColor === "black" ? "" : "black"
          } ${buttonStyleClass}`}
          onClick={handleScrollToForm}
        >
          {customFields.cTA.text}
        </button>
      )
    }
    if (!customFields.cTAScrollToForm && customFields?.cTA?.href) {
      return (
        <ButtonCTA
          data={{
            text: customFields.cTA.text + " →",
            styles: "btn-solid-white btn-arrow",
            href: customFields?.cTA?.href,
          }}
        />
      )
    }

    return null
  }

  return (
    <section
      className={`full-background-image-centered-text fb-${item.contentID}`}
    >
      <style>{inlineStyles}</style>
      <div className="container">
        {customFields.alignContentBottom === "true" ? (
          <div className="row">
            <div className="col-md-8 col-12 bottom-content">
              <h2 className="title">{customFields.title}</h2>
              <p className="text d-none d-md-block text-align-left">
                {customFields.text}
              </p>
            </div>
            <div className="col-md-4 col-12 d-flex flex-column align-items-end justify-content-end">
              <ButtonCTA
                data={{
                  text: customFields.cTA.text + " →",
                  styles: `${customFields.buttonStyle.customFields.cSSClass} btn-arrow wider`,
                  href: customFields?.cTA?.href,
                }}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className={`col-12 d-flex align-items-center flex-column text-center wrapper ${
                customFields.biggerTitle === "true" ? "less-padding" : ""
              }`}
            >
              <h2
                className={`title ${
                  customFields.biggerTitle ? "title-bigger" : ""
                } mb-3`}
              >
                {customFields.title}
              </h2>
              <p className={`text ${showTextinmobile ? "text-align-left":"d-none"} d-md-block`}>{customFields.text}</p>
              <CTA />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default FullBackgroundImageAndCenteredText
