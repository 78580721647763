import { Link } from "gatsby"
import React, { Fragment } from "react"
import LazyImage from "./LazyImage"
import "./MostPopularArticleCard.scss"

const MostPopularArticleCard = ({ popular }) => {
  const ArticleCard = ({ article }) => {
    const { image, title, excerpt } = article.customFields

    return (
      <div className="col-md-3 col-12">
        <div className="wrapper d-flex flex-column ">
          <LazyImage src={image.url} extraClasses="image" />
          <h4 className="title">{title}</h4>
          <div
            className="excerpt"
            dangerouslySetInnerHTML={{
              __html: excerpt.slice(0, 90).concat("…"),
            }}
          />
          <Link to={article.sitemapNode.pagePath}>Read more &#8594;</Link>
        </div>
      </div>
    )
  }

  return (
    <div className="most-popular-article-card">
      <div className="row">
        {popular.map((post, index) => {
          return (
            <Fragment key={index}>
              <ArticleCard article={post} />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default MostPopularArticleCard
