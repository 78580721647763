import React, { Fragment, useRef } from "react"
import "./FullBackgroundAndHorizontalScroll.scss"
import ButtonCTA from "../components/ButtonCTA"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const FullBackgroundAndHorizontalScroll = ({ item }) => {
  const { itemsContent } = item.customFields
  const slider = useRef(null)

  const ItemSlide = ({ content }) => {
    const {
      title,
      cTA,
      description,
      backgroundImage,
      mobileBackground,
    } = content.customFields

    const backgroundStyles = `
  .item-slide.item-${content.contentID} {
    background-image: url(${mobileBackground?.url}) !important;
    background-size: cover;
  }

    @media only screen and (min-width: 768px) {
      .item-slide.item-${content.contentID} {
        background-image: url(${backgroundImage?.url}) !important;
        background-size: cover;
      }
    }
  `

    return (
      <div className={`item-slide item-${content.contentID}`}>
        <style>{backgroundStyles}</style>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <h2 className="item-slide-title">{title}</h2>
              <p className="item-slide-description mt-md-4">{description}</p>
            </div>
            <div className="col-md-6 col-12 d-flex flex-column align-items-md-end justify-content-center">
              <ButtonCTA
                data={{
                  text: `${cTA.text} →`,
                  href: `${cTA.href}`,
                  styles: "btn-transparent btn-no-border-rounded",
                  scrollToForm: true
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    centerMode: true,
    className: "center",
    centerPadding: "0px",
    slidesToScroll: 1,
    currentSlide: 0,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  }

  return (
    <section
      className={`full-background-horizontal-scroll item-${item.contentID}`}
    >
      <Slider ref={slider} {...settings}>
        {itemsContent && itemsContent.map((item, index) => {
          return (
            <Fragment key={index}>
              <ItemSlide content={item} />
            </Fragment>
          )
        })}
      </Slider>
    </section>
  )
}

export default FullBackgroundAndHorizontalScroll
