import React from "react"
import "./RelatedCaseStudies.scss"
import { Link } from "gatsby"

const RelatedCaseStudies = ({ item }) => {
  const customFields = item.customFields
  const mainColor = customFields.mainColor
    ? customFields.mainColor.customFields.hexadecimalValue
    : "#e12a5c"

  const CaseStudy = ({ props }) => {
    const caseStudyColor =
      props.customFields.mainColor.customFields.hexadecimalValue

    const CTA = () => {
      const buttonStyleClass = props.customFields.buttonStyle.customFields
        .cSSClass
        ? props.customFields.buttonStyle.customFields.cSSClass
        : "btn-secondary-pink"
      return (
        <Link
          className={`btn ${buttonStyleClass}`}
          to={props.customFields.ctaLink.href}
        >
          {props.customFields.ctaLink.text
            ? props.customFields.ctaLink.text
            : "Take me there"}
        </Link>
      )
    }

    return (
      <div className="case-study-wrapper pt-5 pt-sm-4 mb-4">
        <div className="d-flex align-items-center mb-3">
          <span
            className="cs-headline-decoration"
            style={{ backgroundColor: caseStudyColor }}
          ></span>
          <p className="headline mb-0">{props.customFields.headline}</p>
        </div>
        <div className="d-flex flex-column flex-sm-row align-items-center align-items-sm-end justify-content-between mb-4">
          <div className="d-flex flex-column">
            <h3 className="case-study-title">{props.customFields.title}</h3>
            <p className="sub-headline mb-sm-0">
              {props.customFields.subheadline}
            </p>
          </div>
          {props.customFields.ctaLink && props.customFields.ctaLink.href && (
            <CTA />
          )}
        </div>
      </div>
    )
  }

  return (
    <section className="related-case-studies">
      <div className="container">
        <div className="row">
          <div className="col-12 title-wrapper">
            <div
              className="headline-decoration"
              style={{ borderColor: mainColor }}
            ></div>
            <h2 className="title pb-1">{customFields.title}</h2>
          </div>
          <div className="col-md-4"></div>
          <div className="col-12 col-md-8">
            <div className="case-studies">
              {customFields.relatedCaseStudies.map((caseStudy, index) => {
                return <CaseStudy key={index} props={caseStudy} />
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RelatedCaseStudies
