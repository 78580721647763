import React from "react"
import "./LogoImageBanner.scss"

const LogoImageBanner = ({ item }) => {
  const customFields = item.customFields
  const backgrounds = `
    @media (min-width: 320px) {
      .logo-img-banner.lib-${item.contentID} {
        background-image: url(${
          customFields.mobileBackground
            ? customFields.mobileBackground.url
            : customFields.background.url
        }); 
      }
    }
    @media (min-width: 768px) {
      .logo-img-banner.lib-${item.contentID} {
        background-image: url(${customFields.background.url}); 
      }
    }
  `

  return (
    <section
      className={`logo-img-banner lib-${item.contentID} background-cover`}
      title={customFields.background.label}
    >
      <style>{backgrounds}</style>
      <div className="container">
        <div className="row">
          {customFields.logo && (
            <div className="col-12 col-sm-6">
              <img
                className="logo"
                src={customFields.logo.url}
                alt={customFields.logo.alt ? customFields.logo.alt : "Logo"}
              />
            </div>
          )}
          {customFields.image && (
            <div className="col-12 col-sm-6">
              <img
                className="image"
                src={customFields.image.url}
                alt={
                  customFields.logo.alt ? customFields.logo.alt : "banner-image"
                }
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default LogoImageBanner
