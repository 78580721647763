import React, { useRef, Fragment, useState } from "react"
import "./HorizontalSlider.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const HorizontalSlider = ({ item }) => {
  const [leftActiveArrow, setLeftActiveArrow] = useState("active")
  const [rightActiveArrow, setRightActiveArrow] = useState("active")
  const [slideIndex, setSlideIndex] = useState(1)

  const {
    title,
    description,
    itemsContent,
    whiteBackground,
    whiteSlider
  } = item.customFields
  const slider = useRef(null)

  const handleClickLeftArrow = () => {
    if (slider) {
      slider.current.slickPrev()
    }
    // setLeftActiveArrow("")
    // setRightActiveArrow("active")
    if (slideIndex - 1 >= 1) {
      setSlideIndex(slideIndex - 1)
    }
  }

  const handleClickRightArrow = () => {
    if (slider) {
      slider.current.slickNext()
    }
    // setLeftActiveArrow("active")
    // setRightActiveArrow("")
    if (slideIndex < itemsContent.length) {
      setSlideIndex(slideIndex + 1)
    }
  }

  const NextArrow = ({ extraClassName }) => {
    return (
      <div className={`custom-arrow ${extraClassName}`}>
        <button
          className={`right-arrow-btn ${rightActiveArrow} ${whiteBackground === 'true' ? "black" : ""}`}
          onClick={() => handleClickRightArrow()}
        >
          &#8594;
        </button>
      </div>
    )
  }
  const PrevArrow = ({ extraClassName }) => {
    return (
      <div className={`custom-arrow ${extraClassName}`}>
        <button
          className={`left-arrow-btn mr-md-3 ${leftActiveArrow} ${whiteBackground === 'true' ? "black" : ""}`}
          onClick={() => handleClickLeftArrow()}
        >
          &#8592;
        </button>
      </div>
    )
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    centerMode: true,
    className: "center",
    centerPadding: "50px",
    slidesToScroll: 1,
    currentSlide: 0,
    adaptiveHeight: true,
    // prevArrow: <PrevArrow extraClassName="d-none d-md-flex" />,
    // nextArrow: <NextArrow extraClassName="d-none d-md-flex" />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  }

  const HorizontalCard = ({ content, white }) => {
    const { quote, author, logo, logoMobile } = content.customFields

    const classWh = white ? "white-card" : ""

    return (
      <div className={`card d-flex ${whiteBackground === 'true' ? "black" : "white"} ${classWh}`}>
        <div className="logo-wrapper order-md-first order-last d-flex">
          <img
            className="logo d-none d-md-block"
            src={logo.url}
            alt={logo.label}
          />
          <img
            className="logo d-md-none"
            src={logoMobile.url}
            alt={logoMobile.label}
          />
        </div>
        <div className="content-wrapper">
          <p className="card-quote mt-md-3">{quote}</p>
          <p className="card-author">{author}</p>
        </div>
      </div>
    )
  }

  return (
    <section
      className={`horizontal-slider item-${item.ContentID} ${whiteBackground === 'true' ? "white" : ""
        }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <h2 className={`title mb-5 ${whiteBackground === 'true' ? "black" : ""}`}>{title}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p className={`description ${whiteBackground === 'true' ? "black" : ""}`}>{description}</p>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-end pt-3 pb-3 p-md-0">
            <p className={`slide-index mb-0 mr-5 ${whiteBackground === 'true' ? "black" : ""}`}>{`${slideIndex}/${itemsContent.length}`}</p>
            <PrevArrow extraClassName="mb-md-3 mb-2 mr-4" />
            <NextArrow extraClassName="mb-md-3 mb-2" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-12 pt-md-5">
            <Slider ref={slider} {...settings}>
              {itemsContent.map(item => {
                return (
                  <Fragment key={item.contentID}>
                    <HorizontalCard content={item} white={whiteBackground ? true : false} />
                  </Fragment>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HorizontalSlider
