import React from "react"
import LazyImage from "../components/LazyImage"
import "./MultipleContentAndImage.scss"

const MultipleContentAndImage = ({ item }) => {
  const { customFields } = item

  const ContentAndImage = ({ _content, key }) => {
    const {
      imageAlignment,
      image,
      mainTitle,
      content,
      backgroundColorTitle,
    } = _content.customFields

    const styles = {
      backgroundColor: `${backgroundColorTitle}`,
    }
    // const sty = `h2 span {
    //   background: ${backgroundColorTitle}
    // }
    // `

    return (
      <div className="wrapper">
        <div className="row no-gutters" key={key}>
          <div
            className={`col-md-5 col-12  ${
              imageAlignment !== "left" ? "order-1" : ""
            } d-flex justify-content-center`}
          >
            <LazyImage src={image.url} alt={image.label} />
          </div>
          <div className="col-md-7 col-12 d-flex flex-column justify-content-center">
            <h2 className="content-title" style={styles}>
              {mainTitle}
            </h2>
            <p className="content">{content}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <section
        className={`multiple-content-and-image multiple-content-and-image-${item.contentID}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <span className="headline-line" />
              <h2 className="title">{customFields.title}</h2>
            </div>
          </div>
          {customFields.contents.map((_content, key) => {
            return <ContentAndImage _content={_content} key={key} />
          })}
        </div>
      </section>
      <div className="container squares-wrapper">
        <div className="row">
          <div className="col d-flex justify-content-end">
            <div className="squares"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MultipleContentAndImage
