import React from "react"
import ButtonCTA from "../components/ButtonCTA.js"
import { renderHTML } from "../agility/utils"
import "./HeroGrid.scss"

const HeroGrid = ({ item }) => {
  const { customFields } = item

  const GridItem = ({ gridItem, size }) => {
    const { customFields } = gridItem

    const buttonStyleClass = customFields.buttonStyle.customFields.cSSClass
      ? customFields.buttonStyle.customFields.cSSClass
      : "btn-primary-white"

    const data = {
      styles: buttonStyleClass,
      text: customFields.cTA.text,
      scrollToForm: customFields?.scrollToForm,
      href: customFields.cTA.href,
    }

    const styles = {
      backgroundImage: `url(${gridItem.customFields.background.url})`,
    }

    return (
      <div className="item">
        <figure
          style={styles}
          className={`item-background d-flex mb-0 ${size}`}
        >
          <div className="headline-wrapper d-flex flex-column align-items-start justify-content-end">
            {/* <h1 className="headline">{customFields.headline}</h1> */}
            <div
              className="headline"
              dangerouslySetInnerHTML={renderHTML(customFields.headline)}
            />
            <ButtonCTA data={data} />
          </div>
        </figure>
      </div>
    )
  }

  return (
    <section className={`hero-grid hero-grid-${item.contentID}`}>
      <div className="wrapper w-100">
        <div className="row no-gutters">
          <div className="col-md-8 col-12">
            <GridItem gridItem={customFields.gridItems[0]} size="wider" />
          </div>
          <div className="col-md-4 col-12">
            <div className="row no-gutters d-flex flex-column">
              <div className="col">
                <GridItem gridItem={customFields.gridItems[1]} size="small" />
              </div>
              <div className="col">
                <GridItem gridItem={customFields.gridItems[2]} size="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroGrid
