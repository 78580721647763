import React, { useState } from "react"
import { Link } from "gatsby"
import "./CustomSlider.scss"

const CustomSlider = ({ cards }) => {
  const [cardsContent, setCardsContent] = useState(cards)
  const [content, setContent] = useState(cards[0].customFields)

  const handleClick = index => {
    setContent(cardsContent[index].customFields)

    if (index === 1) {
      const right_arrow = document.getElementById("r-a")
      const left_arrow = document.getElementById("l-a")
      right_arrow.classList.add("disabled")
      left_arrow.classList.remove("disabled")
    }
    if (index === 0) {
      const right_arrow = document.getElementById("r-a")
      const left_arrow = document.getElementById("l-a")
      right_arrow.classList.remove("disabled")
      left_arrow.classList.add("disabled")
    }
  }
  const styleColor = {
    color: content.mainColor.customFields.hexadecimalValue,
  }

  return (
    <div className="wrapper-long d-flex align-items-center justify-content-center">
      <div
        className="left-arrow arrow ml-0 ml-md-2 disabled" id="l-a"
        onClick={() => handleClick(0)}
      />
      <div className="long col-md-12 mt-md-5 m-md-0 p-0" key={content.title}>
        <div className="row no-gutters">
          <div className="col-md-6">
            <div className="card-first-left-wrapper">
              <h2 className="card-first-left-wrapper-title" style={styleColor}>
                {content.title}
              </h2>
              <p className="card-first-left-wrapper-text">{content.text}</p>
              <Link
                className="call-to-action"
                style={styleColor}
                to={content?.cTA?.href}
              >
                {content?.cTA?.text}
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-first-right-wrapper">
              <img
                className="image-bg"
                src={content.backgroundImage?.url}
                alt={content.backgroundImage?.label}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="right-arrow arrow mr-md-2" id="r-a"
        onClick={() => handleClick(1)}
      />
    </div>
  )
}

export default CustomSlider
