import React from "react"
import { Helmet } from "react-helmet"
import { renderHTML } from "../agility/utils"
import "./HeroLanding.scss"

const HeroLanding = ({ item }) => {
  const { customFields } = item

  const backgroundImage = `${encodeURI(customFields?.image?.url)}`

  const backgroundStyles = `.bg-image {
    background-image: url(${backgroundImage});
    min-height: 450px;
  }`

  const CTA = () => {
    if (
      customFields.scrollToFormOnClick &&
      customFields.cTA &&
      !customFields.cTA.href
    ) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }
      return (
        <button className={`btn btn-solid-pink mt-3 d-none d-md-flex`} onClick={handleScrollToForm}>
          {customFields.cTA.text}
        </button>
      )
    }
    return null
  }

  return (
    <section className={`hero-item-${item.contentID} hero-landing`}>
      <Helmet>
        <link rel="preload" as="image" href={backgroundImage} />
      </Helmet>
      <style>{backgroundStyles}</style>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-12">
            <div
              className="title"
              dangerouslySetInnerHTML={renderHTML(customFields.headline)}
            />
            <p className="subheadline">{customFields.subheadline}</p>
            <CTA />
          </div>
          <div className="col-sm-6 col-12">
            <figure className="bg-image" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroLanding
