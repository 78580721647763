import React from "react"
import "./VideoSection.scss"
import SVG from "../components/SVG"
import { Link } from "gatsby"

const VideoSection = ({ item }) => {
  const customFields = item.customFields

  const CTA = () => {
    const buttonStyleClass = item.customFields.videoSectionCtaStyle.customFields
      .cSSClass
      ? item.customFields.videoSectionCtaStyle.customFields.cSSClass
      : "btn-primary-pink"
    return (
      <Link
        className={`btn black ${buttonStyleClass}`}
        to={item.customFields.videoSectionCtaLink.href}
      >
        {item.customFields.videoSectionCtaLink.text}
      </Link>
    )
  }

  return (
    <section className="video-section">
      <div className="stripes-wrapper">
        <SVG
          name={"diagonal-stripes"}
          width={370}
          height={229}
          fill={`#E12A5C;`}
        ></SVG>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-9 mb-5 mb-sm-0">
            <h2
              className="video-section__title d-sm-none mb-5"
              dangerouslySetInnerHTML={{
                __html: customFields.videoSectionTitle,
              }}
            ></h2>
            <div
              className="video-section__wrapper background-cover d-block d-sm-none"
              style={{
                backgroundImage: `url(${customFields.placeholder.url})`,
              }}
            ></div>
            <div
              className="video-section__wrapper background-cover d-none d-sm-block"
              style={{
                backgroundImage: `url(${customFields.placeholder.url})`,
              }}
            >
              <h2
                className="video-section__title"
                dangerouslySetInnerHTML={{
                  __html: customFields.videoSectionTitle,
                }}
              ></h2>
            </div>
          </div>
          <div className="col-12 col-md-11 col-xl-12">
            <div className="box-cta-wrapper d-flex flex-column flex-sm-row justify-content-between align-items-center align-items-sm-end">
              <div className="black-box mb-5 mb-sm-0">
                <div className="black-box-decoration"></div>
                <p className="black-box-content mb-0">{customFields.textBox}</p>
              </div>
              {item.customFields.videoSectionCtaLink &&
                item.customFields.videoSectionCtaLink.href && <CTA />}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoSection
