import React from "react"
import "./NearshoreTable.scss"

const NearShoreTable = ({ item }) => {
  const customFields = item.customFields
  const columns = customFields.tablecolumns || []

  const Rows = ({ props }) => {
    const columns = props.tablecolumns
    const nearshoreColumnStyleClass = "row-text nearshore-column"

    return (
      <>
        <tr>
          <th className="row-name" scope="row">
            Location
          </th>
          {columns.map((col, index) => (
            <td
              className={
                col.customFields.name === "Nearshore"
                  ? nearshoreColumnStyleClass
                  : "row-text"
              }
              key={index}
            >
              {col.customFields.location}
            </td>
          ))}
        </tr >
        <tr>
          <th className="row-name" scope="row">
            Time zone difference
          </th>
          {columns.map((col, index) => (
            <td
              className={
                col.customFields.name === "Nearshore"
                  ? nearshoreColumnStyleClass
                  : "row-text"
              }
              key={index}
            >
              {col.customFields.timezonedifference}
            </td>
          ))}
        </tr>
        <tr>
          <th className="row-name" scope="row">
            Cultural alignment
          </th>
          {columns.map((col, index) => (
            <td
              className={
                col.customFields.name === "Nearshore"
                  ? nearshoreColumnStyleClass
                  : "row-text"
              }
              key={index}
            >
              {col.customFields.culturalalignment}
            </td>
          ))}
        </tr>
        <tr>
          <th className="row-name table-row" scope="row">
            English communication
          </th>
          {columns.map((col, index) => (
            <td
              className={
                col.customFields.name === "Nearshore"
                  ? nearshoreColumnStyleClass
                  : "row-text"
              }
              key={index}
            >
              {col.customFields.englishcommunication}
            </td>
          ))}
        </tr>
        <tr>
          <th className="row-name" scope="row">
            Costs
          </th>
          {columns.map((col, index) => (
            <td
              className={
                col.customFields.name === "Nearshore"
                  ? nearshoreColumnStyleClass
                  : "row-text"
              }
              key={index}
            >
              {col.customFields.costs}
            </td>
          ))}
        </tr>
        <tr>
          <th className="row-name" scope="row">
            Example
          </th>
          {columns.map((col, index) => (
            <td
              className={
                col.customFields.name === "Nearshore"
                  ? nearshoreColumnStyleClass
                  : "row-text"
              }
              key={index}
            >
              {col.customFields.example}
            </td>
          ))}
        </tr>
      </>
    )
  }

  return (
    <section className="nearshore-table">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column justify-content-between">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" aria-label="empty"></th>
                  {columns.map(column => (
                    <th
                      className={
                        column.customFields.name === "Nearshore"
                          ? "nearshore-table-column-name nearshore-column"
                          : "nearshore-table-column-name"
                      }
                      key={column.contentID}
                      scope="col"
                      data-after={
                        column.customFields.name === "Nearshore" ? "white-after" : ""
                      }
                    >
                      {column.customFields.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <Rows props={customFields} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}
export default NearShoreTable
