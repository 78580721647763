import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import LazyImage from "../components/LazyImage"
import { renderHTML } from "./../agility/utils"
import { trimText } from "../agility/helpers"
import SVG from "../components/SVG"
import "./AuthorDetails.scss"

export default ({ item }) => (
  <StaticQuery
    query={graphql`
      query AllPosts {
        allAgilityInsight {
          nodes {
            author {
              customFields {
                name
              }
            }
            customFields {
              title
              excerpt
              content
              image {
                url
              }
            }
            sitemapNode {
              pagePath
            }
            categories {
              customFields {
                name
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      const featuredAuthor = item.customFields.featuredAuthor.customFields
      const allInsights = queryData.allAgilityInsight.nodes
      const featuredAuthorInsights = allInsights.filter(
        insight => insight.author.customFields.name === featuredAuthor.name
      )

      return (
        <AuthorDetails {...featuredAuthor} posts={featuredAuthorInsights} />
      )
    }}
  />
)

function InsightCard({ insight }) {
  const categoriesString = insight.categories
    .map(category => category.customFields.name)
    .toString()
    .split(",")
    .join(", ")

  return (
    <article className="more-post-card">
      <div className="img-wrapper">
        <LazyImage
          placeholder={"/regular-placeholder.jpg"}
          src={insight.customFields.image.url}
          alt={insight.customFields.title}
        />
        <p className="categories">{categoriesString}</p>
      </div>

      <div
        dangerouslySetInnerHTML={renderHTML(insight.customFields.title)}
      ></div>
      <div
        dangerouslySetInnerHTML={{
          __html: insight.customFields.excerpt.slice(0, 200).concat("…"),
        }}
      />
      <div>
        <Link
          className="btn btn-primary-pink black"
          to={insight.sitemapNode?.pagePath}
        >
          Read the full article
        </Link>
      </div>
    </article>
  )
}

function AuthorDetails(props) {
  const { name, photo, bio, posts, linkedInLink } = props
  return (
    <section className="author-details-module">
      <div className="author-metadata">
        <div className="container">
          <span className="meet">Meet</span>
          <h1 className="title">{name}</h1>
          <div className="row">
            <div className="col-8 col-sm-8">
              <p className="bio">{bio}</p>
              <a
                className="d-inline-block mt-2"
                href={linkedInLink.href}
                aria-label={`Visit ${name} LinkedIn Profile`}
              >
                <SVG
                  fill={"#FFF"}
                  name={"linked-in"}
                  width={40}
                  height={40}
                ></SVG>
              </a>
            </div>
            <div className="col-4 col-sm-4">
              <div className="thumbnail-wrapper mt-3 mb-3 m-sm-0">
                <LazyImage
                  placeholder={"/regular-placeholder.jpg"}
                  src={photo.url}
                  alt={name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-3 mb-3 mt-sm-4 mt-sm-b">
          <div className="col-12 mb-4 mt-4">
            <h2 className="more">More from {name}</h2>
          </div>
          {posts.map(post => (
            <div className="col-12 col-sm-4 mb-5" key={post.customFields.title}>
              <InsightCard insight={post} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
