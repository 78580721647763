import { Link } from "gatsby"
import React, { Fragment } from "react"
import LazyImage from "./LazyImage"
import "./AnimatedMostPopularArticleCard.scss"

const AnimatedMostPopularArticleCard = ({ popular }) => {
  const ArticleCard = ({ article }) => {
    const { image, title, excerpt } = article.customFields


    return (
      <div className="col-md-4 col-12">
        <div className="wrapper d-flex flex-column justify-content-between">
          <div className="image-wrapper">
            <LazyImage src={image.url} extraClasses="image mb-0" />
            <div className="overlay"></div>
          </div>
          <h4 className="title mt-3 mb-3">{title}</h4>
          <div
            className="excerpt "
            dangerouslySetInnerHTML={{
              __html: excerpt.slice(0, 150).concat("…"),
            }}
          />
          <Link className="link" to={article.sitemapNode.pagePath}>Read more &#8594;</Link>
          <div className="row">
            <div className="col-12">

            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="animated-most-popular-article-card">
      <div className="row">
        {popular.map((post, index) => {
          return (
            <Fragment key={index}>
              <ArticleCard article={post} />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default AnimatedMostPopularArticleCard
