import React from "react"
import MostPopularArticles from "../components/MostPopularArticles"
import "./PopularArticles.scss"

const PopularArticles = ({ item }) => {
  const {
    addMostPopularArticles,
    text,
    title,
    headlineDecoration,
    animated,
  } = item.customFields

  if (addMostPopularArticles === "true") {
    return (
      <section className={`popular-articles pa-${item.contentID}`}>
        <div className="container">
          <div className="row">
            <div className="col d-flex flex-column align-items-center">
              {headlineDecoration === "true" && (
                <span className="headline-decoration" />
              )}
              <h2 className={`title ${animated === "true" ? "bigger" : ""}`}>
                {title}
              </h2>
              <p className="text">{text}</p>
            </div>
          </div>
          <MostPopularArticles animated={animated ? true : false} />
        </div>
      </section>
    )
  }
  return null
}

export default PopularArticles
