import React, { useState, useEffect } from "react"
import "./LargeBackground.scss"

const LargeBackground = ({ item }) => {
  const { customFields } = item
  const { pixelians } = customFields
  const [nextCard, setNextCard] = useState(0)
  const [currentPixelian, setCurrentPixelian] = useState(pixelians[nextCard])

  const imageStyle = `
  .centered-image {
    background-image: url(${customFields.centeredImage.url});
    background-size: cover;
    // min-height: 966px;
    min-height: 450px;
  }`

  useEffect(() => {
    const interval = setInterval(() => {
      setNextCard(nextCard => nextCard + 1)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (nextCard <= pixelians.length - 1) {
      setCurrentPixelian(pixelians[nextCard])
    } else {
      setNextCard(0)
    }
  }, [nextCard])

  const CTA = () => {
    const handleScrollToForm = () => {
      if (typeof window === `undefined`) return
      if (document.querySelector(".job-listing")) {
        window.scrollTo({
          top: document.querySelector(".job-listing").offsetTop,
          behavior: "smooth",
        })
      }
    }

    return (
      <button
        onClick={handleScrollToForm}
        className={`cta-btn btn`}
      >
        {customFields.cTA.text}
      </button>
    )
  }

  const Card = ({ pixelian, index }) => {
    const { name, jobName, text, photo } = pixelian.customFields

    const smallerFont = index > 0 && index < 3 ? "smaller-font" : "bigger-line-height"
    return (
      <div className="card">
        <div className="row">
          <div className="col-12">
            <p className={`text ${smallerFont}`}>{text}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <img src={photo.url} alt={photo.label} />
          </div>
          <div className="col-9">
            <span className="name">{name}</span> <br />
            <span className="job-description">{jobName}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className={`large-background large-background-${item.contentID}`}>
      <style>{imageStyle}</style>
      <div className="grey-bg">
        <div className="container">
          <span className="headline-line" />
          <h2 className="top-title">{customFields.topTitle}</h2>
          <div className="row">
            <div className="col-6 col-12 col-md-9">
              <p className="top-text">{customFields.topText}</p>
              <CTA />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <figure className="centered-image" />
        </div>
      </div>
      <div className="container container-bottom">
        <div className="row justify-content-center">
          <h2 className="bottom-title">{customFields.bottomTitle}</h2>
          <p className="bottom-text text-center">{customFields.bottomText}</p>
          <div className="col-md-6 col-12 d-flex align-items-center">
            <p className="bottom-left-text">{customFields.bottomLeftText}</p>
          </div>
          <div className="col-md-6 col-12">
            <Card pixelian={currentPixelian} index={nextCard} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default LargeBackground
