import React, { Fragment } from 'react'
import "./Stats.scss"
import { renderHTML } from "../agility/utils"
import useFetchData from '../hooks/useFetchData';

const Stats = ({ item }) => {
  const { title, numbers } = item.customFields
  const { data } = useFetchData(`referencename=${numbers.referencename}`);

  return (
    <section className={`stats item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10">
            <h3 className='title'>{title}</h3>
          </div>
        </div>
        <div className="row no-gutters">
          {data && data.items.map((stat, index) => {
            const { number } = stat.fields
            const ml = index === 3 ? 'ml-auto' : ""
            const mr = index === 4 ? 'mr-auto' : ""

            return (
              <Fragment key={index}>
                <div className={`col-12 col-md-4 ${ml} ${mr} d-flex justify-content-center`}>
                  <div
                    className="stat"
                    dangerouslySetInnerHTML={renderHTML(number)}
                  />
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Stats

//home_Stats