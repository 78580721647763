import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import agilityUtils from "./agility/utils"
//Some things we need for our layout
import PreviewBar from "./components/PreviewBar"
import "svgxuse/svgxuse"
import AgilityPageTemplate from "./agility/components/AgilityPageTemplate"

const SEO = loadable(() => import("./components/SEO"))
const LayoutTemplate = loadable(() => import("./components/LayoutTemplate"))
const PageProgress = loadable(() => import("./components/PageProgress"))

//Our query to get the our page data and check for a dynamic page item (agilityItem)
export const query = graphql`
  query($pageID: Int!, $contentID: Int!, $languageCode: String!) {
    agilitypage(languageCode: { eq: $languageCode }, itemID: { eq: $pageID }) {
      pageJson
    }
    agilityitem(
      languageCode: { eq: $languageCode }
      itemID: { eq: $contentID }
    ) {
      itemJson
    }
  }
`
const AgilityPage = ({ pageContext, data, location }) => {
  const viewModel = agilityUtils.buildPageViewModel({ pageContext, data })

  const handleSeoImg = () => {
    if (viewModel.dynamicPageItem) {
      if (viewModel.dynamicPageItem.customFields.image.url) {
        return viewModel.dynamicPageItem.customFields.image.url
      }
    }
    return "https://800c8044-cdn.agilitycms.cloud/Attachments/NewItems/op-pixel506-think-create-grow.jpg"
  }

  const SchemaParsing = viewModel => {
    const { page, dynamicPageItem } = viewModel
    let schema_parsed = ""
    let schema_text = page.seo.metaHTML // ? page.seo?.metaHTML : dynamicPageItem?.seo.metaHTML
      ? JSON.stringify(page.seo?.metaHTML)
      : JSON.stringify(dynamicPageItem?.seo?.metaHTML) //uses dynamic page seo if not page

    if (schema_text) {
      schema_parsed = schema_text?.replace(/\\/g, "")
      schema_parsed = schema_parsed?.split("")
      schema_parsed.shift()
      schema_parsed.pop()
      schema_parsed = schema_parsed.join("")
    } else {
      schema_parsed = null
    }
    return schema_parsed
  }

  if (viewModel.page) {
    let schema_parsed = SchemaParsing(viewModel)

    return (
      <LayoutTemplate>
        <SEO
          title={viewModel.page.title}
          description={viewModel.page.seo.metaDescription}
          keywords={viewModel.page.seo.metaKeywords}
          language={viewModel.languageCode}
          route={location}
          img={handleSeoImg()}
          schema={schema_parsed}
          metaHTMLContent={viewModel.page.seo.metaHTML}
          headScript={viewModel.page.scripts.top}
        />
        <PreviewBar isPreview={viewModel.isPreview} />
        <PageProgress />
        <AgilityPageTemplate {...viewModel} route={location} />
      </LayoutTemplate>
    )
  }
  return <LayoutTemplate />
}

export default AgilityPage
