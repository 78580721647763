import React from "react"
import ContentZone from "../agility/components/ContentZone"
import GlobalHeader from "../components/GlobalHeader"
import GlobalFooter from "../components/GlobalFooter"
import ReactFullpage from "@fullpage/react-fullpage"
import "./FullPageTemplate.scss"

class DarkFullPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: <ContentZone name="FullPageZone" {...this.props} />,
      fullpage: {
        direction: null,
        isLast: false,
        isFirst: true,
      },
    }
  }

  onLeave(origin, destination, direction) {
    this.setState({
      fullpage: {
        direction: direction,
      },
    })
    if (destination.isLast) {
      this.setState({
        fullpage: {
          isLast: true,
          direction: direction,
        },
      })
    }
    if (destination.isFirst) {
      this.setState({
        fullpage: {
          isFirst: true,
          direction: direction,
        },
      })
    }
  }

  render() {
    const { sections } = this.state
    const { fullpage } = this.state

    return (
      <div className="pixel506">
        <GlobalHeader
          languageCode={this.props.languageCode}
          isMultiLanguage={this.props.isMultiLanguage}
          variation="dark"
          route={this.props.route}
          fullpage={fullpage}
        ></GlobalHeader>
        <main className="px-fp">
          <ReactFullpage
            licenseKey={"5D0BCB72-01F1443F-BD502207-9FE21819"}
            scrollingSpeed={700}
            fitToSection={false}
            scrollOverflow={true}
            scrollOverflowOptions={{ scrollbars: false }}
            onLeave={this.onLeave.bind(this)}
            render={({ state, fullpageApi }) => {
              if ((typeof window === `undefined`) === false) {
                window.fullpageApi = fullpageApi
              }

              return (
                <ReactFullpage.Wrapper>
                  {sections}

                  <section className="section fp-auto-height">
                    <GlobalFooter
                      languageCode={this.props.languageCode}
                      isMultiLanguage={this.props.isMultiLanguage}
                      route={this.props.route}
                    ></GlobalFooter>
                  </section>
                </ReactFullpage.Wrapper>
              )
            }}
          />
        </main>
      </div>
    )
  }
}

export default DarkFullPageTemplate
