import React from 'react'
import './PixelValue.scss'
import ResponsiveBackground from './../components/ResponsiveBackground'

const PixelValue = ({item}) => {
  return (
    <section className="pixel-value">
      <ResponsiveBackground
        desktop={item.customFields.desktop.url}
        mobile={item.customFields.mobile.url}
        alt={item.customFields.valueName}
        hasParallax={true}
        className="pixel-value-picture"
      />
      <div className="container pixel-value-wrapper">
        <h3>{item.customFields.valueName}</h3>
        <p>{item.customFields.valueDescription}</p>
      </div>
    </section>
  )
}

export default PixelValue