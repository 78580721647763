import React, { Fragment, useEffect, useState } from "react"
import { Link } from "gatsby"
import "./JobListing.scss"

const JobListing = ({ item }) => {
  const { customFields } = item
  const [selectedCategory, setSelectedCategory] = useState({})
  const [activeButton, setActiveButton] = useState(0)
  const [jobs, setJobs] = useState(customFields.categoriesJob)
  const [categoryFilterSelected, setCategoryFilterSelected] = useState("")
  const [showFiltered, setShowFiltered] = useState(true)


  const menuItems = [
    ...new Set(
      customFields.categoriesJob.map(
        category => category.customFields.categoryName
      )
    ),
  ]

  /**
   *
   * @param {data}
   * @returns job search
   */
  const Search = ({ data }) => {
    const [searchShow, setSearchShow] = useState(false)
    const [searchField, setSearchField] = useState(" ")

    const allJobs = data.map(job => {
      const { jobs } = job.customFields
      return jobs
    })
    var jobList = []
    for (let index = 0; index < allJobs.length; index++) {
      jobList = jobList.concat(allJobs[index])
    }

    const filteredData = jobList.filter(item => {
      return item.customFields.name
        .toLowerCase()
        .includes(searchField.toLocaleLowerCase())
    })

    const handleChange = e => {
      setSearchField(e.target.value)
      if (e.target.value === "") {
        setSearchShow(false)
        setShowFiltered(true)
      } else {
        setSearchShow(true)
        setShowFiltered(false)
      }
    }
 

    const searchList = () => {
      if (searchShow) {
        return <JobCardsSearched items={filteredData} />
      }
    }

    return (
      <>
        <div className="search-job d-md-none d-flex align-items-center justify-content-start">
          <input
            className="search ml-2"
            type="search"
            placeholder="Search job"
            onChange={handleChange}
          />
        </div>
        <CategoryFilter
          filterItem={filterItem}
          setJobs={setJobs}
          menuItems={menuItems}
        />
        {searchList()}
      </>
    )
  }

  const JobCardsSearched = ({ items }) => {
    return (
      <>
        {items?.map(job => {
          return (
            <div className="row job-card d-md-none" key={job.contentID}>
              <div className="col-12">
                <p className="job-location">{job.customFields.location}</p>
              </div>
              <div className="col-12">
                <h4 className="job-name">{job.customFields.name}</h4>
              </div>
              <div className="col-12">
                <button className="category-tag btn">
                  {job.customFields.category}
                </button>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const filterItem = _category => {
    const newItem = customFields.categoriesJob.filter(newVal => {
      return newVal.customFields.categoryName === _category
    })
    setJobs(newItem)
    setCategoryFilterSelected(_category)
  }

  /**
   * Cards to render on mobile
   * @function JobCards
   */
  const JobCards = ({ items }) => {
    return (
      <>
        {items?.map(item => {
          const { categoryName } = item.customFields
          const { jobs } = item.customFields

          return (
            <Fragment key={item.contentID}>
              {jobs &&
                jobs.length &&
                jobs.map(job => {
                  const { customFields } = job
                  return (
                    <Fragment key={job.contentID}>
                    <div className="row job-card d-md-none">
                      <div className="col-12">
                        <p className="job-location">{customFields.location}</p>
                      </div>
                      <div className="col-12 mb-1">
                        {/* <h4 className="job-name">{customFields.name}</h4> */}
                        <Link className="job-name" to={job.customFields.jobname.href}>{job.customFields.name}</Link>
                      </div>
                      <div className="col-12">
                        <button className="category-tag btn">
                          {categoryName}
                        </button>
                      </div>
                    </div>
                    </Fragment>
                  )
                })}
            </Fragment>
          )
        })}
      </>
    )
  }

  /**
   *
   * @param { filterItem, setJobs, menuItems}
   * @returns filter to render
   */
  const CategoryFilter = ({ filterItem, setJobs, menuItems }) => {
    const [active, setActive] = useState(false)
    const toggleActive = () => {
      setActive(active ? false : true)
    }

    return (
      <div className="job-filter-categories d-md-none">
        <div className="filter-dropdown">
          <button className={`label`} onClick={() => toggleActive()}>
            Filters
          </button>
          <ul className={`category-list ${active ? "active" : ""} `}>
            {menuItems.map((item, id) => {
              return (
                <li className="category-item" key={id}>
                  <button
                    className={`category-filter-btn ${
                      // jobs[0].customFields.categoryName === item ? "active" : ""
                      categoryFilterSelected === item ? "active" : ""
                    }`}
                    onClick={() => filterItem(item)}
                  >
                    {item}
                  </button>
                </li>
              )
            })}
            <li>
              <button
                className={`category-filter-btn`}
                onClick={() => setJobs(customFields.categoriesJob)}
              >
                All
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  const CategoryButton = ({ category, indx }) => {
    const { categoryName } = category.customFields

    const handleClick = () => {
      setSelectedCategory(category)
      setActiveButton(indx)
    }

    return (
      <>
        <button
          className={`category-btn btn ${
            activeButton === indx ? "btn-selected" : "bg-white"
          }`}
          onClick={() => handleClick()}
        >
          {categoryName}
        </button>
      </>
    )
  }

  useEffect(() => {
    setSelectedCategory(customFields.categoriesJob[0])
  }, [])


  

  return (
    <section className={`job-listing-${item.contentID} job-listing`}>
      <div className="container">
        <h1 className="title">{customFields.title}</h1>
        <div className="row">
          <div className="col-9 col-12">
            <p className="description">{customFields.description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Search data={customFields.categoriesJob} />
            {showFiltered && <JobCards items={jobs} />}
            <div className="category-listing d-none d-md-block">
              <div className="row no-gutters justify-content-center">
                {customFields?.categoriesJob.map((category, index) => {
                  return (
                    <div className="col-3" key={category.contentID}>
                      <CategoryButton category={category} indx={index} />
                    </div>
                  )
                })}
              </div>
              <p className="category-description">
                {selectedCategory.customFields?.categoryDescription}
              </p>
              {selectedCategory?.customFields?.jobs?.map(job => {
                return (
                  <div className="row job-row" key={job.contentID}>
                    <div className="col-9">
                      <a href={job.customFields.jobname.href} className="job-name">{job.customFields.name}</a>
                    </div>
                    <div className="col-3">
                      <p className="job-location">
                        {job.customFields.location}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobListing
