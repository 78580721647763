import React, { useState } from "react"
import "./ContentAndImage.scss"
import { Link } from "gatsby"
import { renderHTML } from "./../agility/utils"
import LazyImage from "../components/LazyImage"
import { navigate } from "gatsby-link"

const ContentAndImage = ({ item }) => {
  const customFields = item.customFields

  const textColor =
    customFields.backgroundColor === "black" ? "#FFF" : "#1C1C1C"

  const bottomBorder =
    customFields?.bottomborderwidth

  const mainColor = customFields.mainColor
    ? customFields.mainColor.customFields.hexadecimalValue
    : "#e12a5c"

  const mainTitleColor = customFields.mainTitlecolor
    ? customFields.mainTitlecolor.customFields.hexadecimalValue
    : "#e12a5c"

  const inlineStyles = `
    .content-img.ci-${item.contentID} .main-title, 
    .content-img.ci-${item.contentID} .headline,
    .content-img.ci-${item.contentID} .text-block {
      color: ${textColor};
    }
    .content-img.ci-${item.contentID} .main-title p span  {
      color: ${mainTitleColor};
    }
    .content-img.ci-${item.contentID} .headline-line {
      background-color: ${mainColor};
    }
    .content-img.ci-${item.contentID} {
      border-bottom: ${bottomBorder} solid #F4F4F4;
    }
  `

  const [showPopUp, setshowPopUp] = useState(false)

  const MailPopUp = () => {
    const [email, setEmail] = useState("")

    const handleSubmit = e => {
      e.preventDefault()

      document.querySelector(".popup-form").classList.add("submitted")
      if (!email) return

      let form = document.getElementById("ebook-popup-form")
      let formData = new FormData(form)
      formData.set("user-email", email)

      setshowPopUp(false)

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => navigate("/thank-you-for-getting-the-ebook-nearshoring"))
        .catch(error => console.log(error))
    }

    const handleChange = e => {
      setEmail(e.target.value)
    }

    return (
      <div className="popup">
        <div className="popup_inner">
          <div className="row">
            <div className="col-12">
              <h1 className="title">Send my free ebook</h1>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-12">
              <form
                id="ebook-popup-form"
                name="ebook-form"
                method="post"
                className="popup-form"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="ebook-form" />
                <div className="px-form-group mb-4">
                  <input
                    id="user-email"
                    name="user-email"
                    type="email"
                    className="px-form-input"
                    onChange={handleChange}
                    value={email}
                    required
                  />
                  <label htmlFor="user-email">Email</label>
                </div>
                <button
                  id="ebook-cta"
                  className="btn solid-pink send-btn m-auto d-block"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Get your free PDF
                </button>
                <button
                  className="btn-cancelar m-auto d-block"
                  onClick={() => setshowPopUp(!showPopUp)}
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const CTA = () => {
    const buttonStyleClass = customFields.buttonStyle.customFields.cSSClass
      ? customFields.buttonStyle.customFields.cSSClass
      : "btn-primary-white"

    if (customFields.cTAScrollToForm && customFields.ctaLink.href) {
      return (
        <>
          {customFields.downloadFile === "true" ? (
            <button
              className={`btn ${buttonStyleClass}`}
              onClick={() => setshowPopUp(!showPopUp)}
            >
              {customFields.ctaLink.text}
            </button>
          ) : (
            <Link
              className={`btn ${buttonStyleClass}`}
              to={customFields.ctaLink.href}
            >
              {customFields.ctaLink.text}
            </Link>
          )}
        </>
      )
    }
    //only for jobs listing
    if (customFields.scrollToJobListing && !customFields.ctaLink.href) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".job-listing")) {
          window.scrollTo({
            top: document.querySelector(".job-listing").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${
            customFields.backgroundColor === "black" ? "" : "black"
          } ${buttonStyleClass}`}
          onClick={handleScrollToForm}
        >
          {customFields.ctaLink.text}
        </button>
      )
    }

    if (customFields.cTAScrollToForm && !customFields.ctaLink.href) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${
            customFields.backgroundColor === "black" ? "" : "black"
          } ${buttonStyleClass}`}
          onClick={handleScrollToForm}
        >
          {customFields.ctaLink.text}
        </button>
      )
    }

    return null
  }

  return (
    <section
      className={`content-img ci-${item.contentID} ${customFields.backgroundColor}`}
    >
      {showPopUp ? <MailPopUp closePopup={showPopUp} /> : null}
      <style>{inlineStyles}</style>
      <div className="container">
        <div className="row">
          <div
            className={`col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start ${
              customFields.imageAlignment !== "right"
                ? "order-2 mt-4 mt-sm-0"
                : "mb-4 mb-sm-0"
            }`}
          >
            <div className="d-flex justify-content-start align-items-center mb-2 mb-md-4 px-sm-2">
              {/* <span className="headline-line"></span> */}
              {!customFields.hideheadlineLine ? (
                <span className="headline-line" />
              ) : null}
              <p className="headline mb-0">{customFields.headline}</p>
            </div>
            <h2
              className="main-title mb-3 mb-sm-2 px-sm-2"
              dangerouslySetInnerHTML={{ __html: customFields.mainTitle }}
            ></h2>
            <div className="d-lg-none">
              {customFields.image && customFields.imagePlaceholder && (
                <LazyImage
                  src={customFields.image.url}
                  placeholder={customFields.imagePlaceholder.url}
                  alt={customFields.image ? customFields.image.label : ""}
                  extraClasses="mobile image mb-3"
                />
              )}

              {customFields.image && !customFields.imagePlaceholder && (
                <img
                  className="mobile image mb-3"
                  src={customFields.image ? customFields.image.url : ""}
                  alt={customFields.image ? customFields.image.label : ""}
                />
              )}
            </div>
            {customFields.textBlock && (
              <div
                className="text-block pb-1 mb-3 px-sm-2"
                dangerouslySetInnerHTML={renderHTML(customFields.textBlock)}
              />
            )}
            {/* <div
              className="text-block pb-1 mb-3 px-sm-2"
              dangerouslySetInnerHTML={renderHTML(customFields.textBlock)}
            ></div> */}
            {item.customFields.ctaLink && <CTA />}
          </div>
          <div
            className={`col-6 d-none d-lg-flex justify-content-center align-items-center ${
              customFields.imageAlignment !== "right" ? "order-1" : ""
            } ${customFields.imagePosition}-wrapper`}
          >
            {customFields.image && customFields.imagePlaceholder && (
              <LazyImage
                src={customFields.image.url}
                placeholder={customFields.imagePlaceholder.url}
                alt={customFields.image ? customFields.image.label : ""}
                extraClasses={`image ${customFields.imagePosition}`}
              />
            )}

            {customFields.image && !customFields.imagePlaceholder && (
              <img
                className={`image ${customFields.imagePosition}`}
                src={customFields.image ? customFields.image.url : ""}
                alt={customFields.image ? customFields.image.label : ""}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentAndImage
