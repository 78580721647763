import React, { Fragment } from "react"
import LazyImage from "../components/LazyImage"
import { renderHTML } from "../agility/utils"
import "./ServicesSection.scss"

const ServicesSection = ({ item }) => {
  const {
    title,
    description,
    devServicesTitle,
    devServices,
    productServicesTitle,
    productServices,
    servicesCTA,
    processTitle,
    processDescription,
    processList,
    workModelTitle,
    workModelDescription,
    teamModelList,
    whyTitle,
    whyDescription,
    whyNumbers,
    backgroundMobile,
    cTA,
    backgroundImage,
  } = item.customFields
  const firstRowNumbers = whyNumbers.slice(0, 4)
  const secondRowNumbers = whyNumbers.slice(-3)

  const backgroundStyles = `
  .ss-${item.contentID} {
    background-image: url(${backgroundImage.url});
    }

    @media only screen and (max-width: 767px) {
        .ss-${item.contentID} {
          background-image: url(${backgroundMobile.url});
        }
      }
  }`

  const ServicesCard = ({ card }) => {
    const { serviceTitle, serviceDescription } = card.customFields

    return (
      <div className="col-md-4">
        <h3 className="service-card-title pb-2">{serviceTitle}</h3>
        <p className="service-card-description">{serviceDescription}</p>
      </div>
    )
  }
  const ProcessCard = ({ card }) => {
    const { title, description } = card.customFields
    return (
      <div className="col-md-3">
        <div className="process-card">
          <h3 className="process-card-title pb-2">{title}</h3>
          <p className="process-card-description">{description}</p>
        </div>
      </div>
    )
  }
  const WorkModelCard = ({ card }) => {
    const {
      title,
      description,
      image,
      imageAlignment,
      cTA,
      backgroundMobile,
    } = card.customFields

    return (
      <div className="row work-model-card-row">
        <div
          className={`col-md-6 d-flex flex-column justify-content-center text-col${
            imageAlignment === "left"
              ? "order-last right-text"
              : "order-first left-text"
          }`}
        >
          <span className="headline-line" />
          <h3 className="work-model-card-title pb-2">{title}</h3>
          <p className="work-model-card-description">{description}</p>
          <div className="cta-wrapper d-none d-md-flex pt-4">
            {cTA && <CTA cta={cTA} />}
          </div>
        </div>
        <div
          className={`col-md-6 d-flex p-0 image-col ${
            imageAlignment === "left"
              ? "order-first"
              : "order-last justify-content-end"
          }`}
        >
          <LazyImage
            src={image.url}
            alt={image ? image.label : ""}
            extraClasses="mobile image mb-3 d-none d-md-flex"
          />
          <LazyImage
            src={backgroundMobile.url}
            alt={backgroundMobile ? backgroundMobile.label : ""}
            extraClasses="mobile image mb-3 d-md-none"
          />
        </div>
        <div className="col d-flex justify-content-center d-md-none pt-5">
          <div className="cta-wrapper pt-4">{cTA && <CTA cta={cTA} />}</div>
        </div>
      </div>
    )
  }

  const NumberCard = ({ card }) => {
    const { number } = card.customFields
    return (
      <div className="col d-flex justify-content-center">
        <div
          className="number-wrapper"
          dangerouslySetInnerHTML={renderHTML(number)}
        />
      </div>
    )
  }

  const CTA = ({ cta }) => {
    const handleScrollToForm = () => {
      if (typeof window === `undefined`) return
      if (document.querySelector(".form-section")) {
        window.scrollTo({
          top: document.querySelector(".form-section").offsetTop,
          behavior: "smooth",
        })
      }
    }
    if (cta.href === "") {
      return (
        <button className="btn btn-transparent" onClick={handleScrollToForm}>
          {cta.text}
        </button>
      )
    }
  }

  return (
    <section
      className={`service-section ss-${item.contentID} background-cover `}
    >
      <style>{backgroundStyles}</style>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <span className="headline-line" />
            <h2 className="title">{title}</h2>
            <p className="description md-mb-3">{description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h2 className="services-title">{devServicesTitle}</h2>
          </div>
          {devServices.map(service => {
            return (
              <Fragment key={service.contentID}>
                <ServicesCard card={service} serviceType="dev" />
              </Fragment>
            )
          })}
        </div>
        <div className="row">
          <div className="col-md-12 mt-md-5">
            <h2 className="services-title">{productServicesTitle}</h2>
          </div>
          {productServices.map(service => {
            return (
              <Fragment key={service.contentID}>
                <ServicesCard card={service} serviceType="dev" />
              </Fragment>
            )
          })}
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center row-btn">
            <CTA cta={servicesCTA} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="headline-line" />
            <h2 className="process-title">{processTitle}</h2>
            <p className="process-description md-mb-3">{processDescription}</p>
          </div>
        </div>
        <div className="row">
          {processList.map(cardProcess => {
            return (
              <Fragment key={cardProcess.contentID}>
                <ProcessCard card={cardProcess} />
              </Fragment>
            )
          })}
        </div>
        <div className="row">
          <div className="col-md-12 d-flex align-items-center flex-column row-work-model">
            <h2 className="work-model-title">{workModelTitle}</h2>
            <p className="work-model-description md-mb-3">
              {workModelDescription}
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        {teamModelList.map(teamModelCard => {
          return (
            <Fragment key={teamModelCard.contentID}>
              <WorkModelCard card={teamModelCard} />
            </Fragment>
          )
        })}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <span className="headline-line" />
            <h2 className="why-title">{whyTitle}</h2>
            <p className="why-description mb-md-5">{whyDescription}</p>
          </div>
        </div>
        <div className="row">
          {firstRowNumbers.map(number => {
            return (
              <Fragment key={number.contentID}>
                <NumberCard card={number} />
              </Fragment>
            )
          })}
        </div>
        <div className="row pt-md-5">
          {secondRowNumbers.map(number => {
            return (
              <Fragment key={number.contentID}>
                <NumberCard card={number} />
              </Fragment>
            )
          })}
        </div>
        <div className="row pb-5">
          <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
            <CTA cta={cTA} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesSection
