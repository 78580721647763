import React, { Fragment } from "react"
import "./LinkTree.scss"
import { graphql, StaticQuery, Link } from "gatsby"

export default props => (
  <StaticQuery
    query={graphql`
      query RoutesQuery {
        allAgilitySitemapNode(filter: { visible: { menu: { eq: true } } }) {
          nodes {
            pagePath
            menuText
          }
        }
        allAgilityInsight {
          nodes {
            categories {
              customFields {
                name
              }
            }
            sitemapNode {
              pagePath
              menuText
            }
          }
        }
        allAgilityPodcast {
          nodes {
            categories {
              customFields {
                name
              }
            }
            sitemapNode {
              pagePath
              menuText
            }
          }
        }
      }
    `}
    render={queryData => {
      const parentRoutes = queryData.allAgilitySitemapNode.nodes.filter(
        node =>
          node.pagePath.split("/").length === 2 ||
          node.pagePath.split("/").length === 3
      )
      const insightsNodes = queryData.allAgilityInsight.nodes
      const podcastsNodes = queryData.allAgilityPodcast.nodes
      const allPosts = [...insightsNodes, ...podcastsNodes]
      let categories = []

      const getPostsCategories = post => {
        const postCategories = []
        post.categories.forEach(category => {
          postCategories.push(category.customFields.name)
        })
        return postCategories.toString()
      }

      allPosts.forEach(post => {
        categories.push(getPostsCategories(post))
      })

      // Remove duplicate category combinations
      categories = categories.filter(
        (value, index, self) => self.indexOf(value) === index
      )

      const categorizedPosts = categories.map(category => {
        return {
          name: category,
          insights: [],
          podcasts: [],
        }
      })

      allPosts.forEach(post => {
        categorizedPosts.forEach(categoryGroup => {
          const postCategories = getPostsCategories(post)
          if (categoryGroup.name.includes(postCategories)) {
            // Make sure no duplicate post gets added to other categories combination
            if (categoryGroup.name === postCategories) {
              if (post.sitemapNode) {
                if (post.sitemapNode.pagePath.includes("/insights/")) {
                  categoryGroup.insights.push(post)
                }
                if (post.sitemapNode.pagePath.includes("/podcasts/")) {
                  categoryGroup.podcasts.push(post)
                }
              }
            }
          }
        })
      })

      const secondlevelServices = queryData.allAgilitySitemapNode.nodes.filter(
        sitemapNode => {
          const isServices = sitemapNode.pagePath.includes("/services")
          let isSecondLevel = sitemapNode.pagePath.split("/").length === 3
          if (props.isMultiLanguage) {
            isSecondLevel = sitemapNode.pagePath.split("/").length === 4
          }
          return isServices && isSecondLevel
        }
      )

      let thirdlevelServices = queryData.allAgilitySitemapNode.nodes.filter(
        sitemapNode => {
          const isServices = sitemapNode.pagePath.includes("/services")
          let isThirdLevel = sitemapNode.pagePath.split("/").length === 4
          if (props.isMultiLanguage) {
            isThirdLevel = sitemapNode.pagePath.split("/").length === 5
          }
          return isServices && isThirdLevel
        }
      )

      thirdlevelServices = thirdlevelServices.sort(
        (a, b) => a.menuText > b.menuText
      )

      secondlevelServices.forEach((service, index) => {
        const serviceCategory = service.pagePath.split("/")[2]
        secondlevelServices[index].childNodes = []
        thirdlevelServices.forEach(child => {
          const childServiceCategory = child.pagePath.split("/")[2]
          if (childServiceCategory === serviceCategory) {
            secondlevelServices[index].childNodes.push(child)
          }
        })
      })

      const organizedServices = secondlevelServices

      const data = {
        item: props.item,
        parentRoutes,
        categorizedPosts,
        organizedServices,
      }
      return <LinkTree {...data} />
    }}
  />
)

const LinkTree = props => {
  const customFields = props.item.customFields

  const ParentPagesLinksBranch = ({ props }) => {
    return (
      <div className="pages-links mb-4">
        <ul className="pages-links-branch">
          {props.map((route, index) => {
            const isTopLevelPage = route.pagePath.split("/").length === 2
            return (
              <li key={index}>
                {isTopLevelPage && (
                  <h2 className="headline">
                    <Link to={route.pagePath}>{route.menuText}</Link>
                  </h2>
                )}
                {!isTopLevelPage && (
                  <Link className="mb-2" to={route.pagePath}>
                    {route.menuText}
                  </Link>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const CategoryLinkBranch = props => {
    const handleRender = (pTerm, pData) => {
      return (
        <Fragment>
          <h3 className="term">{pTerm}</h3>
          <ul className="branch">
            {pData.map((item, index) => {
              return (
                <li key={index}>
                  <Link className="link" to={item.sitemapNode.pagePath}>
                    {item.sitemapNode.menuText}
                  </Link>
                </li>
              )
            })}
          </ul>
        </Fragment>
      )
    }

    const handleBranchTitle = () => {
      if (props.name.split(",").length > 1) {
        const allCommasString = props.name.split(",").join(", ")
        const lastCommaIndex = allCommasString.lastIndexOf(", ")
        const formattedName = `${allCommasString.substring(
          0,
          lastCommaIndex
        )} and ${allCommasString.substring(lastCommaIndex + 1)}`
        return formattedName
      } else {
        return props.name.split(",").join(" and ")
      }
    }

    return (
      <div className="category-branch">
        <h2 className="branch-title">{handleBranchTitle()}</h2>
        {props.insights.length > 0 && handleRender("Insights", props.insights)}
        {props.podcasts.length > 0 && handleRender("Podcasts", props.podcasts)}
      </div>
    )
  }

  const ServicesLinkBranch = ({ props }) => {
    return (
      <div className="services mb-4">
        <h3>{props.menuText}</h3>
        <ul className="services-links">
          {props.childNodes.map((child, index) => {
            return (
              <li key={index}>
                <Link to={child.pagePath}>{child.menuText}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <section className="link-tree">
      <div className="link-tree-wrapper container">
        <div className="row">
          <div className="col-12">
            <h1 className="title">{customFields.title}</h1>
          </div>
          <div className="col-12 col-sm-6 mb-4">
            <ParentPagesLinksBranch props={props.parentRoutes} />
          </div>
          <div className="col-12 col-sm-6 mb-4">
            <div className="pages-links">
              <h2 className="mb-4">Let us help you with</h2>
              <div className="services-wrapper">
                {props.organizedServices.map((service, index) => {
                  return <ServicesLinkBranch props={service} key={index} />
                })}
              </div>
            </div>
          </div>
          <div className="col-12 my-4 my-md-5">
            <h2 className="title smaller">Insights &amp; Podcasts Archive</h2>
          </div>

          {props.categorizedPosts.map((categoryGroup, index) => {
            return (
              <div key={index} className="col-12 col-sm-6 mb-4">
                <CategoryLinkBranch {...categoryGroup} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
