import React from 'react'
import "./ContentAndFullWidthImage.scss"
import { renderHTML } from "../agility/utils"
import { AnimatedCta } from '../components/AnimatedCta'

const ContentAndFullWidthImage = ({ item }) => {
  const { title, content, image, cta } = item.customFields

  const ctaStyles = {
    color: "white",
    border: "1.5px solid #FFF",
    marginTop: "60px"
  }


  return (
    <section className={`content-full-width-image item-${item.contentID}`}>
      <div className="row">
        <div className="col-12 col-md-5">
          <h2 className='title'>{title}</h2>
          <div
            className="content"
            dangerouslySetInnerHTML={renderHTML(content)}
          />
          <AnimatedCta styles={ctaStyles} href={null} text={cta.text} />
        </div>
        <div className="col-12 col-md-7 pr-0">
          <img className='image-bg' src={image.url} alt={image.label} />
        </div>
      </div>
    </section>
  )
}

export default ContentAndFullWidthImage