import React from "react"
import { Helmet } from "react-helmet"
import ButtonCTA from "../components/ButtonCTA"
import { renderHTML } from "../agility/utils"
import Form from "./../components/Form"
import "./HeroVideo.scss"
import { AnimatedCta } from '../components/AnimatedCta'

const HeroVideo = ({ item }) => {
  const {
    mainTitle,
    headline,
    subheadline,
    cTASettings,
    cTAStyles,
    partnerLogos,
    hasVideo,
    hasForm,
    imageBackground,
    mobileBackground,
    formHeadline,
    form,
    thankYouPage,
    animatedCTA
  } = item.customFields

  const ctaClass = cTAStyles.customFields.cSSClass
  const ctaStyles = {
    border: "2px solid #E12A5C",
    color: "#FFF",
    marginTop: "30px",
    marginBottom: "10px",
    background: "#E12A5C"
  }

  const backgroundStyles = `
  ${hasVideo === "true"
      ? `.flipped-background:before {
    background-image: url(${mobileBackground?.url});
    background-size: cover;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    transform: scaleX(-1);
    z-index: -1;`
      : `.hero-video.item-${item.contentID} {
      background-image: url(${mobileBackground?.url});
      background-size: cover;
    }
   `
    }
  
  @media (min-width: 768px) {
    ${hasVideo === "true"
      ? `
    .flipped-background:before {
      background-image: url(${imageBackground?.url});
      background-size: cover;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      transform: scaleX(-1);
      z-index: -1;
    }`
      : `.hero-video.item-${item.contentID} {
          background-image: url(${imageBackground?.url});
          background-size: cover;
       }`
    }
    
  }`

  const titleFontSize = hasVideo ? "font-size-4" : ""

  return (
    <section className={`hero-video item-${item.contentID}`}>
      {partnerLogos && (
        <Helmet>
          <link rel="preload" as="image" href={partnerLogos.url} />
        </Helmet>
      )}
      <div className="flipped-background">
        {imageBackground && <style>{backgroundStyles}</style>}

        <div className="container">
          <div className="row">
            <div className={`col ${hasForm ? "col-md-7" : "col-md-6"}`}>
              {headline && <span className="headline">{headline}</span>}
              <div
                className={`title ${hasForm ? "smaller" : ""} ${titleFontSize}`}
                dangerouslySetInnerHTML={renderHTML(mainTitle)}
              />
              <div className="wrapper">
                <p className="subheadline m-0">{subheadline}</p>
                {/* <ButtonCTA
                  data={{
                    text: cTASettings.text,
                    styles: ctaClass,
                    href: cTASettings.href,
                    scrollToForm: true,
                  }}
                /> */}
                {animatedCTA === "true" ? 
                <AnimatedCta styles={ctaStyles} href={cTASettings.href} text={cTASettings.text} /> : 
                <ButtonCTA
                  data={{
                    text: cTASettings.text,
                    styles: ctaClass,
                    href: cTASettings.href,
                    scrollToForm: true,
                  }}
                />}
              </div>
            </div>
            {hasForm === "true" && (
              <div className={`col col-sm-5 col-md-5`}>
                <div className="form-wrapper form-section">
                  <h2 className="text-center mb-4">{formHeadline}</h2>
                  <Form form={form} thankYouPage={thankYouPage} />
                </div>
              </div>
            )}
          </div>
          <div className="row row-logos">
            {partnerLogos && <div className="col d-flex align-items-center flex-column">
              <span className="partner-logos-title">Our Partner Logos</span>
              <img className="partners-logos" src={partnerLogos.url} />
            </div>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroVideo
