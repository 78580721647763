import React from "react"
import { Link } from "gatsby"
import "./SmallBanner.scss"

const SmallBanner = ({ item }) => {
  const customFields = item.customFields
  const textColor = customFields.textColor === "black" ? "#1C1C1C" : "#FFF"
  const alt = customFields.background.label
    ? customFields.background.label
    : customFields.title
  const backgrounds = `
    @media (min-width: 320px) {
      .small-banner.bg-${item.contentID} {
        background-image: url(${
          customFields.mobileBackground
            ? customFields.mobileBackground.url
            : customFields.background.url
        }); 
      }
    }
    @media (min-width: 768px) {
      .small-banner.bg-${item.contentID} {
        background-image: url(${customFields.background.url}); 
      }
    } 
    
    .small-banner.bg-${item.contentID} .headline {
      color: ${textColor}
    }

    .small-banner.bg-${item.contentID} .title {
      color: ${textColor}
    }

    .small-banner.bg-${item.contentID} .content {
      color: ${textColor}
    }
  `

  const CTA = () => {
    const buttonStyleClass = item.customFields.smallBannerCTAStyle.customFields
      .cSSClass
      ? item.customFields.smallBannerCTAStyle.customFields.cSSClass
      : "btn-primary-pink"
    return (
      <Link
        className={`btn ${buttonStyleClass}`}
        to={item.customFields.ctaLink.href}
      >
        {item.customFields.ctaText}
      </Link>
    )
  }

  return (
    <section
      className={`small-banner bg-${item.contentID} background-cover`}
      title={alt}
    >
      <style>{backgrounds}</style>
      <div className="container">
        <p className="headline">{customFields.headline}</p>
        <h2 className="title">{customFields.title}</h2>
        <p className="content">{customFields.description}</p>
        {item.customFields.ctaLink && item.customFields.ctaLink.href && <CTA />}
      </div>
    </section>
  )
}

export default SmallBanner
