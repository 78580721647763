import React from "react"
import { Helmet } from "react-helmet"
import "./HeroServices.scss"
import { renderHTML } from "../agility/utils"
import LazyImage from "../components/LazyImage"
import CallToAction from "../components/CallToAction"

const HeroServices = ({ item }) => {
  const {
    headline,
    mainTitle,
    text,
    description,
    cTAStyles,
    cTA,
    background,
    backgroundMobile,
    rightColumnImage,
    mobileRightImage,
    bottomImage,
  } = item.customFields

  const alt = background?.label
  const highQualityDesktop = `${encodeURI(background?.url)}`
  const highQualityMobile = `${encodeURI(backgroundMobile?.url)}`
  const backgroundStyles = `
  .hero-service.item-${item.contentID} {
    background-image: url(${highQualityMobile});
    background-size: cover; 
   }

   @media (min-width: 768px) {
    .hero-service.item-${item.contentID} {
        background-image: url(${highQualityDesktop});
        background-size: cover;
    }
   }`

  const buttonStyleClass = cTAStyles
    ? cTAStyles.customFields.cSSClass
    : "btn-primary-pink"
  // min-height: 550px;

  return (
    <section
      className={`hero-service item-${item.contentID}`}
      role="banner"
      title={alt}
    >
      <Helmet>
        <link rel="preload" as="image" href={highQualityDesktop} />
      </Helmet>
      <style>{backgroundStyles}</style>
      <div className="container">
        {headline && (
          <div className="headline-wrapper mb-3">
            <span className="headline-decoration" />
            <p className="headline-content mb-0">{headline}</p>
          </div>
        )}
        <div className="row no-gutters">
          <div className="col-md-8">
            <div
              className="mainTitle"
              dangerouslySetInnerHTML={renderHTML(mainTitle)}
            />
            <p className="text mb-5 mt-3">{text}</p>
            <CallToAction
              scrollToFormOnClick={true}
              text={cTA.text}
              href={cTA.href}
              buttonStyleClass={buttonStyleClass}
            />
          </div>
          <div className="col-md-4 overflow-hidden">
            <LazyImage
              src={rightColumnImage.url}
              alt={rightColumnImage.label}
              extraClasses="right-image d-none d-md-flex"
            />
            <LazyImage
              src={mobileRightImage.url}
              alt={mobileRightImage.label}
              extraClasses="right-image d-md-none"
            />
          </div>
        </div>
        <div className="row">
          <div className="col d-md-none">
            <div
              className="description text-center"
              dangerouslySetInnerHTML={renderHTML(description)}
            />
          </div>
          <div className="col d-flex justify-content-center justify-content-md-start">
            <LazyImage
              src={bottomImage.url}
              alt={bottomImage.label}
              extraClasses="bottom-image"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroServices
