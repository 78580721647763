import React from "react"
import { Link } from "gatsby"
import "./ImageBackground.scss"

export const ImageBackground = ({ item }) => {

  const customFields = item.customFields
  const alt = customFields?.backgroundImage?.label
    ? customFields.backgroundImage.label
    : ""
  const placeholder = customFields.placeholder
    ? customFields.placeholder.url
    : customFields?.backgroundImage?.url

  const styles = `.img-background.img-background-${item.contentID} .image-bg {
    background-image: url(${customFields?.backgroundImage?.url}), url(${placeholder});

    @media (min-width: 768px) {
        .img-background.img-background-${item.contentID} .image-bg {
          background-image: url(${customFields.backgroundImage.url}), url(${placeholder});
        }
      }
  }`

  const CTA = () => {
    const buttonStyleClass = customFields.buttonStyle.customFields.cSSClass
      ? customFields.buttonStyle.customFields.cSSClass
      : "btn-primary-pink"

    if (customFields.cTALink && customFields.cTALink.href !== "") {
      return (
        <Link
          className={`btn ${buttonStyleClass} mt-4 cta`}
          to={customFields.cTALink.href}
        >
          {customFields.cTALink.text}
        </Link>
      )
    }

    //If href is not setted, so it goes to the form in the same pahge
    if (customFields.cTALink && customFields.cTAScrollToForm) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${buttonStyleClass} mt-4 cta`}
          onClick={handleScrollToForm}
        >
          {customFields.cTALink.text}
        </button>
      )
    }
    return null
  }

  return (
    <section className={`img-background img-background-${item.contentID}`}>
      <style>{styles}</style>
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <div className="col-12 col-md-12">
            <figure className="image-bg" role="img" title={alt}>
              <div className="text-wrapper">
                <h1>{customFields.text}</h1>
                <CTA />
              </div>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageBackground
