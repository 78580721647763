import React, { Fragment, useRef } from "react"
import { useForm } from "react-hook-form"
// import "./Subscribe.scss"
import "./HeroSubscribeForm.scss"
import Modal from "./Modal"
import addToMailchimp from "gatsby-plugin-mailchimp"

/**
 * Component to render the subscribe form using the mailchimp plugin.
 * @function Subscribe
 */

const HeroSubscribeForm = ({
  label = "Subscribe",
  btnTxt = "Subscribe",
  placeholder = "",
}) => {
  const { handleSubmit, register, errors, formState } = useForm()
  const { isDirty } = formState
  const alreadySubscribedModal = useRef()
  const subscribedModal = useRef()

  /**
   * Handler for the mailchimp response when the email is submitted to subscribe.
   * @function onSubmit
   */
  const onSubmit = async ({ email, name }) => {
    const response = await addToMailchimp(email, { FNAME: name })
    // const response = await addToMailchimp(email)
    if (response.result === "success") {
      subscribedModal.current.open()
    }
    if (response.result === "error" && response.msg.includes("already")) {
      alreadySubscribedModal.current.open()
    }
    if (response.result === "error" && response.msg.includes("invalid")) {
      errors.apiInvalid = response.msg
    }
    if (response.result === "error" && response.msg.includes("too many")) {
      errors.tooMany = response.msg
    }
  }

  return (
    <Fragment>
      <div className="justify-content-start wrap-container">
        <div className="col-12 align-items-center subscribe-form-wrapper">
          {/* <div className="col-md-8 col-12 col-sm-5 p-md-0 pb-3"> */}
          <div className="col-md-12 col-12 col-sm-5 p-lg-0 pb-md-3">
            {/* <p className="subscribe-title  mb-sm-4">
              Subscribe To Our Newsletter!
            </p> */}
            <form
              className="subscribe-form align-items-center d-flex"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                className={`float-control form-control ${
                  isDirty ? "is-dirty" : ""
                }`}
              >
                <input
                  name="name"
                  className="dark-bg"
                  placeholder=""
                  ref={register({
                    required: "Required",
                  })}
                />
                <label className="label float-label" htmlFor="name">
                  Name
                </label>
              </div>
              <div
                className={`float-control form-control pr-md-3 p-md-0 ${
                  isDirty ? "is-dirty" : ""
                }`}
              >
                <input
                  name="email"
                  className="dark-bg"
                  placeholder={placeholder}
                  title="Enter your email and name to subscribe to our newsletter"
                  ref={register({
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />

                <label className="label float-label"> {label} </label>
              </div>
              {errors.email && errors.email.message}
              <div className="float-control form-control d-flex justify-content-end">
                <button className="btn btn-subscribe-hero" type="submit">
                  {btnTxt}
                </button>
              </div>
            </form>

            <Modal
              ref={alreadySubscribedModal}
              className="subscribe-form-wrapper-already-modal"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <p className="welcome-modal-title p-0 mb-3">
                      Already Onboard!
                    </p>
                    <p className="p-0 mt-3 mb-5 welcome-modal-content">
                      Thanks for being awesome, but it looks like you are
                      already subscribed to our newsletter.
                    </p>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              ref={subscribedModal}
              className="subscribe-form-wrapper-welcome-modal"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <p className="welcome-modal-title pt-2">
                      thank you for your subscription!
                    </p>
                    <p className="p-0 welcome-modal-content">
                      Feel free to continue browsing through our articles!
                    </p>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HeroSubscribeForm
