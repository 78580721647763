import React, { Fragment } from "react"
import { isSingleDigit } from "./../agility/helpers"
import { renderHTML } from "./../agility/utils"
import "./TwoColumnsImageAndList.scss"
import { Link } from "gatsby"

const TwoColumnsImageAndList = ({ item }) => {
  const customFields = item.customFields
  const list = customFields.contentList
  const alignment =
    item.customFields.imageAlignment === "right" ? "flex-md-row-reverse" : ""

  const alt = customFields.image.label ? customFields.image.label : ""
  const placeholder = customFields.placeholder
    ? customFields.placeholder.url
    : customFields.image.url

  const formattedItems = list.map((item, key) => {
    const humanIndex = key + 1
    return {
      ...item.customFields,
      number: isSingleDigit(humanIndex) ? `0${key + 1}` : `${key + 1}`,
    }
  })
  const buttonStyleClass = customFields.buttonStyle
    ? customFields.buttonStyle.customFields.cSSClass
    : "btn-primary-pink"

  const styles = `
    .tc-img-list.tc-img-list-${item.contentID} .tc-image {
      background-image: url(${customFields.mobileImage.url}), url(${placeholder});
    }
    
    @media (min-width: 768px) {
      .tc-img-list.tc-img-list-${item.contentID} .tc-image {
        background-image: url(${customFields.image.url}), url(${placeholder});
      }
    }
  `

  const ListItem = ({ props }) => {
    const title = props.displayTitle ? props.displayTitle : props.title
    return (
      <Fragment>
        <div className="col-2 col-sm-3 mb-2 mb-sm-4 text-center">
          <p className="list-number">{props.number}</p>
        </div>
        <div className="col-10 col-sm-9 mb-4 mb-sm-4">
          <p className="list-title">{title}</p>
          <div
            className="list-description"
            dangerouslySetInnerHTML={renderHTML(props.description)}
          ></div>
        </div>
      </Fragment>
    )
  }

  const ListItems = ({ items }) => {
    return items.map((listItem, index) => {
      return <ListItem props={listItem} key={index} />
    })
  }

  const CTA = () => {
    if (customFields.ctaLink && customFields.ctaLink.href !== "") {
      return (
        <Link
          className={`btn ${buttonStyleClass} mt-4`}
          to={customFields.ctaLink.href}
        >
          {customFields.ctaLink.text}
        </Link>
      )
    }

    if (customFields.ctaLink && customFields.cTAScrollToForm) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${buttonStyleClass} mt-4`}
          onClick={handleScrollToForm}
        >
          {customFields.ctaLink.text}
        </button>
      )
    }
    return null
  }

  return (
    <section className={`tc-img-list tc-img-list-${item.contentID}`}>
      <style>{styles}</style>
      <div className="container-fluid px-0">
        <div className={`row no-gutters ${alignment}`}>
          <div className="col-12 col-lg-6">
            <figure className="tc-image mb-0" role="img" title={alt}>
              <div className="text-over-background">
                {customFields.text && (
                  <div
                    className="headline-decoration d-sm-block"
                    style={{ borderColor: "#E12A5C" }}
                  />
                )}
                {customFields.title ? (
                  <h1 className="title">{customFields.title}</h1>
                ) : (
                  ""
                )}
                {customFields.text ? (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={renderHTML(customFields.text)}
                  />
                ) : (
                  ""
                )}
                <CTA />
              </div>
            </figure>
          </div>
          <div className="col-12 col-lg-6">
            <div className="list-wrapper">
              <div className="row">
                <ListItems items={formattedItems} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnsImageAndList
