import React from "react"
import { graphql, StaticQuery } from "gatsby"
import MostPopular from "./MostPopular"

/**
 * Anonymous element to query the podcasts posts and render them using the MostPopular component.
 */

export default () => (
  <StaticQuery
    query={graphql`
      {
        allAgilityPodcast {
          nodes {
            sitemapNode {
              pageView
              pagePath
            }
            customFields {
              title
            }
          }
        }
      }
    `}
    render={(queryData) => {
      const allPageViews = queryData.allAgilityPodcast.nodes || []
      const mostPopular = allPageViews
        .sort((a, b) => a.sitemapNode.pageView - b.sitemapNode.pageView)
        .reverse()
        .slice(0, 3)

      return <MostPopular popular={mostPopular} type="Podcasts" />
    }}
  />
)
