import React from "react"
import "./PartnerLogos.scss"

const PartnerLogos = ({ item }) => {
  const { logos } = item.customFields

  return (
    <section className={`partner-logos item${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img className="logos" src={logos.url} alt={logos.label} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PartnerLogos
