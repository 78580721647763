import React from "react"
import "./ServicesListing.scss"
import { StaticQuery, Link, graphql } from "gatsby"
import SVG from "./../components/SVG"

export default props => (
  <StaticQuery
    query={graphql`
      query Services {
        allAgilityServicesOverviewCard {
          nodes {
            customFields {
              artImage {
                url
              }
              description
              link {
                href
                target
                text
              }
              title
            }
            ellipseColor {
              customFields {
                hexadecimalValue
                name
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <ServicesListing
          services={queryData.allAgilityServicesOverviewCard.nodes}
          {...props.item}
        />
      )
    }}
  />
)

const ServiceCard = ({ customFields, ...props }) => {
  const borderColor = props.ellipseColor
    ? props.ellipseColor.customFields.hexadecimalValue
    : "#E12A5C"
  return (
    <div className="col-12 col-md-6 services-overview-item">
      <figure
        className="services-overview-item-art"
        aria-hidden="true"
        style={{ borderColor: borderColor }}
      >
        <img src={customFields.artImage.url} alt={customFields.title}></img>
      </figure>
      <article className="services-overview-item-article">
        <h3>{customFields.title}</h3>
        <p>{customFields.description}</p>
        {customFields.link && customFields.link.href && (
          <Link
            className="btn btn-primary-pink"
            to={customFields.link.href}
            target={customFields.link.target}
          >
            {customFields.link.text}
          </Link>
        )}
      </article>
    </div>
  )
}

const ServicesListing = ({ services, customFields }) => {
  return (
    <section className="services-overview">
      <SVG
        name="dotted-squares"
        className="services-overview-squares d-none d-sm-flex"
        width={495}
        height={226}
      />
      <div className="container">
        <div className="services-overview-title">
          <h2 dangerouslySetInnerHTML={{ __html: customFields.headline }}></h2>
        </div>
        <div className="row">
          {services.map((service, index) => (
            <ServiceCard {...service} key={index} />
          ))}
        </div>
      </div>
    </section>
  )
}
