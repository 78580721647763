import React from "react"
import ContentZone from "../agility/components/ContentZone"
import GlobalHeader from "../components/GlobalHeader"
import FullHeight from "../components/FullHeight"
import GlobalFooter from "../components/GlobalFooter"

const FullHeightTemplateWithLightHeader = props => {
  return (
    <div className="pixel506">
      <GlobalHeader
        languageCode={props.languageCode}
        isMultiLanguage={props.isMultiLanguage}
        variation="light"
        route={props.route}
      ></GlobalHeader>
      <main>
        <FullHeight>
          <ContentZone name="FullHeightZone" {...props} />
          <div className="full-height-section">
            <ContentZone name="MainContentZone" {...props} />
            <GlobalFooter
              languageCode={props.languageCode}
              isMultiLanguage={props.isMultiLanguage}
              route={props.route}
            ></GlobalFooter>
          </div>
        </FullHeight>
      </main>
    </div>
  )
}
export default FullHeightTemplateWithLightHeader
