import React, { useRef, useEffect, useState } from "react"
import Glide, {
  Controls,
  Keyboard,
  Breakpoints,
  Swipe,
  Autoplay,
} from "@glidejs/glide/dist/glide.modular.esm"
import { Link } from "gatsby"
import "./HorizontalScroller.scss"
import { renderHTML } from "./../agility/utils"
import useHasMounted from "./../hooks/useHasMounted"

const HorizontalScroller = ({ item }) => {
  const container = useRef()
  const ctaClass = item.customFields.cTAStyle
    ? item.customFields.cTAStyle.customFields.cSSClass
    : "btn-primary-black"
  const scrollerWidth = 380 * item.customFields.itemstoScroll.length
  const stepSize =
    (380 * 100) / 380 / (item.customFields.itemstoScroll.length * 2)
  const end =
    typeof window !== `undefined`
      ? scrollerWidth > window.innerHeight
        ? scrollerWidth
        : window.innerHeight
      : 0
  const [progress, setProgress] = useState(0)
  const [scrollPos, setScrollPos] = useState(0)
  const hasMounted = useHasMounted()

  const handleScroll = () => {
    const containerStyles =
      typeof window !== `undefined`
        ? window.getComputedStyle(
            container.current.querySelector(".horizontal-scroller-scroller")
          )
        : { width: 0 }
    const wrapperWidth = parseInt(containerStyles.width)
    const start = container.current.offsetTop
    const scrollPos = typeof window !== `undefined` ? window.scrollY : 0
    const percentage =
      typeof window !== `undefined`
        ? ((scrollPos - start) * 100) / (end - window.innerHeight)
        : 0

    setProgress(percentage <= 0 ? 0 : percentage >= 100 ? 100 : percentage)
    setScrollPos((end - wrapperWidth) * (progress / 100) + 10)
  }

  useEffect(() => {
    if (hasMounted) {
      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (hasMounted) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  })

  if (!hasMounted) {
    return null
  }

  const Card = ({ data, index }) => {
    const cardNumer = (index + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    const step = stepSize * (index + 1) + stepSize * index

    return (
      <div
        className={`horizontal-scroller-card ${
          progress >= step ? "active" : ""
        }`}
      >
        <span className="list-number">{cardNumer}</span>
        <div className="content-wrapper">
          <h3>{data.customFields.title}</h3>
          <div
            dangerouslySetInnerHTML={renderHTML(data.customFields.description)}
          ></div>
        </div>
      </div>
    )
  }

  return (
    <div
      className="horizontal-scroller"
      ref={container}
      style={{ height: `${end}px` }}
    >
      <div className="horizontal-scroller-sticky flex-lg-column">
        <div className="horizontal-scroller-wrapper">
          <div className="horizontal-scroller-content">
            <h2>{item.customFields.title}</h2>
            {item.customFields.link && item.customFields.link.href && (
              <div className="mt-3">
                <Link
                  className={`btn black ${ctaClass}`}
                  to={item.customFields.link.href}
                  target={item.customFields.link.target}
                >
                  {item.customFields.link.text}
                </Link>
              </div>
            )}
          </div>
          <div className="horizontal-scroller-scroller">
            <div className="horizontal-scroller-progress">
              <span style={{ width: `${progress}%` }}></span>
            </div>
            <div className="scroller">
              <div
                className="scroller-cards"
                style={{
                  width: `${scrollerWidth}px`,
                  transform: `translate3d(-${scrollPos}px, 0, 0)`,
                }}
              >
                {item.customFields.itemstoScroll.map((card, index) => (
                  <Card data={card} index={index} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MobileSlider = ({ item }) => {
  const slider = useRef()
  const ctaClass = item.customFields.cTAStyle
    ? item.customFields.cTAStyle.customFields.cSSClass
    : "btn-primary-black"

  const CardSlide = ({ data, index }) => {
    return (
      <li className="glide__slide">
        <h3>{data.customFields.title}</h3>
        <p className="content">{data.customFields.description}</p>
        {item.customFields.link && item.customFields.link.href && (
          <div className="mt-5 text-center">
            <Link
              className={`btn black ${ctaClass}`}
              to={item.customFields.link.href}
              target={item.customFields.link.target}
            >
              {item.customFields.link.text}
            </Link>
          </div>
        )}
      </li>
    )
  }

  useEffect(() => {
    const elementToGlide = slider.current.querySelector(".glide")
    const glide = new Glide(elementToGlide, {
      type: "carousel",
      perView: 1,
      gap: 0,
    })

    glide.mount({ Controls, Keyboard, Breakpoints, Swipe, Autoplay })

    return () => {
      glide.destroy()
    }
  })

  return (
    <div className="horizontal-scroller-slider" ref={slider}>
      <h2>{item.customFields.title}</h2>
      <div className="glide">
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {item.customFields.itemstoScroll.map((card, index) => (
              <CardSlide data={card} index={index} key={index} />
            ))}
          </ul>
        </div>
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {item.customFields.itemstoScroll.map((card, index) => (
            <button
              className="glide__bullet"
              data-glide-dir={`=${index}`}
              key={index}
              aria-label="btn"
            ></button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default props => {
  return (
    <section className="horizontal-scroller-section">
      <HorizontalScroller {...props} />
      <MobileSlider {...props} />
    </section>
  )
}
