import React, { useRef, useState } from "react"
import Form from "./../components/Form"
import "./FrequentlyAskedQuestions.scss"
// import Badge from "../components/Badge"
import AWSGoogleBadges from "../components/AWSGoogleBadges"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const FrequentlyAskedQuestions = ({ item }) => {
  const [leftActiveArrow, setLeftActiveArrow] = useState("")
  const [rightActiveArrow, setRightActiveArrow] = useState("active")

  const customFields = item.customFields
  const {
    backgroundImage,
    leftTitle,
    leftText,
    form,
    formHeadline,
    thankYouPage,
    changeFontType,
    backgroundMobile,
    addBadge,
    transparentBackground,
    sliderTitle,
    description,
    sliderCards,
  } = customFields
  const slider = useRef(null)

  const hasShadow = customFields.form?.customFields?.shadow

  const handleClickLeftArrow = () => {
    if (slider) {
      slider.current.slickPrev()
    }
    setLeftActiveArrow("")
    setRightActiveArrow("active")
  }
  const handleClickRightArrow = () => {
    // slider?.current.slickNext()
    if (slider) {
      slider.current.slickNext()
    }
    setLeftActiveArrow("active")
    setRightActiveArrow("")
  }

  const NextArrow = ({ extraClassName }) => {
    return (
      <div className={`custom-arrow ${extraClassName}`}>
        <button
          className={`right-arrow-btn ${rightActiveArrow}`}
          onClick={() => handleClickRightArrow()}
        >
          &#8594;
        </button>
      </div>
    )
  }
  const PrevArrow = ({ extraClassName }) => {
    return (
      <div className={`custom-arrow ${extraClassName}`}>
        <button
          className={`left-arrow-btn ${leftActiveArrow}`}
          onClick={() => handleClickLeftArrow()}
        >
          &#8592;
        </button>
      </div>
    )
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    centerMode: true,
    className: "center",
    centerPadding: "0px",
    slidesToScroll: 1,
    currentSlide: 0,
    adaptiveHeight: true,
    prevArrow: <PrevArrow extraClassName="d-none d-md-flex" />,
    nextArrow: <NextArrow extraClassName="d-none d-md-flex" />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  }

  const Card = ({ card }) => {
    const { title, description, image } = card.customFields
    return (
      <div className="card d-flex m-md-2">
        <div className="row no-gutters">
          <div className="col-md-6 pl-md-4 p-3 d-flex flex-column justify-content-center">
            <h2 className="title">{title}</h2>
            <p className="description">{description}</p>
          </div>
          <div className="col-md-6">
            <img className="slider-image" src={image.url} alt={image.label} />
          </div>
          <div className="col-12 d-flex justify-content-around align-items-center p-0">
            <PrevArrow extraClassName="mobile d-md-none" />
            <NextArrow extraClassName="mobile d-md-none" />
          </div>
        </div>
      </div>
    )
  }

  const backgroundStyles = `
  .frequently-asked-questions-${item.contentID} {
    background-image: url(${backgroundImage?.url}) !important;
  }

    @media only screen and (max-width: 767px) {
      .frequently-asked-questions-${item.contentID} {
        background-image: url(${backgroundMobile?.url}) !important;
      }
    }
  `

  const transparentItem = backgroundImage ? "transparent" : ""
  const whiteBackground = transparentBackground ? "white" : ""

  const QuestionItem = ({ props }) => {
    const [showAnswer, setShowAnswer] = useState(false)
    const show_or_hide = showAnswer ? "-" : "+"
    const borderClass = showAnswer ? "border" : ""

    return (
      <h4
        className={`question-item ${transparentItem}`}
        onClick={() => setShowAnswer(!showAnswer)}
      >
        <div
          className={`question-wrapper ${borderClass} d-flex justify-content-between`}
        >
          <h4 className="question">{props.customFields.question}</h4>
          <span>{show_or_hide}</span>
        </div>
        {showAnswer ? (
          <p className="answer">{props.customFields.answer}</p>
        ) : null}
      </h4>
    )
  }

  const CTA = () => {
    const handleScrollToForm = () => {
      if (typeof window === `undefined`) return
      if (document.querySelector(".form-section")) {
        window.scrollTo({
          top: document.querySelector(".form-section").offsetTop,
          behavior: "smooth",
        })
      }
    }
    if (customFields.cTA && !customFields.cTA.href) {
      return (
        <button className="cta-btn btn-solid-pink" onClick={handleScrollToForm}>
          {customFields.cTA.text}
        </button>
      )
    }

    if (customFields.cTA) {
      return (
        <button className={`cta-btn btn-solid-pink`}>
          {customFields.cTA.text}
        </button>
      )
    }
    return null
  }

  return (
    <section
      className={`frequently-asked-questions frequently-asked-questions-${
        item.contentID
      } ${backgroundImage ? "background-cover" : ""} ${whiteBackground}`}
    >
      <style>{backgroundStyles}</style>
      {sliderCards && (
        <div className="container p-">
          <div className="row">
            <div className="col-md-12 slider-module">
              <span className="headline-line" />
              <h2 title="slider-title">{sliderTitle}</h2>
              <p className="slider-description">{description}</p>
              <Slider ref={slider} {...settings}>
                {sliderCards.map((card, index) => {
                  return <Card card={card} key={index} />
                })}
              </Slider>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <div className="col-12 col-md-12 d-flex justify-content-center">
            <div className="faq-block">
              <div className="title-header d-flex justify-content-center flex-column align-items-center">
                <div
                  className="headline-decoration d-sm-block"
                  style={{ borderColor: "#E12A5C" }}
                />
                <h2 className="title d-flex justify-content-center">
                  {customFields.title}
                </h2>
              </div>
              {customFields.questionsItems.map(question => {
                return (
                  <QuestionItem key={question.contentID} props={question} />
                )
              })}
              <div className="cta mt-5 d-flex justify-content-center">
                <CTA />
              </div>
            </div>
          </div>
        </div>
      </div>
      {form && (
        <div className="container">
          <div className="row row-form form-section">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <span className="headline-line" />
              <h2 className="left-title">{leftTitle}</h2>
              <p className="left-text">{leftText}</p>
            </div>
            <div className={`col-12 col-sm-5 col-md-6 p-0 p-md-2`}>
              <div className={`form-wrapper  ${hasShadow ? "shadow" : ""}`}>
                {hasShadow && <span className="headline-line" />}
                <h2
                  className={`text-center mb-4 ${
                    changeFontType ? "change-font" : ""
                  }`}
                >
                  {formHeadline}
                </h2>
                <Form form={form} thankYouPage={thankYouPage} />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {addBadge === "true" && <Badge />} */}
      {addBadge === "true" && <AWSGoogleBadges />}
    </section>
  )
}
export default FrequentlyAskedQuestions
