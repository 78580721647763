import React from 'react'
import "./TagAndContent.scss"

const TagAndContent = ({ item }) => {
  const { tagName, roundedTagName, tagOverTitle, title, content, fontSize } = item.customFields


  if (tagOverTitle) {
    return (
      <section className={`tag-content item-${item.contentID}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10">
              <span className={`tag-name ${roundedTagName ? "rounded" : ""} md-mb-5`}>{tagName}</span>
              <h2 className={`title ${fontSize}`}>{title}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="content my-5 mr-md-5"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className={`tag-content item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3">
            <span className={`tag-name ${roundedTagName ? "rounded" : ""} md-mb-5`}>{tagName}</span>
          </div>
          <div className="col-12 col-md-9">
            <h2 className={`title ${fontSize} `}>{title}</h2>
            <div className="content my-5 mr-md-5"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TagAndContent