import React from "react"
import "./FullBackgroundImage.scss"

export const FullBackgroundImage = ({ item }) => {
  const customFields = item.customFields

  const alt = customFields?.backgroundimage?.label
    ? customFields.backgroundimage.label
    : ""
  const placeholder = customFields.placeholder
    ? customFields.placeholder.url
    : customFields?.backgroundimage?.url

  const styles = `.full-background-img.full-background-img-${item.contentID} .fb-img {
    background-image: url(${customFields.mobileImage.url}), url(${placeholder});

    @media (min-width: 768px) {
        .full-background-img.full-background-img-${item.contentID} .fb-img {
          background-image: url(${customFields.backgroundimage.url}), url(${placeholder});
        }
      }
  }`

  const Cards = () => {
    return (
      <div className="cards-wrapper d-flex flex-column">
        <div className="card p-4">
          <h1 className="card-title">{customFields.firstcardtitle}</h1>
          <div
            className="headline-decoration d-none d-sm-block mt-2 mb-2"
            style={{ borderColor: "#E12A5C" }}
          />
          <div className="card-text">{customFields.firstcardtext}</div>
        </div>
        <div className="card p-4">
          <h1 className="card-title">{customFields.secondcardtitle}</h1>
          <div
            className="headline-decoration d-none d-sm-block mt-2 mb-2"
            style={{ borderColor: "#E12A5C" }}
          />
          <div className="card-text">{customFields.secondcardtext}</div>
        </div>
      </div>
    )
  }

  return (
    <section
      className={`full-background-img full-background-img-${item.contentID}`}
    >
      <style>{styles}</style>
      <div className="container-fluid px-0">
        <div className="row no-gutters d-none d-sm-flex">
          <div className="col-12 col-md-12 desktop-bg">
            <figure className="fb-img mb-0" role="img" title={alt}>
              <div className="row">
                <div className="col-12 col-md-6 col-sm-12">
                  <div className="title-wrapper">
                    <div
                      className="headline-decoration d-none d-sm-block"
                      style={{ borderColor: "#E12A5C" }}
                    />
                    <h1 className="title">{customFields.title}</h1>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-sm-12">
                  <Cards />
                </div>
              </div>
            </figure>
          </div>
        </div>
        <div className="row no-gutters d-sm-none">
          <div className="col-sm-12 mobile-bg flex-column">
            <figure className="fb-img mb-0" role="img" title={alt}>
              <div className="row">
                <div className="col-12 col-md-6 col-sm-12">
                  <div className="title-wrapper">
                    <div
                      className="headline-decoration d-sm-block"
                      style={{ borderColor: "#E12A5C" }}
                    />
                    <h1 className="title">{customFields.title}</h1>
                  </div>
                </div>
              </div>
            </figure>
          </div>
        </div>
        <div className="row no-gutters d-sm-none">
          <div className="col-12 mobile-cards">
            <Cards />
          </div>
        </div>
      </div>
    </section>
  )
}
export default FullBackgroundImage
