import React from "react"
import "./ImageHero.scss"
import { Link } from "gatsby"
import ResponsiveBackground from "./../components/ResponsiveBackground"

const ImageHero = ({ item }) => {
  const wrapperClasses = `${item.customFields.mobileVerticalAlignment} ${item.customFields.mobileHorizontalAlignment}
    ${item.customFields.desktopVerticalAlignment} ${item.customFields.desktopHorizontalAlignment}`
  const fullHeight =
    item.customFields.covertheViewPort &&
    item.customFields.covertheViewPort === "true"
  const hasParallax =
    item.customFields.parallaxEffect &&
    item.customFields.parallaxEffect === "true"
  const wrapDescription =
    item.customFields.wrapDescription &&
    item.customFields.wrapDescription === "true"
  const descriptionClasses = `hero-content-html 
      ${item.customFields.mobileTextAlignment} 
      ${item.customFields.desktopTextAlignment}
      ${wrapDescription ? "wrapped" : ""}`
  const buttonStyleClass = item.customFields.cTAStyle
    ? item.customFields.cTAStyle.customFields.cSSClass
    : "btn-primary-pink"
  const hasFixedHeight =
    item.customFields.fixedHeight && item.customFields.fixedHeight === "true"

  const CTA = () => {
    if (!item.customFields.cTAScrollToForm && item.customFields.cTALink.href) {
      return (
        <Link
          className={`btn ${buttonStyleClass}`}
          to={item.customFields.cTALink.href}
        >
          {item.customFields.cTALink.text}
        </Link>
      )
    }

    if (item.customFields.cTAScrollToForm && !item.customFields.cTALink.href) {
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${buttonStyleClass}`}
          onClick={handleScrollToForm}
        >
          {item.customFields.cTALink.text}
        </button>
      )
    }

    return null
  }

  return (
    <section
      className={`hero ${wrapperClasses} ${fullHeight ? "cover" : "fit"} ${
        hasFixedHeight ? "fixed-h" : ""
      }`}
    >
      <ResponsiveBackground
        className="hero-background"
        mobile={item.customFields.mobileImage.url}
        desktop={item.customFields.desktopImage.url}
        alt={item.customFields.desktopImage.label}
        hasParallax={hasParallax}
      ></ResponsiveBackground>
      <div className="container hero-content">
        <article
          className="hero-content-article"
          style={{
            color: item.customFields.textColor
              ? item.customFields.textColor.customFields.hexadecimalValue
              : "#000",
          }}
        >
          {item.customFields.titleTab && (
            <div className="hero-content-label">
              <span
                style={{
                  backgroundColor:
                    item.customFields.detailColors.customFields
                      .hexadecimalValue,
                }}
              ></span>
              {item.customFields.titleTab}
            </div>
          )}
          {item.customFields.title && (
            <h1 className="hero-content-title">{item.customFields.title}</h1>
          )}
          {item.customFields.description && (
            <div
              className={descriptionClasses}
              dangerouslySetInnerHTML={{
                __html: item.customFields.description,
              }}
            ></div>
          )}
          {item.customFields.cTALink && <CTA />}
        </article>
      </div>
    </section>
  )
}

export default ImageHero
