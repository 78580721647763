import React from "react"
import SVG from "./SVG"
import "./LandingFooter.scss"

function LandingFooter() {
  return (
    <footer>
      <ul className="social-links mt-sm-3 mb-4 justify-content-center">
        <li className="social-links__item">
          <a
            href="https://www.linkedin.com/company/pixel506/"
            rel="noopener"
            target="_blank"
            aria-label="Pixel506 LinkedIn page (Opens a new Window)."
          >
            <SVG fill={"#FFF"} name={"linked-in"} width={40} height={40}></SVG>
          </a>
        </li>
        <li className="social-links__item">
          <a
            href="https://www.instagram.com/pixel506official"
            rel="noopener"
            target="_blank"
            aria-label="Pixel506 Instagram page (Opens a new Window)."
          >
            <SVG fill={"#FFF"} name={"instagram"} width={40} height={40}></SVG>
          </a>
        </li>
        <li className="social-links__item">
          <a
            href="https://www.facebook.com/pixel506"
            rel="noopener"
            target="_blank"
            aria-label="Pixel506 Facebook page (Opens a new Window)."
          >
            <SVG fill={"#FFF"} name={"facebook"} width={40} height={40}></SVG>
          </a>
        </li>
      </ul>
      <p className="copyright">&#169; PIXEL506, LLC. ALL RIGHTS RESERVED</p>
    </footer>
  )
}

export default LandingFooter
