import React from "react"
import { graphql, StaticQuery } from "gatsby"
// import MostPopular from "./MostPopular"
import MostPopularArticleCard from "./MostPopularArticleCard"
import AnimatedMostPopularArticleCard from "./AnimatedMostPopularArticleCard"

/**
 * Anonymous element to query the podcasts posts and render them using the MostPopular component.
 */

export default ({ animated = false }) => (
  <StaticQuery
    query={graphql`
      {
        allAgilityInsight {
          nodes {
            sitemapNode {
              pageView
              pagePath
            }
            customFields {
              title
              excerpt
              image {
                url
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      const allPageViews = queryData.allAgilityInsight.nodes || null
      const articlesToShow = animated ? 3 : 4
      const mostPopular = allPageViews
        .filter(a => a.sitemapNode !== null)
        .sort((a, b) => a.sitemapNode.pageView - b.sitemapNode.pageView)
        .reverse()
        .slice(0, articlesToShow)

      //   return <MostPopular popular={mostPopular} type="Articles" />
      // return <MostPopularArticleCard popular={mostPopular} type="Articles" />
      if (!animated) {
        return <MostPopularArticleCard popular={mostPopular} type="Articles" />
      } else {
        return <AnimatedMostPopularArticleCard popular={mostPopular} />
      }
    }}
  />
)
