import React from "react"
import "./MostPopular.scss"

/**
 * Loops trough the popuplar posts to render the link.
 * @function MostPopular
 */
const MostPopular = ({ popular, type }) => {
  return (
    <div className="most-popular">
      <h2>Most Popular {type}</h2>
      <ul>
        {popular.map((post, key) => {
          return (
            <li key={key}>
              <a href={post.sitemapNode.pagePath}>{post.customFields.title}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MostPopular
