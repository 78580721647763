import React, { useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import "./ServicesAccordions.scss"
import { isSingleDigit } from "./../agility/helpers"

export default props => (
  <StaticQuery
    query={graphql`
      query ServicesForAccordion {
        allAgilityServicesOverviewCard {
          nodes {
            customFields {
              artImage {
                url
              }
              description
              title
            }
            ellipseColor {
              customFields {
                hexadecimalValue
                name
              }
            }
          }
        }
        allAgilitySitemapNode(
          filter: {
            visible: { menu: { eq: true } }
            pagePath: { regex: "/services/" }
          }
        ) {
          nodes {
            pagePath
            menuText
            name
          }
        }
      }
    `}
    render={queryData => {
      const services = queryData.allAgilitySitemapNode.nodes
      const servicesInfo = queryData.allAgilityServicesOverviewCard.nodes

      const secondlevelServices = services.filter(sitemapNode => {
        let isSecondLevel = sitemapNode.pagePath.split("/").length === 3
        if (props.isMultiLanguage) {
          isSecondLevel = sitemapNode.pagePath.split("/").length === 4
        }
        return isSecondLevel
      })

      let thirdlevelServices = services.filter(sitemapNode => {
        let isThirdLevel = sitemapNode.pagePath.split("/").length === 4
        if (props.isMultiLanguage) {
          isThirdLevel = sitemapNode.pagePath.split("/").length === 5
        }
        return isThirdLevel
      })

      thirdlevelServices = thirdlevelServices.sort(
        (a, b) => a.menuText > b.menuText
      )

      secondlevelServices.forEach((service, index) => {
        const serviceCategory = service.pagePath.split("/")[2]
        secondlevelServices[index].childNodes = []
        thirdlevelServices.forEach(child => {
          const childServiceCategory = child.pagePath.split("/")[2]
          if (childServiceCategory === serviceCategory) {
            secondlevelServices[index].childNodes.push(child)
          }
        })
      })

      services.childNodes = secondlevelServices

      servicesInfo.forEach(serviceInfo => {
        services.childNodes.forEach(service => {
          if (serviceInfo.customFields.title === service.menuText) {
            serviceInfo.name = service.name
          }
        })
      })

      return (
        <ServicesAccordions
          services={services.childNodes}
          servicesInfo={queryData.allAgilityServicesOverviewCard.nodes}
          {...props.item}
        />
      )
    }}
  />
)

const ServicesAccordions = ({ services, servicesInfo, customFields }) => {
  const [activeService, setActiveService] = useState(null)

  const handleActiveService = service => {
    if (service !== activeService) {
      setActiveService(service)
    } else {
      setActiveService(null)
    }
  }

  return (
      <section className="services-accordions">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              {services.map((service, key) => {
                let maxHeightClass

                if (service.childNodes.length > 8) {
                  maxHeightClass = "large"
                } else {
                  maxHeightClass = "middle"
                }

                if (service.childNodes.length > 15) {
                  maxHeightClass = "extra-large"
                }

                return (
                  <button
                    key={key}
                    className={`accordion ${
                      activeService === service.name ? "active" : "inactive"
                    }`}
                    aria-expanded={activeService === service.name}
                    onClick={() => handleActiveService(service.name)}
                  >
                    <div className="accordion-wrapper">
                      <h2 className="accordion-topic mb-0">
                        {service.menuText}
                      </h2>
                      <div
                        className={`accordion-subpanel ${maxHeightClass} ${
                          service.name
                        } ${activeService === service.name ? "visible" : ""} ${
                          service.name
                        }`}
                      >
                        <ul className="accordion-links">
                          {service.childNodes.map((childNode, key) => {
                            const humanIndex = key + 1
                            return (
                              <li key={key} className="accordion-links__item">
                                <span>
                                  {isSingleDigit(humanIndex)
                                    ? `0${key + 1}`
                                    : `${key + 1}`}
                                </span>
                                <Link to={childNode.pagePath}>
                                  {childNode.menuText}
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </button>
                )
              })}
            </div>
            <div className="col-12 col-md-6 d-none d-sm-flex justify-content-center align-items-center">
              <div className="services-accordions-title d-none d-sm-flex">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: customFields.pinkBoxHeadline,
                  }}
                ></h2>
              </div>
              <div
                className={`service-box  ${
                  activeService === null ? "" : "d-none"
                }`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: customFields.inactiveText,
                  }}
                ></div>
              </div>
              {servicesInfo.map((service, index) => {
                const borderColor = service.ellipseColor
                  ? service.ellipseColor.customFields.hexadecimalValue
                  : "#E12A5C"
                return (
                  <div
                    key={index}
                    className={`service-box  ${
                      activeService === service.name ? "" : "d-none"
                    }`}
                  >
                    <figure
                      className="services-overview-item-art"
                      aria-hidden="true"
                      style={{ borderColor: borderColor }}
                    >
                      <img
                        src={service.customFields.artImage.url}
                        alt={service.customFields.title}
                      ></img>
                    </figure>
                    <h2>{service.customFields.title}</h2>
                    <p>{service.customFields.description}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
  )
}
