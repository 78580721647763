import React from "react"
import Form from "./Form"
import "./InsightForm.scss"

const form = {
  customFields: {
    name: "Insight form",
    inputs: [
      {
        customFields: {
          label: "Your Full Name",
          name: "name",
          type: "text",
          required: "true",
        },
      },
      {
        customFields: {
          label: "Corporate Email",
          name: "corporateemail",
          type: "email",
          required: "true",
        },
      },
      {
        customFields: {
          label: "Industry",
          name: "Industry",
          type: "text",
          required: "true",
        },
      },
      {
        customFields: {
          label: "Comments",
          name: "comments",
          type: "long-text",
          required: "true",
        },
      },
    ],
    inputIDs: "441,1366,3417,3559",
    small: "true",
    shadow: "true",
    cTALabel: "Send",
    cTAStyle: {
      customFields: { name: "Primary Pink", cSSClass: "btn-primary-pink" },
    },
    cTAStyleID: "76",
  },
}

const InsightForm = () => {
  const thank_you_page = "/thank-you"

  return (
    <div className="form-insight-wrapper mt-md-5">
      <h2 className="form-title mb-4">
        Want to learn how our Nearshore teams can enhance your business growth?
        <br />
        Contact us today!
      </h2>
      <Form form={form} thankYouPage={thank_you_page} />
    </div>
  )
}

export default InsightForm
