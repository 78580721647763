import React from "react"
import "./PopularInsights.scss"
import MostPopularInsights from "../components/MostPopularInsights"

const PopularInsights = ({ item }) => {
  const { title } = item.customFields
  return (
    <section className={`popular-insights item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <h2 className="title">{title}</h2>
          </div>
        </div>
      </div>
       <MostPopularInsights/>
    </section>
  )
}

export default PopularInsights
