import React from "react"
import "./JobDescription.scss"
import { renderHTML } from "./../agility/utils"
import { Link } from "gatsby"

const JobDescription = ({ item }) => {
  const { customFields } = item

  const handleScrollToForm = () => {
    if (typeof window === `undefined`) return
    if (document.querySelector(".form-section")) {
      window.scrollTo({
        top: document.querySelector(".form-section").offsetTop,
        behavior: "smooth",
      })
    }
  }

  return (
    <section className={`job-description-${item.contentID} job-description`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3 d-flex flex-column">
            <Link className="link mb-4" to={customFields.link.href}>
              {customFields.link.text}
            </Link>
            <h4 className="tag tag-location d-flex align-content-center">
              {customFields.location}
            </h4>
            <h4 className="tag tag-type d-flex align-content-center">
              {customFields.type}
            </h4>
            <h4 className="tag tag-category d-flex align-content-center">
              {customFields.category}
            </h4>
            <button className="btn" onClick={handleScrollToForm}>
              Apply now
            </button>
          </div>
          <div className="col-12 col-md-9">
            <span className="headline-line" />
            <h1 className="title">{customFields.title}</h1>
            <p className="description">{customFields.description}</p>
            <div
              className="content pb-1 mb-3 px-sm-2"
              dangerouslySetInnerHTML={renderHTML(customFields.content)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobDescription
