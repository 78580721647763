import React, { useEffect } from "react"
import "./EmbedForm.scss"

const EmbedForm = ({ item }) => {
  const { embedcode } = item.customFields

  const { portalId, formId } = JSON.parse(embedcode)
  const detailColor = item.customFields.detailColor
    ? item.customFields.detailColor.customFields.hexadecimalValue
    : "#E12A5C"

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)


    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: "#hubspotForm",
        })
      }
    })
  }, [])

  return (
    <section className="form-section section">
      <div className="container">
        <h2 className="form-section-title">
          <span className="wrapper">
            <span
              style={{
                backgroundColor: detailColor,
              }}
            />
            {item.customFields.title}
          </span>
        </h2>
        <div id="hubspotForm"></div>
      </div>
    </section>
  )
}

export default EmbedForm
