import React from 'react'
import "./FullWidthContentAndImage.scss"
import { renderHTML } from "./../agility/utils"
import LazyImage from '../components/LazyImage'
import { AnimatedCta } from '../components/AnimatedCta'

const FullWidthContentAndImage = ({ item }) => {
  const { title, content, cta, image, imageAlignment, darkBackground } = item.customFields

  const aligmentClass = imageAlignment === "right bottom" ? "order-lg-2 relative" : ""
  const imageClass = imageAlignment === "right bottom" ? "right-bottom" : ""

  const ctaStyles = {
    border: "2px solid #1d1d1d",
    color: "#1d1d1d",
    maxWidth: "240px"
  }
  const darkBg = darkBackground === "true" ? "dark" : ""

  if (darkBackground === "true") {
    const darkCtaStyles = {
      border: "2px solid #ffff",
      color: "#ffff",
      marginTop: "20px"
    }

    return (
      <section className={`full-width-content-image px-4 px-lg-0 ${darkBg} item-${item.contentID}`}>
        <div className="row no-gutters">
          <div className={`col-12 col-lg-4 order-1 order-lg-0 mt-5 ${aligmentClass}`}>
            <LazyImage extraClasses={`image dark ${imageClass}`} src={image.url} alt={image.label || "image"} />
          </div>
          <div className="col-12 col-lg-6 content-column px-lg-5">
            <h2 className='title white'>{title}</h2>
            <div
              className="content white"
              dangerouslySetInnerHTML={renderHTML(content)}
            />
            {cta && <AnimatedCta styles={darkCtaStyles} href={cta.href} text={cta.text} />}
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className={`full-width-content-image px-4 px-lg-0 item-${item.contentID}`}>
      <div className="row no-gutters">
        <div className={`col-12 col-lg-6 order-1 order-lg-0 mt-4 ${aligmentClass}`}>
          <LazyImage extraClasses={`image ${imageClass}`} src={image.url} alt={image.label || "image"} />
        </div>
        <div className="col-12 col-lg-6 content-column px-lg-5 d-md-flex flex-column justify-content-center">
          <h2 className='title'>{title}</h2>
          <div
            className="content"
            dangerouslySetInnerHTML={renderHTML(content)}
          />
          {cta && <AnimatedCta styles={ctaStyles} href={cta.href} text={cta.text} />}
        </div>
      </div>
    </section>
  )
}

export default FullWidthContentAndImage