import React from "react"
import "./CareerPostListing.scss"

const CareerPostListing = ({ allCareers }) => {
  console.log(allCareers)
  return (
    <div className="job-board container-fluid">
      <div className="row w-100">
        <div className="col-12">
          <h2>CareerPostListing</h2>
          <h3>testing iframes</h3>
          <iframe className="iframe" src="https://boards.greenhouse.io/embed/job_board?for=edume&b=https%3A%2F%2Fwww.edume.com%2Fcareers" />
        </div>
      </div>
    </div>
  )
}

export default CareerPostListing
