import React from "react"
import LazyImage from "../components/LazyImage"
import { renderHTML } from "../agility/utils"
import "./CardSection.scss"

const CardSection = ({ item }) => {
  const { customFields } = item

  const styles = {
    backgroundColor: `${customFields.backgroundColor}`,
  }

  const CTA = ({ customFields }) => {
    const handleScrollToForm = () => {
      if (typeof window === `undefined`) return
      if (document.querySelector(".form-section")) {
        window.scrollTo({
          top: document.querySelector(".form-section").offsetTop,
          behavior: "smooth",
        })
      }
    }

    if (customFields.cTA && !customFields.href) {
      return (
        <button className="btn" onClick={handleScrollToForm}>
          {customFields.cTA.text}
        </button>
      )
    }
    return null
  }

  const Card = ({ card, key }) => {
    const { title, image, imageAlignment, text } = card.customFields

    if (imageAlignment === "left") {
      return (
        <div className={`row card left-alignment`} key={key}>
          <div className="col-md-4 col-12">
            <LazyImage
              extraClasses="card-img-top"
              src={image.url}
              alt={image.label}
            />
          </div>
          <div className="col-md-8 col-12">
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <div
                className="card-text"
                dangerouslySetInnerHTML={renderHTML(text)}
              />
            </div>
            <div className="row">
              <div className="col-12 btn-row">
                <CTA customFields={card.customFields} />
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="card" key={key}>
        <LazyImage
          extraClasses="card-img-top"
          src={image.url}
          alt={image.label}
        />
        <div className="card-body d-flex flex-column align-items-center">
          <h5 className="card-title">{title}</h5>
          <div
            className="card-text"
            dangerouslySetInnerHTML={renderHTML(text)}
          />
        </div>
      </div>
    )
  }

  return (
    <section
      className={`card-section card-section-${item.contentID}`}
      style={styles}
    >
      {/* <style>{styles}</style> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <span className="headline-line" />
            <h2 className="title">{customFields.title}</h2>
            {customFields.description ? (
              <p className="description mb-5">{customFields.description}</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          {customFields.cardList.map((card, key) => {
            return (
              <div
                className={
                  customFields.cardList.length > 1
                    ? "col-md-6 col-12"
                    : "col-12"
                }
                key={key}
              >
                <Card card={card} key={key} />
              </div>
            )
          })}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center mt-5">
            <CTA customFields={customFields} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardSection
