import React from "react"
import { renderHTML } from "../agility/utils"
import "./BackgroundAndContent.scss"

const BackgroundAndContent = ({ item }) => {
  const { customFields } = item

  const alt = customFields?.imageBackground?.label
    ? customFields.imageBackground.label
    : ""

  const placeholder = customFields.placeholder
    ? customFields.placeholder.url
    : customFields?.imageBackground?.url

  const styles = `.bg-content.bg-content-${item.contentID} .bg-img {
        background-image: url(${customFields?.imageBackground?.url}), url(${placeholder});
    
        @media (min-width: 768px) {
            .bg-content.bg-content-${item.contentID} .bg-img {
              background-image: url(${customFields.imageBackground.url}), url(${placeholder});
            }
          }
      }`

  return (
    <section className={`bg-content bg-content-${item.contentID}`}>
      <style>{styles}</style>
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <div className="col-12 col-md-12 col-sm-12">
            <figure className="bg-img mb-0" role="img" title={alt}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="content-wrapper">
                    <div
                      className="headline-decoration d-none d-sm-block mb-2"
                      style={{ borderColor: "#E12A5C" }}
                    />
                    <h1 className="title">{customFields.title}</h1>
                    <div
                      className="text pb-1 px-sm-2"
                      dangerouslySetInnerHTML={renderHTML(customFields.text)}
                    />
                  </div>
                </div>
              </div>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}
export default BackgroundAndContent
