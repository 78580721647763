import React from "react"
import "./ClientsSection.scss"

export const ClientsSection = ({ item }) => {
  const customFields = item.customFields

  const alt = customFields?.imageBackground?.label
    ? customFields.imageBackground.label
    : ""
  const placeholder = customFields.placeholder
    ? customFields.placeholder.url
    : customFields?.imageBackground?.url

  const styles = `.clients-section.clients-section-${item.contentID} .background-img, .background-mobile {
    background-image: url(${customFields?.imageBackground?.url}), url(${placeholder});
    background-repeat: no-repeat;
    background-size: cover;

    @media(min-width: 768px) {
        .clients-section.clients-section-${item.contentID} .background-img {
          background-image: url(${customFields.imageBackground.url}), url(${placeholder});
        }
      }
    @media(max-width: 767px) {
        .clients-section.clients-section-${item.contentID} .background-mobile {
          background-image: url(${customFields.mobileBackground.url}), url(${customFields.mobileBackground.url});
        }
      }
  }`

  const Card = ({ props }) => {
    const { positionCompany, name, text, image } = props.customFields

    return (
      <div className="col-md-4 col-sm-12">
        <div className="card">
          <div className="card-image d-flex justify-content-center">
            <img src={image.url} alt="image" />
          </div>
          <div className="card-body">
            <div className="card-subtitle d-flex align-items-center">
              <div
                className="headline-decoration d-none d-sm-block"
                style={{ borderColor: "#E12A5C" }}
              />
              <p className="m-0 ml-2">{positionCompany}</p>
            </div>
            <div className="card-name">
              <h1 className="name">{name}</h1>
              <h4 className="text">{text}</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className={`clients-section clients-section-${item.contentID}`}>
      <style>{styles}</style>
      <div className="container-fluid px-0">
        <div className="row no-gutters desktop-img">
          <div className="col-12 col-md-12">
            <figure className="background-img mb-0" role="img" title={alt}>
              <div className="title d-flex justify-content-center">
                <h1>{customFields.title}</h1>
              </div>
              <div className="cards d-flex">
                {customFields.clientsTestimony.map(clientCard => {
                  return <Card key={clientCard.contentID} props={clientCard} />
                })}
              </div>
            </figure>
          </div>
        </div>
        <div className="row no-gutters mobile-img">
          <div className="col-sm-12">
            <figure className="background-mobile mb-0" role="img" title={alt}>
              <div className="title d-flex justify-content-end">
                <h1>{customFields.title}</h1>
              </div>
            </figure>
            <div className="cards d-flex">
              {customFields.clientsTestimony.map(clientCard => {
                return <Card key={clientCard.contentID} props={clientCard} />
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ClientsSection
