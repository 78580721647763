import React, { Fragment, useEffect, useRef } from "react"
import LazyImage from "../components/LazyImage"
import "./SliderApproach.scss"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderApproach = ({ item }) => {
  const { approachesList } = item.customFields
  let currentPos = 0
  const slider = useRef()

  const addTranslate = () => {
    currentPos = currentPos >= 120 ? 0 : currentPos + 60
    let scroll_progress = document.getElementById("approach-slide-progress")
    scroll_progress.style.transform = `translateY(${currentPos}px)`
    scroll_progress.style.transition = `1s ease-in-out`
  }

  useEffect(() => {
    let currentHeight = 60

    const scrollProgress = () => {
      currentHeight = currentHeight >= 180 ? 60 : currentHeight + 60
      let scroll_progress = document.getElementById("approach-slide-progress")
      scroll_progress.style.height = `${currentHeight}px`
      scroll_progress.style.transition = `1s ease-in-out`
      slider.current.slickNext()
    }
    const interval = setInterval(() => scrollProgress(), 3000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    fade: true,
    swipe: true,
    vertical: true,
    verticalSwiping: true,
    slidesToScroll: 1,
    currentSlide: 0,
    adaptiveHeight: true,
    onSwipe: () => addTranslate(),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  }

  const Slide = ({ slide }) => {
    const { title, image, text } = slide.customFields

    return (
      <>
        <div className="col-md-6 col-12 img">
          <LazyImage
            src={image.url}
            alt={image.label}
            extraClasses="image-mockups"
          />
        </div>
        <div className="col-md-6 col-12 txt">
          <div className="wrap">
            <div className="headline-line-wrapper align-items-center flex-row">
              <span className="headline-line" />
              <p className="headline-text ml-2 m-0">Shiru</p>
            </div>
            <h2 className="title">{title}</h2>
            <p className="text">{text}</p>
          </div>
        </div>
      </>
    )
  }

  return (
    <section className={`slider-approaches sa-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div className="wrapper col-11 col-md-11">
            <Slider ref={slider} {...settings} className="row center">
              {approachesList.map((approach, index) => {
                return (
                  <Fragment key={index}>
                    <Slide slide={approach} />
                  </Fragment>
                )
              })}
            </Slider>
          </div>
          <div className="col-1 col-md-1">
            <div className="first__scroll">
              <div className="scroll__start">01</div>
              <div className="scroll__line">
                <div
                  id="approach-slide-progress"
                  className="scroll__progress"
                ></div>
              </div>
              <div className="scroll__end">03</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SliderApproach
