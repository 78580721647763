import React from "react"
import SVG from "../components/SVG"
import "./GridListing.scss"
import { renderHTML } from "./../agility/utils"
import { isSingleDigit } from "./../agility/helpers"
import { Link } from "gatsby"

const GridListing = ({ item }) => {
  const customFields = item.customFields
  const moduleColor = customFields.moduleColor.customFields.hexadecimalValue

  const items = customFields.listItems.map((item, key) => {
    const humanIndex = key + 1
    return {
      ...item.customFields,
      color: moduleColor,
      number: isSingleDigit(humanIndex) ? `0${key + 1}` : `${key + 1}`,
    }
  })

  const CTA = () => {
    if (customFields.cTAScrollToForm && customFields.cTALink.href) {
      const buttonStyleClass = customFields.buttonStyle.customFields.cSSClass
        ? customFields.buttonStyle.customFields.cSSClass
        : "btn-primary-white"

      return (
        <Link
          className={`btn ${buttonStyleClass}`}
          to={customFields.cTALink.href}
        >
          {customFields.cTALink.text}
        </Link>
      )
    }

    if (customFields.cTAScrollToForm && !customFields.cTALink.href) {
      const buttonStyleClass = customFields.buttonStyle.customFields.cSSClass
        ? customFields.buttonStyle.customFields.cSSClass
        : "btn-primary-white"
        
      const handleScrollToForm = () => {
        if (typeof window === `undefined`) return
        if (document.querySelector(".form-section")) {
          window.scrollTo({
            top: document.querySelector(".form-section").offsetTop,
            behavior: "smooth",
          })
        }
      }

      return (
        <button
          className={`btn ${
            customFields.backgroundColor === "black" ? "" : "black"
          } ${buttonStyleClass}`}
          onClick={handleScrollToForm}
        >
          {customFields.cTALink.text}
        </button>
      )
    }

    return null
  }

  const GridItem = ({ props }) => {
    return (
      <div className={"col-12 col-sm-6 col-md-4 col-lg-3 grid-item"}>
        <p
          className="grid-item__index"
          style={{ color: props.color, borderColor: props.color }}
        >
          {props.number}
        </p>

        {props.title && <h3 className="grid-item__title">{props.title}</h3>}

        <div
          className="grid-item__description"
          dangerouslySetInnerHTML={renderHTML(props.description)}
        ></div>
      </div>
    )
  }

  const GridItems = ({ gridItems }) => {
    return gridItems.map((gridItem, index) => {
      return <GridItem props={gridItem} key={index} />
    })
  }

  return (
    <section className="grid-listing">
      <div className="squares-wrapper d-none d-md-block">
        <SVG
          name={"dotted-squares"}
          width={495}
          height={226}
          fill={moduleColor}
        ></SVG>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="headline-decoration d-none d-sm-block"
              style={{ borderColor: moduleColor }}
            ></div>
            <h2 className="title">{customFields.title}</h2>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 p-0">
              {customFields.topText ? (
                <div
                className="topText"
                dangerouslySetInnerHTML={renderHTML(customFields.topText)}
              />
              ) : (
                ""
              )}
            </div>
          </div>

          <GridItems gridItems={items} />
        </div>
        <div
          className={
            customFields?.cTAAlignment !== undefined
              ? "d-flex justify-content-center"
              : ""
          }
        >
          <CTA />
        </div>
      </div>
    </section>
  )
}

export default GridListing
