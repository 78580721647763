import React, { Fragment } from "react"
import "./SimpleColumnsCards.scss"

const SimpleColumnsCards = ({ item }) => {
  const { title, itemCards, darkBackground, titleAlignment, text } = item.customFields

  const dark = darkBackground === "true" ? "dark" : ""
  const textLeft = titleAlignment === "left" ? "text-left" : ""
  const col = titleAlignment === "left" ? "col-md-9" : "col-md-12"

  const Card = ({ card }) => {
    const { image, title, description, link } = card.customFields


    return (
      <div className="col-lg-4 col-md-6 col-12">
        <div className="wrapper d-flex flex-column justify-content-between mb-5 mb-md-0">
          <a href={link.href} target="_blank" rel="noreferrer">
            <div className="image-wrapper">
              <img className="image mb-0" src={image.url} alt="" />
              <div className={`overlay ${dark}`}></div>
            </div>
          </a>
          <h4 className={`title ${dark} mt-3 mb-3`}>{title}</h4>
          <p className={`description ${dark}`}>{description}</p>
          <a
            className="link"
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more &#8594;
          </a>
          <div className="row">
            <div className="col-12"></div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className={`simple-columns-cards item-${item.contentID} ${dark}`}>
      <div className="container">
        <div className="row">
          <div className={`col-12 ${col} justify-content-center`}>
            <h2 className={`title ${dark} ${textLeft}`}>{title}</h2>
          </div>
        </div>
        {text && <div className="row">
          <div className="col-md-4 col-12">
            <p className="text mb-md-t mt-md-4">{text}</p>
          </div>
        </div>}
        <div className="row justify-content-around mt-md-5">
          {itemCards.map(card => {
            return (
              <Fragment key={card.contentID}>
                <Card card={card} />
              </Fragment>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default SimpleColumnsCards
