import React from "react"
import "./ShareOnMedia.scss"
import SVG from "./SVG"

/**
 * Component to render the share a post on media using the post and link provided.
 * @function ShareOnMedia
 */

const ShareOnMedia = ({ post, route }) => {
  return (
    <div className="insights-share">
      <h3>Share On</h3>
      <div className="insights-share-icons">
        <a
          className="insights-share-link"
          href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
            route.href
          )}`}
          target="_blank"
          aria-label="Share on Facebook. (Opens new window)"
          rel="noopener noreferrer"
        >
          <SVG
            fill={"#fff"}
            name={"facebook-variation"}
            width={22}
            height={22}
          ></SVG>
        </a>
        <a
          className="insights-share-link"
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            route.href
          )}&title=${encodeURIComponent(
            post.customFields.title
          )}&summary=${encodeURIComponent(post.customFields.excerpt)}`}
          target="_blank"
          aria-label="Share on LinkedIn. (Opens new window)"
          rel="noopener noreferrer"
        >
          <SVG
            fill={"#fff"}
            name={"linked-in-variation"}
            width={22}
            height={22}
          ></SVG>
        </a>
        <a
          className="insights-share-link"
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            route.href
          )}&text=${encodeURIComponent(post.customFields.title)}`}
          target="_blank"
          aria-label="Share on Twitter. (Opens new window)"
          rel="noopener noreferrer"
        >
          <SVG
            fill={"#fff"}
            name={"twitter-variation"}
            width={22}
            height={22}
          ></SVG>
        </a>
      </div>
    </div>
  )
}

export default ShareOnMedia
