import React, { Fragment } from "react"
import LazyImage from "../components/LazyImage"
import { renderHTML } from "./../agility/utils"
import "./ImageAndText.scss"

const ImageAndText = ({ item }) => {
  const { title, text, numbers, image, background } = item.customFields

  const Number = ({ num, index }) => {
    const { number, tag } = num.customFields

    return (
      <div key={num.contentID} className="dots-wrapper d-flex">
        <div className={`lines d-md-none ${index == 2 ? "transparent" : ""}`}>
          <div className={`dot ${index == 2 ? "transparent" : ""}`}></div>
          <div className={`line ${index == 2 ? "transparent" : ""}`}></div>
          <div className={`dot ${index == 2 ? "transparent" : ""}`}></div>
        </div>
        <div className="number-wrapper d-flex">
          <p className="number">{number}</p>
          <p className="tag mt-md-3 ml-md-1">{tag}</p>
        </div>
      </div>
    )
  }

  return (
    <section
      className={`image-text it-${item.contentID}`}
      style={{
        backgroundImage:
          `url(${background.url})`,
      }}
    >
      <div className="container">
        <div className="row no-gutters">
          <div className="col-md-6 order-last">
            <LazyImage src={image.url} alt={image.label} extraClasses="image" />
          </div>
          <div className="col-md-6 right-wrapper order-md-last">
            <span className="headline-line" />
            <h2 className="title">{title}</h2>
            <div className="text" dangerouslySetInnerHTML={renderHTML(text)} />
            <div className="numbers d-flex justify-content-between mr-md-5 mt-md-5 flex-column flex-md-row">
              {numbers.map((num, index) => {
                return (
                  <Fragment key={index}>
                    <Number num={num} index={index} />
                  </Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageAndText
