import React from "react"
import "./BrochureGallery.scss"

const BrochureGallery = ({ item }) => {
  return (
    <section className="brochure-gallery" aria-hidden="true">
      <div className="brochure-gallery-col-1">
        <img
          src={item.customFields.image1.url}
          role="presentation"
          aria-hidden="true"
          alt={item.customFields.image1.label}
        />
        <figure
          style={{
            backgroundImage: `url(${item.customFields.image1.url})`,
          }}
          role="presentation"
          aria-hidden="true"
          title={item.customFields.image1.label}
        ></figure>
      </div>
      <div className="brochure-gallery-col-2">
        <img
          src={item.customFields.image2.url}
          role="presentation"
          aria-hidden="true"
          alt={item.customFields.image2.label}
        />
        <figure
          style={{
            backgroundImage: `url(${item.customFields.image2.url})`,
          }}
          role="presentation"
          aria-hidden="true"
          title={item.customFields.image2.label}
        ></figure>
      </div>
      <div className="brochure-gallery-col-3">
        <figure
          style={{
            backgroundImage: `url(${item.customFields.image3.url})`,
          }}
          role="presentation"
          aria-hidden="true"
          title={item.customFields.image3.label}
        ></figure>
        <figure
          style={{
            backgroundImage: `url(${item.customFields.image4.url})`,
          }}
          role="presentation"
          aria-hidden="true"
          title={item.customFields.image3.label}
        ></figure>
      </div>
    </section>
  )
}

export default BrochureGallery
