import React from "react"
import "./ServicesLinks.scss"
import { graphql, StaticQuery, Link } from "gatsby"

export default props => (
  <StaticQuery
    query={graphql`
      query ServicesRoutes {
        allAgilitySitemapNode(
          filter: {
            visible: { menu: { eq: true } }
            pagePath: { regex: "/services/" }
          }
        ) {
          nodes {
            pagePath
            menuText
          }
        }
      }
    `}
    render={queryData => {
      const servicesRoutes = queryData.allAgilitySitemapNode.nodes
      const currentServicePath = props.route.pathname
      const currentServices = servicesRoutes.filter(route =>
        route.pagePath.includes(`${currentServicePath}/`)
      )

      const data = {
        currentServices,
        item: props.item,
      }

      return <ServicesLinks {...data} />
    }}
  />
)

const ServicesLinks = props => {
  const customFields = props.item.customFields
  const moduleColor = customFields.mainColor
    ? customFields.mainColor.customFields.hexadecimalValue
    : "#e12a5c"
  return (
    <section className="services-links">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div
              className="headline-decoration"
              style={{ borderColor: moduleColor }}
            ></div>
            <h2 className="title">{customFields.title}</h2>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: customFields.content }}
            ></div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="links-wrapper">
              <p className="text-above-links">{customFields.textAboveLinks}</p>
              <ul className="links">
                {props.currentServices.map((service, index) => {
                  return (
                    <li key={index}>
                      <Link to={service.pagePath}>- {service.menuText}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
