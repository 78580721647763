import React from "react"
import { stripHTML } from "./../agility/helpers"
import "./LogoTitleBanner.scss"

const LogoTitleBanner = ({ item }) => {
  const customFields = item.customFields
  const mainColor = customFields.mainColor.customFields.hexadecimalValue
  const alt = customFields.background.label
    ? customFields.background.label
    : stripHTML(customFields.bannerTitle)
  const styles = `
    .logo-title-banner.ltb-${item.contentID} .title p span {
      color: ${mainColor};
    }
    @media (min-width: 320px) {
      .logo-title-banner.ltb-${item.contentID} {
        background-image: url(${
          customFields.mobileBackground
            ? customFields.mobileBackground.url
            : customFields.background.url
        }); 
      }
    }
    @media (min-width: 768px) {
      .logo-title-banner.ltb-${item.contentID} {
        background-image: url(${customFields.background.url}); 
      }
    }
  `

  return (
    <section
      className={`logo-title-banner ltb-${item.contentID} background-cover`}
      title={alt}
    >
      <style>{styles}</style>
      <div className="container">
        <div className="row">
          <div className="col-5">
            {customFields.logo && (
              <img
                className="logo"
                src={customFields.logo.url}
                alt={
                  customFields.logo.alt ? customFields.logo.alt : "Logo image"
                }
              />
            )}
            <h2
              className="title"
              dangerouslySetInnerHTML={{ __html: customFields.bannerTitle }}
            ></h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LogoTitleBanner
