import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import "./FullHeightCaseStudies.scss"
import ResponsiveBackground from "../components/ResponsiveBackground"

export default props => (
  <StaticQuery
    query={graphql`
      query CaseStudies {
        allAgilityCaseStudyFullHeightPanel {
          nodes {
            contentID
            customFields {
              title
              extraContent
              link {
                href
                target
                text
              }
              titleAreaImage {
                url
                label
              }
              mobileBackgroundLocalImg {
                childImageSharp {
                  fluid(background: "") {
                    originalImg
                  }
                }
              }
              desktopBackgroundLocalImg {
                childImageSharp {
                  fluid(background: "") {
                    originalImg
                  }
                }
              }
            }
            buttonStyle {
              customFields {
                cSSClass
                name
              }
            }
            indicatorColor {
              customFields {
                hexadecimalValue
                name
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <FullHeightCaseStudies
          caseStudies={queryData.allAgilityCaseStudyFullHeightPanel.nodes}
          {...props}
        />
      )
    }}
  />
)

const CaseStudy = ({ data }) => {
  const desktopImage =
    data.customFields.desktopBackgroundLocalImg.childImageSharp.fluid
      .originalImg
  const mobileImage =
    data.customFields.mobileBackgroundLocalImg.childImageSharp.fluid.originalImg
  const ctaStyle = data.buttonStyle
    ? data.buttonStyle.customFields.cSSClass
    : "btn-primary-pink"

  return (
    <ResponsiveBackground
      className="full-height-section case-studies-item"
      renderAsSection={true}
      desktop={desktopImage}
      mobile={mobileImage}
    >
      <div className="case-studies-item-wrapper h-100">
        <div className="row justify-content-end align-items-center h-100">
          <div className="col-12 col-md-6">
            <img
              src={data.customFields.titleAreaImage.url}
              alt={data.customFields.titleAreaImage.label}
            />
            {data.customFields.extraContent && (
              <div
                className="case-studies-item-content"
                dangerouslySetInnerHTML={{
                  __html: data.customFields.extraContent,
                }}
              ></div>
            )}
            <div className="case-studies-item-cta">
              {data.customFields.link && data.customFields.link.href && (
                <Link
                  to={data.customFields.link.href}
                  target={data.customFields.link.target}
                  className={`btn ${ctaStyle}`}
                >
                  {data.customFields.link.text}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </ResponsiveBackground>
  )
}

const Indicator = ({ data, index, ...props }) => {
  const isCurrentSlide = props.currentslide === index
  const goTo = props.goToSlide

  const handleClick = () => {
    if (!props.goToSlide) return
    goTo(index)
  }

  const style = isCurrentSlide
    ? {
        borderColor: data.indicatorColor.customFields.hexadecimalValue,
        transition: "all .3s ease",
      }
    : {
        transition: "all .3s ease",
      }

  const fontStyle = isCurrentSlide
    ? {
        color: data.indicatorColor.customFields.hexadecimalValue,
        transition: "all .3s ease",
      }
    : {
        transition: "all .3s ease",
      }

  return (
    <li className={isCurrentSlide ? "active" : ""}>
      <button onClick={handleClick}>
        <span className="indicator" style={style}></span>
        <span className="label" style={fontStyle}>
          {data.customFields.title}
        </span>
      </button>
    </li>
  )
}

const FullHeightCaseStudies = props => {
  return (
    <section className="section case-studies">
      <div className="case-studies-wrapper">
        <div className="case-studies-indicators-wrapper">
          <nav className="case-studies-indicators">
            <ul>
              {props.caseStudies.map((caseStudy, index) => {
                return (
                  <Indicator
                    data={caseStudy}
                    index={index}
                    key={index}
                    {...props}
                  />
                )
              })}
            </ul>
          </nav>
        </div>
        {props.caseStudies.map((caseStudy, index) => {
          return <CaseStudy data={caseStudy} key={index} />
        })}
      </div>
    </section>
  )
}
