import React from "react"
import "./SpanishLandingFooter.scss"

const SpanishLandingFooter = () => {
  return (
    <footer>
      <p className="copyright">
        &#169; PIXEL506, LLC. TODOS LOS DERECHOS RESERVADOS
      </p>
    </footer>
  )
}

export default SpanishLandingFooter
