import React from 'react'
import "./AnimatedCta.scss"
import { Link } from 'gatsby'

export const AnimatedCta = ({ styles, href, text }) => {
  if (!href) {
    const handleScrollToForm = () => {
      if (typeof window === `undefined`) return
      if (document.querySelector(".form-section")) {
        window.scrollTo({
          top: document.querySelector(".form-section").offsetTop,
          behavior: "smooth",
        })
      }
    }

    return (
      <button
        className="bordered-cta"
        onClick={handleScrollToForm}
        style={styles}
      >
        {text}
      </button>
    )
  }
  return (
    <Link className={'bordered-cta'} style={styles} to={href}>
      {text}
    </Link>
  )
}
