import React, { useState, useEffect } from "react"
import "./Related.scss"
import { Link } from "gatsby"
import { trimText, getReadTime } from "./../agility/helpers"
import moment from "moment"

/**
 * Renders the related posts using a single post.
 * @function Related
 */

const RelatedCard = React.memo(({ props }) => {
  const isPodcast = props.sitemapNode?.pagePath.includes("/podcasts/")
  const categoriesString = props.categories
    .map(category => category.customFields.name)
    .toString()
    .split(",")
    .join(", ")

  const excerptText = props.customFields.excerpt

  return (
    <div className="col-12 col-md-6 col-lg-4 related-post">
      <Link
        to={props.sitemapNode?.pagePath}
        className="related-post__wrapper"
        aria-label={props.customFields.title}
      >
        <figure
          className="featured-image background-cover"
          style={{
            backgroundImage: `url(${props.customFields.image.url})`,
          }}
        >
          <span className="categories">{categoriesString}</span>
        </figure>

        <h2
          className="title"
          dangerouslySetInnerHTML={{
            __html: props.customFields.title,
          }}
        ></h2>
        <p
          className="excerpt"
          dangerouslySetInnerHTML={{
            __html: excerptText.slice(0, 90).concat('…'),
          }}/>

        {/* <p className="excerpt">{trimText(props.customFields.excerpt, 90)}</p> */}
        <div className="post-metadata">
          {props.author && (
            <figure
              className="author-picture"
              style={{
                backgroundImage: `url(${props.author.customFields.photo.url})`,
              }}
            ></figure>
          )}
          <div className="post-metadata-wrapper">
            {props.author && (
              <span className="author-name">
                {props.author.customFields.name}
              </span>
            )}

            <p className="date-reading-wrapper">
              <span className="date">
                {moment(props.customFields.publishDate).format("MMM D YYYY")}
              </span>
              {!isPodcast && (
                <span className="reading-time">
                  <span className="d-none d-md-inline"> • </span>
                  {getReadTime(props.customFields.content)}min read
                </span>
              )}
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
})

const Related = ({ posts }) => {
  const [mutatedPosts, setPosts] = useState([])

  useEffect(() => {
    setPosts(posts)
  }, [])

  return (
    <section className="related">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mb-2 mb-md-4">
            <h2 className="related__title">More Like This</h2>
          </div>
          {mutatedPosts.map(post => {
            return <RelatedCard key={post.customFields.title} props={post} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Related
