import React, { Fragment } from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "./FullPageCaseStudies.scss"

export default props => (
  <StaticQuery
    query={graphql`
      query FullPageCaseStudies {
        allAgilityCaseStudyFullHeightPanel {
          nodes {
            contentID
            customFields {
              title
              extraContent
              link {
                href
                target
                text
              }
              titleAreaImage {
                url
                label
              }
              mobileBackground {
                url
              }
              desktopBackground {
                url
              }
            }
            buttonStyle {
              customFields {
                cSSClass
                name
              }
            }
            indicatorColor {
              customFields {
                hexadecimalValue
                name
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <FullPageCaseStudies
          caseStudies={queryData.allAgilityCaseStudyFullHeightPanel.nodes}
          {...props}
        />
      )
    }}
  />
)

const FullPageCaseStudies = props => {
  const title = props.item.customFields.title
  const handleMoveToCaseStudy = index => {
    if ((typeof window === `undefined`) === false) {
      if (window.fullpageApi) {
        window.fullpageApi.moveTo(index)
      }
    }
  }

  return (
    <Fragment>
      {props.caseStudies.map((caseStudy, index) => {
        const customFields = caseStudy.customFields
        const placeholder = customFields.placeholder
          ? customFields.placeholder.url
          : ""
        const desktopImage = customFields.desktopBackground.url
        const mobileImage = customFields.mobileBackground.url
        const alt = customFields.desktopBackground.label
          ? customFields.desktopBackground.label
          : customFields.titleAreaImage.label
        const ctaStyle = caseStudy.buttonStyle
          ? caseStudy.buttonStyle.customFields.cSSClass
          : "btn-primary-pink"

        const styles = `
          .case-study.cs-${caseStudy.contentID} {
            background-image: url(${mobileImage}), url(${placeholder});
          }
          @media (min-width: 768px) {
            .case-study.cs-${caseStudy.contentID} {
              background-image: url(${desktopImage}), url(${placeholder});
            }
          }
        `

        return (
          <section
            key={caseStudy.contentID}
            className={`section ${index === 0 ? "active" : ""}`}
          >
            <style>{styles}</style>
            {index === 0 && (
              <Helmet>
                <link rel="preload" as="image" href={placeholder} />
                <link rel="preload" as="image" href={mobileImage} />
                <link rel="preload" as="image" href={desktopImage} />
                <link
                  rel="preload"
                  as="image"
                  href={customFields.titleAreaImage.url}
                />
              </Helmet>
            )}
            <div className={`case-study cs-${caseStudy.contentID}`} title={alt}>
              <div className="container h-100">
                {index === 0 && (
                  <div className="heading-box p-4">
                    <h1 className="text-white mb-0">{title}</h1>
                  </div>
                )}
                <div className="row justify-content-end align-items-center h-100">
                  <div className="d-none d-sm-block col-12 col-md-5">
                    <nav className="case-studies-indicators">
                      <ul>
                        {props.caseStudies.map((caseStudy, index) => {
                          const style = {
                            borderColor:
                              caseStudy.indicatorColor.customFields
                                .hexadecimalValue,
                            transition: "all .3s ease",
                          }

                          const fontStyle = {
                            color:
                              caseStudy.indicatorColor.customFields
                                .hexadecimalValue,
                            transition: "all .3s ease",
                          }

                          return (
                            <li key={caseStudy.contentID}>
                              <button
                                onClick={() => handleMoveToCaseStudy(index + 1)}
                              >
                                <span
                                  className="indicator"
                                  style={style}
                                ></span>
                                <span className="label" style={fontStyle}>
                                  {caseStudy.customFields.title}
                                </span>
                              </button>
                            </li>
                          )
                        })}
                      </ul>
                    </nav>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="case-study-wrapper">
                      <img
                        src={customFields.titleAreaImage.url}
                        alt={customFields.titleAreaImage.label}
                      />
                      {customFields.extraContent && (
                        <div
                          className="case-studies-item-content"
                          dangerouslySetInnerHTML={{
                            __html: customFields.extraContent,
                          }}
                        ></div>
                      )}
                      <div className="case-studies-item-cta">
                        {customFields.link && customFields.link.href && (
                          <Link
                            to={customFields.link.href}
                            target={customFields.link.target}
                            className={`btn ${ctaStyle}`}
                          >
                            {customFields.link.text}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      })}
    </Fragment>
  )
}
