import React, { Fragment } from "react"
import "./LeftContentAndColumns.scss"

const LeftContentAndColumns = ({ item }) => {
  const { mainTitle, description, content, backgorundImage, mobileBackground } = item.customFields

  const backgroundStyles = `
  .left-content-columns.item-${item.contentID} {
    background-image: url(${mobileBackground?.url});
    background-size: cover;
  }

  @media (min-width: 768px) {
    .left-content-columns.item-${item.contentID} {
      background-image: url(${backgorundImage?.url});
      background-size: cover;
    }
  }`

  const SimpleContent = ({ content }) => {
    const { title, description } = content.customFields
    return (
      <div className="col-12 col-md-4">
        <h4 className="content-title">{title}</h4>
        <p className="content-description">{description}</p>
      </div>
    )
  }
  return (
    <section className={`left-content-columns item-${item.contentID}`}>
      {backgorundImage && <style>{backgroundStyles}</style>}
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <h2 className="title">{mainTitle}</h2>
            {/* <p className="description mt-md-5">{description}</p> */}
          </div>
          <div className="col-lg-5 col-md-8">
            <p className="description mt-md-5">{description}</p>
          </div>
        </div>
        <div className="row">
          {content &&
            content.map((content, index) => {
              return (
                <Fragment key={index}>
                  <SimpleContent content={content} />
                </Fragment>
              )
            })}
        </div>
      </div>
    </section>
  )
}

export default LeftContentAndColumns
