import React from 'react'
import "./TitleAndBackground.scss"
import { AnimatedCta } from '../components/AnimatedCta'

const TitleAndBackground = ({ item }) => {
  const { title, backgroundDesktop, backgroundMobile, cta } = item.customFields

  const inlineStyles = `
    .title-background.item-${item.contentID}{
    background-image: url(${backgroundMobile.url});
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (min-width: 768px) {
    .title-background.item-${item.contentID} {
      background-image: url(${backgroundDesktop?.url});
      background-size: cover;
    }
  }
`

  const ctaStyles = {
    border: "2px solid #ffff",
    color: "#ffff",
    marginX: "0 auto",
    marginBottom: "0",
  }
  return (
    <section className={`title-background item-${item.contentID}`}>
      <style>{inlineStyles}</style>
      <div className="container">
        <div className="row no-gutters">
          <div className="col-12 flex justify-content-center">
            <h3 className='title'>{title}</h3>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-lg-3 col-12 d-flex flex-row justify-content-center">
            <AnimatedCta styles={ctaStyles} href={cta.href} text={cta.text} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TitleAndBackground