import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import "./SolutionsAccordions.scss"
import plusDesktop from "../img/plus_desktop.svg"
import minusDesktop from "../img/minus_desktop.svg"
import arrow from "../img/rigt_arrow.svg"

export default props => (
  <StaticQuery
    query={graphql`
    query SolutionsForAccordion {
        
        allAgilitySitemapNode(
          filter: {
            visible: { menu: { eq: true } }
            pagePath: { regex: "/services/" }
          }
        ) {
          nodes {
            pagePath
            menuText
            name
          }
        }
      }
    `}
    render={queryData => {
      const services = queryData.allAgilitySitemapNode.nodes

      const secondlevelServices = services.filter(sitemapNode => {
        let isSecondLevel = sitemapNode.pagePath.split("/").length === 3
        if (props.isMultiLanguage) {
          isSecondLevel = sitemapNode.pagePath.split("/").length === 4
        }
        return isSecondLevel
      })

      let thirdlevelServices = services.filter(sitemapNode => {
        let isThirdLevel = sitemapNode.pagePath.split("/").length === 4
        if (props.isMultiLanguage) {
          isThirdLevel = sitemapNode.pagePath.split("/").length === 5
        }
        return isThirdLevel
      })

      thirdlevelServices = thirdlevelServices.sort(
        (a, b) => a.menuText > b.menuText
      )

      secondlevelServices.forEach((service, index) => {
        const serviceCategory = service.pagePath.split("/")[2]
        secondlevelServices[index].childNodes = []
        thirdlevelServices.forEach(child => {
          const childServiceCategory = child.pagePath.split("/")[2]
          if (childServiceCategory === serviceCategory) {
            secondlevelServices[index].childNodes.push(child)
          }
        })
      })

      services.childNodes = secondlevelServices
      return (
        <SolutionsAccordions
          services={services.childNodes}
          {...props.item}
        />
      )
    }}
  />
)

const SolutionsAccordions = ({ services, customFields }) => {
  const [activeService, setActiveService] = useState(null)


  const handleActiveService = service => {
    if (service !== activeService) {
      setActiveService(service)
    } else {
      setActiveService(null)
    }
  }

  return (
    <section className="solutions-accordions">
      <div className="container p-md-0">
        <div className="row">
          <div className="col-12 col-md-8">
            <h2 className='headline'>{customFields.headline}</h2>
            <p className='text'>{customFields.text}</p>
          </div>

        </div>
      </div>
      <div className="container p-0 m-0 d-inline">
        <div className="row no-gutters">
          <div className="col-12">
            {services.map((service, key) => {
              let maxHeightClass
              const isOdd = (key + 1) % 2 !== 0;

              if (service.childNodes.length > 8) {
                maxHeightClass = "large"
              } else {
                maxHeightClass = "middle"
              }

              if (service.childNodes.length > 15) {
                maxHeightClass = "extra-large"
              }

              return (
                <button
                  key={key}
                  className={`accordion ${isOdd ? "pink-background" : ""} ${activeService === service.name ? "active" : "inactive"}`}
                  aria-expanded={activeService === service.name}
                  onClick={() => handleActiveService(service.name)}
                >
                  <div className="accordion-wrapper">
                    <h2 className="accordion-topic mb-0">
                      {service.menuText}
                      {activeService === service.name ?
                        <img className='item-icon m-auto' src={minusDesktop} alt="" />
                        : <img className='item-icon m-auto' src={plusDesktop} alt="" />
                      }
                    </h2>
                    <div className={`accordion-subpanel ${maxHeightClass} ${service.name
                      } ${activeService === service.name ? "visible" : ""} ${service.name}`}
                    >
                      <ul className="accordion-links">
                        {service.childNodes.map((childNode, key) => {
                          return (
                            <li key={key} className="accordion-links__item d-flex align-items-center">
                              <img className='arrow mr-2' src={arrow} alt="" />
                              <Link to={childNode.pagePath}>
                                {childNode.menuText}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )

}