import React, { useState, useEffect, useRef } from "react"
import "./NumbersAndTitle.scss"
import { renderHTML } from "../agility/utils"
import ButtonCTA from "../components/ButtonCTA"

const NumbersAndTitle = ({ item }) => {
  const {
    title,
    description,
    cTA,
    numbers,
    subtitle,
    headlines,
    bottomCTA,
  } = item.customFields

  const Number = ({ num }) => {
    const [counter, setCounter] = useState(0)
    const [startCounter, setStartCounter] = useState(false)
    const [numberToStop, setNumberToStop] = useState(0)
    const [description, setDescription] = useState("")
    const { number } = num.customFields
    const _intervalRef = useRef(null)
    const animatedRef = useRef(null)

    useEffect(() => {
      const parser = new DOMParser()
      const docu = parser.parseFromString(number, "text/html")
      const numText = docu.getElementsByTagName("p")
      const text = docu.getElementsByClassName("text")
      if (numText.length > 0) {
        const counterStop = numText[0].textContent.replace(/[^0-9\.]/g, "")
        setNumberToStop(counterStop)
      }
      if (text.length > 0) {
        setDescription(text[0].outerText)
      }
    }, [])

    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setStartCounter(true)
          observer.unobserve(animatedRef.current)
        }
      })

      observer.observe(animatedRef.current)

      return () => {
        observer.unobserve(animatedRef.current)
      }
    }, [])

    useEffect(() => {
      if (startCounter && counter === 0) {
        _intervalRef.current = setInterval(() => {
          setCounter(counter => counter + 10)
        }, 200)
      } else {
        clearInterval(_intervalRef.current)
      }
      return () => clearInterval(_intervalRef.current)
    }, [startCounter])

    useEffect(() => {
      if (counter >= numberToStop) {
        setStartCounter(false)
        setCounter(numberToStop)
      }
    }, [counter])

    const isDecimal = num => {
      return  num % 1 !== 0;
    }

    return (
      <div className="number-wrapper">
        <p id="digit" className="number-digit d-flex mb-0" ref={animatedRef}>
          {counter}
          {isDecimal(numberToStop) ? "K" : null}
          <sup>+</sup>
        </p>
        <p>{description}</p>
      </div>
    )
  }

  return (
    <section className={`number-title item-${item.contentID}`}>
      <div className="container">
        <div className="row">
          <div
            className={`col ${bottomCTA === "true" ? "col-md-10" : "col-md-6"}`}
          >
            <h2 className="title">{title}</h2>
          </div>
        </div>
        <div className="row flex-wrap align-content-start">
          <div
            className={`col-12 ${bottomCTA === "true" ? "col-md-6" : "col-md-4"}`}
          >
            <p className="description">{description}</p>
            {!bottomCTA && (
              <ButtonCTA
                data={{
                  text: cTA.text + " →",
                  styles: "btn-solid-white btn-no-rounded",
                  href: cTA.href,
                  scrollToForm: true
                }}
              />
            )}
          </div>
          {numbers &&
            !subtitle &&
            numbers.map((num, index) => {
              return <Number num={num} key={index} />
            })}
        </div>
        {numbers && subtitle && (
          <>
            <div className="row">
              <div className="col-md-8">
                <p className="subtitle mr-md-5">{subtitle}</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div
                  className="headlines"
                  dangerouslySetInnerHTML={renderHTML(headlines)}
                />
              </div>
            </div>
            <div className="row">
              {numbers.map((num, index) => {
                return (
                  <div
                    className="col d-flex justify-content-center"
                    key={index}
                  >
                    <Number num={num} />
                  </div>
                )
              })}
            </div>
          </>
        )}
        {bottomCTA && (
          <div className="row">
            <div className="col d-flex justify-content-center pb-5 pt-5">
              <ButtonCTA
                data={{
                  text: cTA.text,
                  styles: "btn-solid-pink btn-no-rounded",
                  href: cTA.href,
                  scrollToForm: true
                }}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default NumbersAndTitle
