import React from "react"
import SVG from "./SVG"
import "./DarkLandingHeader.scss"
import { Link } from "gatsby"

function DarkLandingHeader() {
  /**
   * Renders the logo using the SVG component.
   * @function Logo
   */
  const Logo = () => {
    return (
      <Link
        to="#"
        className="logo"
        aria-label="Pixel506 Logo, click to navigate to the Homepage"
      >
        <SVG name="pearls-pixel-logo" width="150" height="80"></SVG>
      </Link>
    )
  }
  return (
    <header className="dark-landing-header">
      <nav className="px-header-nav">
        <div className="px-header-nav-group">
          <Logo />
        </div>
      </nav>
    </header>
  )
}
export default DarkLandingHeader