import React from "react"
import AWSGoogleBadges from "../components/AWSGoogleBadges"
import "./PartnersBadge.scss"

const PartnersBadge = ({ item }) => {
  const { title } = item.customFields

  return (
    <section className={`partners-badge item-${item.contentID}`}>
      <div className="container">
        {title && <h2 className="title mt-5">{title}</h2>}
      </div>
      <AWSGoogleBadges />
    </section>
  )
}

export default PartnersBadge
