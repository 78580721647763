import React, { Fragment, useState } from "react"
import "./TwoColumnsAndAccordions.scss"
import { renderHTML } from "../agility/utils"

const TwoColumnsAndAccordions = ({ item }) => {
  const {
    title,
    description,
    items,
    imageBackground,
    mobileBackground,
    whiteBackground,
    pinkArrows,
    // colorTextBlack,
    questionColor
  } = item.customFields

  const backgroundStyles = `.two-columns-accordions.item-${item.contentID} {
    background-image: url(${mobileBackground?.url});
    background-size: cover;
  }
  @media (min-width: 768px) {
    .two-columns-accordions.item-${item.contentID} {
      background-image: url(${imageBackground?.url});
      background-size: cover;
    }
  }`

  const black = whiteBackground === "true" ? "black" : ""
  // const blackQuestion = colorTextBlack === "true" ? "black" : "pink"

  const Item = ({ contentItem }) => {
    const [showItem, setShowItem] = useState(false)
    const { question, answer } = contentItem.customFields

    const handleKeyDown = (e) => {
      // Allow the "Enter" or "Space" key to trigger the state change
      if (e.key === 'Enter' || e.key === ' ') {
        setShowItem(!showItem)
      }
    }

    const Arrow = ({ pink, upOrDown }) => {

      if (upOrDown === "down") {
        return (
          <img
            className="arrow"
            src={`${!pink ? "/circle-arrow-down.svg" : "/arrow-down.svg"}`}
            alt="arrow"
          />
        )
      }
      return (
        <img
          className="arrow"
          src={`${!pink ? "/circle-arrow-up.svg" : "/arrow-up.svg"}`}
          alt="arrow"
        />
      )
    }

    return (
      <div className={`item ${black}`} onClick={() => setShowItem(!showItem)} onKeyDown={handleKeyDown}
        role="button" tabIndex={0} >
        <div className="item-wrapper d-flex justify-content-between">
          <h4 className={`item-question ${questionColor} mb-3`}>
            {question}
          </h4>
          {!showItem && (<Arrow pink={pinkArrows} upOrDown={"down"}/>)}
          {showItem && (<Arrow pink={pinkArrows} upOrDown={"up"}/>)}
        </div>
        {showItem && <p className={`item-answer ${black}`}>{answer}</p>}
      </div>
    )
  }

  return (
    <section className={`two-columns-accordions item-${item.contentID}`}>
      {!black && <style>{backgroundStyles}</style>}
      {black && imageBackground && (
        <img className="bottom-left-image d-none d-md-flex"
          src={`${imageBackground?.url}`}
          alt={`${imageBackground.label}`}
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col-md-5 col">
            {black && mobileBackground && (
              <img className="bottom-top-image d-md-none"
                src={`${mobileBackground?.url}`}
                alt={`${mobileBackground.label}`}
              />
            )}
            <h2 className={`title ${black}`}>{title}</h2>
            {description && <div
              className={`description ${black}`}
              dangerouslySetInnerHTML={renderHTML(description)}
            />}
          </div>
          <div className="col-md-7">
            {items &&
              items.map(item => {
                return (
                  <Fragment key={item.contentID}>
                    <Item contentItem={item} />
                  </Fragment>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnsAndAccordions
