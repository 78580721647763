import React from "react"
import "./Testimonials.scss"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Testimonials = ({ item }) => {
  const { title, cards, description, backgroundColor, titleAlignment, useArrows } = item.customFields
  const background_color = backgroundColor ? backgroundColor : ""
  const alignment = titleAlignment === "left" ? "align-items-left col-md-6" : "align-items-center"
  const arrows = useArrows === "true" ? true : false
  const slider = React.useRef(null);
  const NextArrow = () => {
    return (
      <div
        className="custom-arrow">
        <button className="right-arrow-btn mr-1 mr-md-5" onClick={() => slider?.current?.slickNext()}>
          <img src="/right-arrow.svg" alt="right_arrow" />
        </button>
      </div>
    );
  }
  const PrevArrow = () => {
    return (
      <div
        className="custom-arrow md-mr-3">
        <button className="left-arrow-btn mr-1 mr-md-5" onClick={() => slider?.current?.slickPrev()}>
          <img src="/left-arrow.svg" alt="left_arrow" />
        </button>
      </div>
    );
  }

  const settings = {
    // dots: true,
    dots: !arrows,
    // arrows: false,
    arrows: arrows,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    centerMode: true,
    className: "center",
    // centerPadding: "300px",
    centerPadding: arrows ? "0px" : "300px",
    slidesToScroll: 1,
    currentSlide: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  }
  const Card = ({ card }) => {
    const { image, positionCompany, name, text } = card.customFields
    if (arrows) {
      return (
        <div className="testimonials-slider-slide mb-5 ml-2 mr-2 transparent">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
              <div className="logo">
                <img src={image.url} alt={image.label} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="text">{text}</p>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col d-flex flex-column align-items-center">
              <div className="wrapper mr-2">
                <p className="name mb-0">{name}</p>
                <p className="position-company">{positionCompany}</p>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="testimonials-slider-slide mb-5 ml-2 mr-2">
        <div className="row">
          <div className="col-md-12">
            <p className="text">{text}</p>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col d-flex justify-content-end mr-3">
            <div className="logo">
              <img src={image.url} alt={image.label} />
            </div>
          </div>
          <div className="col d-flex flex-column align-items-end">
            <div className="wrapper mr-2">
              <p className="name mb-0">{name}</p>
              <p className="position-company">{positionCompany}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className={`testimonials t-${item.contentID} testimonials-slider ${background_color}`}>
      {/* <div className="container-fluid w-100"> */}
      <div className={`container${arrows ? "" : "-fluid"} w-100`}>
        <div className="row">
          {/* <div className="col-md-12 d-flex align-items-center flex-column mb-5 mt-5"> */}
          <div className={`col d-flex ${alignment} flex-column mb-5 mt-5`}>
            <span className="headline-line" />
            <h2 className="testimonials-title">{title}</h2>
            {description && <p className="description">{description}</p>}
          </div>
          <div className="col-md-12 mb-5">
            <Slider ref={slider} {...settings}>
              {cards.map((card, index) => {
                return <Card card={card} key={index} />
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
