import React, { useState } from "react"
import "./CirclesSection.scss"

const CirclesSection = ({ item }) => {
  const [showText, setShowText] = useState(false)
  const [active, setActive] = useState(0)
  const { customFields } = item

  const Circle = ({ circle, index }) => {
    const { title, text, color } = circle.customFields

    const handleClick = (idx) => {
      setShowText(!showText)
      setActive(idx)
    }

    const styles = {
      backgroundColor: `${color}`,
    }
    const stylesText = {
    }
    const additionalClass = index === 0 ? "no-margin-left" : ""

    return (
      <div className="col-md-3 col-12" key={index}>
        <div
          onClick={() => handleClick(index)}
          style={styles}
          className={`circle d-flex justify-content-center align-items-center ${additionalClass}`}
        >
          <h3 className="title">{title}</h3>
          <span
            className={`d-md-none arrow arrow-${showText && active === index ? "up" : "down"}`}
          ></span>
        </div>
        <p className="text-circle d-none d-md-flex" style={stylesText}>
          {text}
        </p>
        {showText && active === index && (
          <p className="text-circle d-md-none" style={stylesText}>
            {text}
          </p>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="container squares-wrapper">
        <div className="row">
          <div className="col d-flex justify-content-end">
            <div className="squares"></div>
          </div>
        </div>
      </div>
      <section className={`circles-section circles-section-${item.contentID}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <span className="headline-line" />
              <h2 className="title">{customFields.title}</h2>
            </div>
            <div className="col-12">
              <p className="text">{customFields.text}</p>
            </div>
          </div>
          <div className="row circle-wrapper">
            {customFields.circles.map((circle, key) => {
              return <Circle circle={circle} index={key} key={key} />
            })}
          </div>
          <div className="row mobile-circle-wrapper"></div>
        </div>
      </section>
    </>
  )
}

export default CirclesSection
