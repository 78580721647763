import { Link } from "gatsby"
import React, { Fragment } from "react"
import "./CaseStudyCards.scss"
// import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import CustomSlider from "../components/CustomSlider"

const CaseStudyCards = ({ item }) => {
  const { cards } = item.customFields

  // let [firstLongCard, secondSmallCard, thirdSmallCard] = cards

  const CallToAction = ({ card, classes }) => {
    const { cTA } = card.customFields

    return (
      <Link className={`${classes}`} to={cTA.href}>
        {cTA.text}
      </Link>
    )
  }

  const Card = ({ card }) => {
    const {
      cTA,
      long,
      text,
      title,
      backgroundImage,
      mainColor,
    } = card.customFields
    const styleColor = { color: mainColor.customFields.hexadecimalValue }

    if (long == "true") {
      return (
        <div className="long col-md-12 mt-md-5 m-md-3 m-md-0">
          <div className="row no-gutters">
            <div className="col-md-6">
              <div className="card-first-left-wrapper">
                <h2
                  className="card-first-left-wrapper-title"
                  style={styleColor}
                >
                  {title}
                </h2>
                <p className="card-first-left-wrapper-text">{text}</p>
                {/* <CallToAction card={card} classes="long-card-cta" /> */}
                <Link
                  className="call-to-action"
                  style={styleColor}
                  to={cTA.href}
                >
                  {cTA.text}
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-first-right-wrapper">
                <img
                  className="image-bg"
                  src={backgroundImage.url}
                  alt={backgroundImage.label}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="col-md-6 ">
        <div
          className="second-small-card"
          style={{
            backgroundImage: `url(${backgroundImage.url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="second-small-card-title" style={styleColor}>
            {title}
          </h2>
          <p className="second-small-card-text">{text}</p>
          <Link className="call-to-action" style={styleColor} to={cTA.href}>
            {cTA.text}
          </Link>
        </div>
      </div>
    )
  }

  return (
    <section className={`case-studies-cards csc-${item.contentID}`}>
      <div className="container">
        <div className="row">
          {/* {cards.map((card, index) => {
            return (
              <Fragment key={index}>
                <Card card={card} />
              </Fragment>
            )
          })} */}
          <div className="title-wrapper d-flex col-12 flex-column align-items-center">
            <span className="headline-line" />
            <h3 className="success-stories">Success Stories</h3>
          </div>
          <CustomSlider cards={cards} />
        </div>
        {/* <div className="row no-gutters long">
          <div className="col-md-6">
            <div className="card-first-left-wrapper">
              <h2 className="card-first-left-wrapper-title">
                {firstLongCard.customFields.title}
              </h2>
              <p className="card-first-left-wrapper-text">
                {firstLongCard.customFields.text}
              </p>
              <CTA card={firstLongCard} classes="long-card-cta" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-first-right-wrapper">
              <img
                className="image-bg"
                src={firstLongCard.customFields.backgroundImage.url}
                alt=""
              />
            </div>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-md-6">
            <div
              className="second-small-card"
              style={{
                backgroundImage: `url(${secondSmallCard.customFields.backgroundImage.url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <h2 className="second-small-card-title">
                {secondSmallCard.customFields.title}
              </h2>
              <p className="second-small-card-text">
                {secondSmallCard.customFields.text}
              </p>
              <CTA card={secondSmallCard} classes="second-small-card-cta" />
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="third-small-card"
              style={{
                backgroundImage: `url(${thirdSmallCard.customFields.backgroundImage.url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <h2 className="third-small-card-title">
                {thirdSmallCard.customFields.title}
              </h2>
              <p className="third-small-card-text">
                {thirdSmallCard.customFields.text}
              </p>
              <CTA card={thirdSmallCard} classes="third-small-card-cta" />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default CaseStudyCards
